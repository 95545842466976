<ul class="nav nav-tabs mb-3">
  <div class="d-flex">
    <ng-container *ngFor="let tab of uiTabs | keyvalue">
      <li class="nav-item" (click)="changeTab(tab.value)">
        <a class="nav-link" [class.active]="selectedTab === tab.value">{{
          tab.value
        }}</a>
      </li>
    </ng-container>
  </div>
</ul>
<ng-container *ngFor="let tab of uiTabs | keyvalue">
  <div class="tab-content">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tab.value ? 'active' : ''"
      *ngIf="selectedTab === tab.value"
    >
      <div class="row row-cols-1 row-cols-lg-2 col-12 col-lg-6 mb-2">
        <div class="col" *ngIf="tabDataCount > 4">
          <div class="input-group flex-nowrap form-floating">
            <input
              type="text"
              [(ngModel)]="jobSearchStr"
              placeholder="Filter Jobs"
              class="form-control"
              (input)="filterJobs()"
            />
            <label>Filter Jobs</label>
            <span
              class="input-group-text"
              (click)="jobSearchStr = ''; filterJobs()"
              *ngIf="jobSearchStr"
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
        <div
          class="col form-floating"
          (click)="openRangePicker()"
          *ngIf="tabDataCount > 4"
        >
          <input
            class="form-control input-font-sm"
            readonly
            placeholder="Date Filter"
            [value]="dateRangeFilter?.dateRangeValue"
          />
          <label for="">Date Filter</label>
        </div>
      </div>

      <div *ngIf="tabDataListUI?.length > 1">
        <div class="my-3 d-flex gap-3 p-2">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="allJobsSelected"
            (change)="selectAllJobs($event)"
          />
          <label for="">Select All</label>
          <span *ngIf="selectedJobIds?.length" class="col">
            <ng-template *ngTemplateOutlet="changeStatusTemplate"></ng-template>
          </span>
        </div>
      </div>

      @if(largeView){

      <table class="table invoice-table" *ngIf="tabDataListUI?.length">
        <thead>
          <tr>
            <th></th>
            <th class="text-secondary">
              Completed
              <span class="mx-2" (click)="sortField(tableColumns['col1'])">
                @if( tableColumns['col1']?.sortOrder == 'asc'){
                <span>
                  <i class="fa fa-arrow-up"></i>
                </span>

                }@else {
                <span>
                  <i class="fa fa-arrow-down"></i>
                </span>

                }
              </span>
            </th>
            <th class="text-secondary">
              Job Type
              <span class="mx-2" (click)="sortField(tableColumns['col2'])">
                @if( tableColumns['col2']?.sortOrder == 'asc'){
                <span>
                  <i class="fa fa-arrow-up"></i>
                </span>

                }@else {
                <span>
                  <i class="fa fa-arrow-down"></i>
                </span>

                }
              </span>
            </th>
            <th class="text-secondary">
              Site
              <span class="mx-2" (click)="sortField(tableColumns['col3'])">
                @if( tableColumns['col3']?.sortOrder == 'asc'){
                <span>
                  <i class="fa fa-arrow-up"></i>
                </span>

                }@else {
                <span>
                  <i class="fa fa-arrow-down"></i>
                </span>

                }
              </span>
            </th>
            <th class="text-secondary">
              Job ID / Docket
              <span class="mx-2" (click)="sortField(tableColumns['col4'])">
                @if( tableColumns['col4']?.sortOrder == 'asc'){
                <span>
                  <i class="fa fa-arrow-up"></i>
                </span>

                }@else {
                <span>
                  <i class="fa fa-arrow-down"></i>
                </span>

                }
              </span>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of tabDataListUI; let i = index">
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="job?.selected"
                (change)="markSelectedJobs(job)"
              />
            </td>
            <td>
              <div>
                {{ job?.modified_at | customDate : "d MMM y HH:mm" }}
              </div>
            </td>
            <td>
              <div>
                {{ job?.job_type?.name }}
                <div
                  *ngIf="job?.response_type?.name"
                  class="fw-semibold small`"
                >
                  [{{ job?.response_type?.name }}]
                </div>
                <div *ngIf="job?.service_type?.name" class="fw-semibold small`">
                  [{{ job?.service_type?.name }}]
                </div>
              </div>
            </td>
            <td>
              <div class="fw-semibold">
                {{ job?.site?.company_name }}
                <span class="small-font"
                  >[{{ job?.company?.company_name }}]</span
                >
              </div>
              <div class="small-font">{{ job?.site?.address }}</div>
            </td>
            <td>
              <div>
                {{ job?.job_key }}
                <div *ngIf="job?.ext_job_id" class="fw-semibold small">
                  {{ job?.ext_job_id }}
                </div>
              </div>
            </td>

            <td>
              <ng-template
                *ngTemplateOutlet="
                  changeStatusTemplate;
                  context: { value: job }
                "
              ></ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      }@else {
      <div class="row row-cols-1">
        @for (job of tabDataListUI; track $index) {

        <div class="gt-card cursor-pointer">
          <div class="text-end">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="job?.selected"
              (change)="markSelectedJobs(job)"
            />
          </div>

          <div>
            <div class="fw-semibold">
              {{ job?.job_type?.name }}
              <span *ngIf="job?.response_type?.name" class="fw-normal"
                >- {{ job?.response_type?.name }}</span
              >
              <span *ngIf="job?.service_type?.name" class="fw-normal"
                >- {{ job?.service_type?.name }}</span
              >
            </div>
            <div class="fw-semibold">
              {{ job?.site?.company_name }}
              <span class="small-font">[{{ job?.company?.company_name }}]</span>
            </div>
            <div class="small-font">{{ job?.site?.address }}</div>
            <div *ngIf="job?.monitoring_company?.name">
              {{ job?.monitoring_company?.name }}
            </div>
            <div>
              {{ job?.job_key }}
              <span *ngIf="job?.ext_job_id"> - {{ job?.ext_job_id }}</span>
            </div>
          </div>
          <div class="pt-2">
            <ng-template
              *ngTemplateOutlet="changeStatusTemplate; context: { value: job }"
            ></ng-template>
          </div>
        </div>

        }
      </div>
      }
    </div>
  </div>
</ng-container>

<ng-template #changeStatusTemplate let-jobData="value">
  <div class="d-flex gap-4">
    <button
      *ngIf="jobData?.id"
      class="btn btn-sm btn-outline-dark"
      (click)="openJobDetailPage(jobData)"
    >
      View Details
    </button>
    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="uiTabs.tab1">
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Invoice"
          *ngIf="jobInvoiceStatusData?.[11]?.id"
          (click)="updateJobStatus(jobInvoiceStatusData?.[11], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          <i
            class="fa-solid fa-file-invoice-dollar fa-2x text-primary"
            role="button"
          ></i>
        </div>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Do Not Invoice"
          *ngIf="jobInvoiceStatusData?.[12]?.id"
          (click)="updateJobStatus(jobInvoiceStatusData?.[12], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          <i class="fa-solid fa-ban fa-2x text-danger" role="button"></i>
        </div>
        <button
          *ngIf="jobInvoiceStatusData?.[12]?.id"
          class="btn btn-sm w-100 btn-danger d-none"
          (click)="updateJobStatus(jobInvoiceStatusData?.[12], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          Do Not Invoice
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="uiTabs.tab2">
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Payment Received"
          *ngIf="jobInvoiceStatusData?.[13]?.id"
          (click)="updateJobStatus(jobInvoiceStatusData?.[13], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          <i class="fa-solid fa-hand-holding-dollar fa-2x text-success"></i>
        </div>
        <button
          *ngIf="jobInvoiceStatusData?.[13]?.id"
          class="btn btn-sm w-100 btn-primary d-none"
          (click)="updateJobStatus(jobInvoiceStatusData?.[13], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          Payment Received
        </button>
        <button
          *ngIf="jobInvoiceStatusData?.[10]?.id"
          class="btn btn-sm w-100 btn-secondary d-none"
          (click)="updateJobStatus(jobInvoiceStatusData?.[10], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          Not Invoiced 1
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="uiTabs.tab3">
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Awaiting Payment"
          *ngIf="jobInvoiceStatusData?.[10]?.id"
          (click)="updateJobStatus(jobInvoiceStatusData?.[11], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          <i class="fa-solid fa-hand-holding-dollar fa-2x text-danger"></i>
        </div>
        <button
          *ngIf="jobInvoiceStatusData?.[10]?.id"
          class="btn btn-sm w-100 btn-secondary d-none"
          (click)="updateJobStatus(jobInvoiceStatusData?.[10], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          Not Invoiced 2
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="uiTabs.tab4">
        <button
          *ngIf="jobInvoiceStatusData?.[10]?.id"
          class="btn btn-sm btn-primary"
          (click)="updateJobStatus(jobInvoiceStatusData?.[10], jobData?.id?[jobData?.id]: this.selectedJobIds)"
        >
          Ready for Invoicing
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #donInvoiceTemplate let-donInvoiceTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Reason
      <span class="float-end" (click)="donInvoiceTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-group input-group mb-3">
        <textarea
          class="form-control"
          placeholder="Reason [160 chars]"
          cols="20"
          rows="4"
          wrap="hard"
          maxlength="160"
          type="text"
          [(ngModel)]="dNotInvoiceReason"
          id="dNotInvoiceReasonField"
        ></textarea>
        <span
          class="input-group-text"
          *ngIf="dNotInvoiceReason"
          (click)="
            dNotInvoiceReason = '';
            clearAndFocusTextBox('dNotInvoiceReasonField')
          "
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div class="row row-cols-2 my-2">
        <div class="col">
          <button
            class="btn btn-dark btn-lg w-100"
            (click)="donInvoiceTemplate.close()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            [disabled]="!dNotInvoiceReason"
            (click)="donInvoiceTemplate.close(); donotInvoiceJobs()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div></ng-template
>
