<div *ngIf="detailData" class="pt-2">
  <div class="card-body">
    <!-- Incident Delete Button -->
    <div
      [ngStyle]="{
        'padding-bottom': bottomSectionFixedHeight
      }"
    >
      <div *ngIf="!isIncidentSubmitted" class="text-end mb-2">
        <button (click)="deleteIncident()" class="btn btn-danger btn-sm">
          Delete
        </button>
      </div>

      <div *ngIf="isIncidentSubmitted" class="text-end mb-2">
        <div>
          <button
            (click)="markResolved(resolTimeTemplateRef)"
            class="btn btn-success btn-sm"
          >
            Resend Report
          </button>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-lg-3 details-card mb-3">
        <div class="col">
          <div>
            <dl>
              <dt>Incident ID</dt>
              <dd>{{ detailData?.incident_key }}</dd>
            </dl>
          </div>

          <!-- Editable Incident Fields -->
          <div>
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Incident Type</dt>
                </div>
                <div
                  *ngIf="isCreatedUser && !isIncidentSubmitted"
                  class="d-flex align-items-center"
                >
                  <span
                    class="me-3"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="This field categorizes the type of incident being reported."
                  >
                    <i
                      class="fa-solid fa-circle-info md-font-size text-primary"
                    ></i>
                  </span>
                  <span
                    (click)="
                      openupdateIncidentTypeTemplate(incidentTypeTemplateRef)
                    "
                  >
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i
                  ></span>
                </div>
              </div>
              <dd>{{ detailData?.incident_type }}</dd>
            </dl>
          </div>
          <div>
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Site Name</dt>
                  <dd>
                    {{ detailData?.site?.company_name }} [{{
                      detailData?.company?.company_name
                    }}]
                  </dd>
                </div>
                <div (click)="openSiteView(detailData?.site)">
                  <span
                    (click)="
                      openupdateIncidentTypeTemplate(incidentTypeTemplateRef)
                    "
                  >
                    <i class="fa-solid fa-link md-font-size text-secondary"></i
                  ></span>
                </div>
              </div>
            </dl>
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Address</dt>
                  <dd>
                    {{ detailData?.site?.address?.full_address }}
                  </dd>
                </div>
                <div>
                  <a
                    [href]="
                      'https://www.google.com/maps/search/?api=1&query=' +
                      detailData?.site?.address?.latitude +
                      ',' +
                      detailData?.site?.address?.longitude
                    "
                    target="_blank"
                  >
                    <span class="me-1">
                      <i class="fa-solid fa-route md-font-size"></i>
                    </span>
                  </a>
                </div>
              </div>
            </dl>
          </div>
          <div>
            <dl>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Time Of Incident</dt>
                  <dd>
                    {{ detailData?.incident_time | date : "d MMM y HH:mm" }}
                  </dd>
                </div>
                <div *ngIf="isCreatedUser && !isIncidentSubmitted">
                  <span
                    (click)="
                      openupdateIncidentTimeTemplate(incidentTimeTemplateRef)
                    "
                  >
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i
                  ></span>
                </div>
              </div>
            </dl>
            <dl *ngIf="detailData?.information">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Details</dt>
                </div>
                <div>
                  <span
                    (click)="openExtraInfoTemplate(extraInfoTemplateRef, 'an')"
                    *ngIf="isCreatedUser && !isIncidentSubmitted"
                  >
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i>
                  </span>
                </div>
              </div>
              <dd class="gt-multi-line">{{ detailData?.information }}</dd>
            </dl>
            <dl *ngIf="detailData?.corrective_actions">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <dt>Resolution</dt>
                </div>
                <div>
                  <span
                    (click)="openExtraInfoTemplate(extraInfoTemplateRef, 'ca')"
                    *ngIf="isCreatedUser && !isIncidentSubmitted"
                  >
                    <i
                      class="fa-solid fa-pen-to-square md-font-size text-secondary"
                    ></i>
                  </span>
                </div>
              </div>
              <dd class="gt-multi-line">
                {{ detailData?.corrective_actions }}
              </dd>
            </dl>
          </div>
        </div>

        <!-- Updates & Photos Section -->
        <div *ngIf="incidentCommentFiles?.length">
          <div class="text-end mb-2" *ngIf="incidentCommentFiles?.length > 1">
            <button
              class="btn btn-sm btn-secondary"
              (click)="toggleSortOrder()"
            >
              Sort
              <span>
                <i class="fa-solid fa-sort"></i>
              </span>
            </button>
          </div>

          <div class="row row-cols-1">
            <ng-container *ngIf="incidentCommentFiles?.length">
              <div
                *ngFor="let msg of sortedIncidentCommentFiles; let i = index"
              >
                <dl class="mb-0">
                  <dt>
                    <!--update details section-->

                    {{ msg?.updated_at | date : "d MMM y HH:mm" }}
                    {{
                      subscriberUserId == msg?.subs_user_id
                        ? ""
                        : "| " + msg?.user_name
                    }}
                    <span
                      *ngIf="
                        msg?.history_changes?.length &&
                        (subscriberUserId == msg?.subs_user_id ||
                          isAdmin ||
                          isDispatcher)
                      "
                    >
                      | Edited [{{
                        (
                          msg?.history_changes
                          | misc : "lastCommentEditedDateTime"
                        )?.updated_at | date : "d MMM y HH:mm"
                      }}]</span
                    >

                    <span *ngIf="msg?.file">
                      | Source
                      {{
                        msg?.event_action === 11
                          ? "[Device Library]"
                          : "[Camera]"
                      }}
                    </span>

                    <span *ngIf="[8, 9].includes(msg?.event_action)">
                      | Report Sent
                    </span>

                    <ng-container *ngIf="isCreatedUser && !isIncidentSubmitted">
                      <span class="float-end me-2" (click)="deletePhoto(msg)">
                        <i
                          class="fa-regular fa-trash-can md-font-size text-secondary"
                        ></i>
                      </span>
                      <span
                        class="float-end me-2"
                        (click)="openCommentTemplate(commentRef, msg)"
                      >
                        <i
                          class="fa-solid fa-pen-to-square md-font-size text-secondary"
                        ></i>
                      </span>
                    </ng-container>
                  </dt>

                  <dd class="mb-1">
                    <!-- comment / photo -->

                    <div>
                      <span *ngIf="[8, 9].includes(msg?.event_action)"
                        ><dt>Recipients:</dt></span
                      >

                      <span class="gt-multi-line">
                        {{
                          msg?.detail === "null" || msg?.detail === "undefined"
                            ? ""
                            : msg?.detail
                        }}
                      </span>

                      <span *ngIf="msg?.event_action === 0" class="small-font"
                        >[Checkpoint Scan]</span
                      >
                      <span *ngIf="msg?.event_action === 6" class="small-font"
                        >[QR Scan]</span
                      >
                    </div>
                    <img
                      (click)="onOpenImage($event, msg)"
                      *ngIf="msg?.file"
                      [src]="msg?.file"
                      height="120"
                      width="120"
                      class="cursor-pointer"
                    />
                  </dd>
                </dl>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="col-lg-3 d-none d-md-block">
          <div class="row gy-2">
            <div>
              <button
                class="btn btn-primary btn-lg btn-block w-100"
                *ngIf="
                  !detailData?.information &&
                  isCreatedUser &&
                  !isIncidentSubmitted
                "
                (click)="openExtraInfoTemplate(extraInfoTemplateRef, 'an')"
              >
                + Incident Details
              </button>
            </div>

            <ng-container *ngIf="isCreatedUser && !isIncidentSubmitted">
              <div>
                <button
                  class="btn btn-primary btn-lg btn-block w-100"
                  (click)="openCamPopup()"
                >
                  Add Photo
                </button>
              </div>

              <div *ngIf="!detailData?.corrective_actions">
                <button
                  class="btn btn-primary btn-lg btn-block w-100"
                  (click)="openExtraInfoTemplate(extraInfoTemplateRef, 'ca')"
                >
                  Resolution
                </button>
              </div>
            </ng-container>

            <div *ngIf="selectedUsers?.length">
              <button
                class="btn btn-primary btn-lg btn-block w-100"
                *ngIf="
                  !isIncidentSubmitted &&
                  (isCreatedUser || isAdmin || isDispatcher)
                "
                (click)="markResolved(resolTimeTemplateRef)"
              >
                Send Incident Report
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!selectedUsers.length" class="alert alert-info">
        This site has no contacts to receive incident reports. Contact your
        manager or supervisor.
      </div>
    </div>

    <!-- Bottom Fixed Action Buttons -->
    <div id="fixedBtn" class="bottom-section-buttons row d-md-none">
      <div
        *ngIf="
          !detailData?.information && isCreatedUser && !isIncidentSubmitted
        "
      >
        <button
          class="btn btn-outline-primary btn-lg btn-block w-100 d-md-none"
          (click)="openExtraInfoTemplate(extraInfoTemplateRef, 'an')"
        >
          + Incident Details
        </button>
      </div>

      <ng-container *ngIf="isCreatedUser && !isIncidentSubmitted">
        <div *ngIf="detailData?.status?.status_identity?.value !== 1">
          <div
            class="d-flex align-items-center justify-content-center w-100 btm-btn-height mb-2"
            (click)="openCamPopup()"
          >
            <button class="btn btn-outline-primary btn-lg btn-block w-100">
              + Add a Photo
            </button>
          </div>

          <div
            *ngIf="!detailData?.corrective_actions"
            class="d-flex align-items-center justify-content-center w-100 btm-btn-height"
            (click)="openExtraInfoTemplate(extraInfoTemplateRef, 'ca')"
          >
            <button class="btn btn-primary btn-lg btn-block w-100">
              Resolution
            </button>
          </div>
        </div>
      </ng-container>

      <div
        *ngIf="
          !isIncidentSubmitted && (isCreatedUser || isAdmin || isDispatcher)
        "
        class="d-flex align-items-center justify-content-center w-100 btm-btn-height mb-3"
        (click)="markResolved(resolTimeTemplateRef)"
      >
        <button class="btn btn-success btn-lg btn-block w-100">
          Send Incident Report
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ADD COMMENT  DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <div class="card dialog-box">
    <div class="card-header">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}
      <span class="float-end" (click)="commentRef.close(); newValue = ''">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-group input-group">
        <textarea
          class="form-control"
          rows="5"
          wrap="hard"
          maxlength="340"
          placeholder="Enter comment here"
          autofocus
          [(ngModel)]="newValue"
          id="updateCommentBox"
        ></textarea>
        <span
          class="input-group-text"
          *ngIf="newValue"
          (click)="clearAndFocusTextBox('updateCommentBox')"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div class="row row-cols-2 mt-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close(); newValue = ''"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="addComment(data); commentRef.close()"
          >
            {{ data?.detail ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- edit corrective actions/ additonal notes  DIALOG -->
<ng-template
  #extraInfoTemplateRef
  let-extraInfoTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card m-0">
    <div class="card-header">
      {{ data?.extrainfoType == "ca" ? "Resolution" : "Incident Details" }}
      <span class="float-end" (click)="extraInfoTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="form-group input-group my-2">
        <textarea
          class="form-control"
          rows="5"
          wrap="hard"
          maxlength="340"
          placeholder="Start typing"
          autofocus
          [(ngModel)]="newValue"
          id="updateextrainfoType"
        ></textarea>
        <span
          class="input-group-text"
          *ngIf="newValue"
          (click)="clearAndFocusTextBox('updateextrainfoType')"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div class="row row-cols-2 mt-2">
        <div class="col">
          <button
            (click)="extraInfoTemplateRef.close(); newValue = ''"
            class="btn btn-dark btn-lg w-100"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            (click)="updateExtraInfo(data); extraInfoTemplateRef.close()"
            class="btn btn-primary btn-lg w-100"
            [disabled]="previousTextValue === newValue"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- resolution time template-->
<ng-template
  #resolTimeTemplateRef
  let-data
  let-resolTimeTemplateRef="dialogRef"
>
  <div class="dialog-box card m-0">
    <div class="card-header">
      Send Incident Report
      <span class="float-end" (click)="resolTimeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="scrollable-y">
      <!--incident Time section-->
      <div *ngIf="resDateTimeErrorMessage" class="mt-3">
        {{ resDateTimeErrorMessage }}
      </div>

      <div class="row row-cols-1 mt-3 mb-2" *ngIf="!isIncidentSubmitted">
        <div class="col">
          <div class="form-floating">
            <input
              autofocus
              id="rDayField"
              class="form-control"
              placeholder="Incident Resolved"
              type="datetime-local"
              [(ngModel)]="resolutionDay"
              (change)="formateStartDate($event)"
            /><label for="inputAssignee"> Incident Resolved</label>
          </div>
        </div>
      </div>
      <div class="my-3">
        <div *ngIf="fetchingData">Getting data from server</div>

        <div *ngIf="!fetchingData && selectedUsers?.length === 0" class="mt-3">
          No contacts available
        </div>

        <div class="list-group overflow-auto">
          <div class="mb-2" *ngIf="selectedUsers?.length">Recipients</div>
          <li
            *ngFor="let user of slicedData(selectedUsers); let i = index"
            class="list-group-item mb-1"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="!user.isSnubbed"
                (change)="user.isSnubbed = !user.isSnubbed"
                id="check-{{ user.id }}-{{ i }}"
              />
              <label class="form-check-label" for="check-{{ user.id }}-{{ i }}">
                {{
                  user?.user_full_name ? user?.user_full_name : user.full_name
                }}
              </label>
            </div>
          </li>
        </div>
        <div>
          <button
            class="btn btn-outline-secondary btn-sm"
            *ngIf="
              selectedUsers?.length > this.slicedUsersCount && !displayAllUsers
            "
            (click)="displayAllUsers = !displayAllUsers"
          >
            + {{ selectedUsers?.length - this.slicedUsersCount }} more
          </button>
        </div>
      </div>
      <div class="row row-cols-1 mt-2">
        <div class="col d-flex justify-content-end">
          <button
            type="button"
            (click)="resolTimeTemplateRef.close(); submitIncident()"
            class="btn btn-success btn-lg w-100"
            [disabled]="!resolutionDateTime || !hasUnsnubbedUsers()"
          >
            Send Report
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- edit incident time  DIALOG -->
<ng-template
  #incidentTimeTemplateRef
  let-incidentTimeTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Update Incident Time

      <span class="float-end" (click)="incidentTimeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="col my-2">
        <div class="row row-cols-1">
          <div class="col">
            <div class="form-floating">
              <input
                id="mobiDayField"
                class="form-control"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Incident Date"
                type="datetime-local"
                (change)="formateNewStartDate($event)"
                [(ngModel)]="newDateValue"
              /><label for="mobiDayField"> Incident Date</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-2 mt-2">
        <div class="col">
          <button
            (click)="incidentTimeTemplateRef.close(); newValue = ''"
            class="btn btn-dark btn-lg w-100"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            (click)="updateincidentTime(data); incidentTimeTemplateRef.close()"
            class="btn btn-primary btn-lg w-100"
            [disabled]="!newDateValue"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- edit incident type  DIALOG -->
<ng-template
  #incidentTypeTemplateRef
  let-incidentTypeTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Update Incident Type

      <span class="float-end" (click)="incidentTypeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="mb-3">
        <gtapp-auto-complete
          [data]="incidentTypeList"
          [maxLength]="256"
          placeHolder="Search Incidents Type"
          searchKeyword="name"
          [initialValue]="previousincidentTypeValue"
          [itemTemplate]="incidentTypeTemplate"
          [addInitialKey]="previousincidentTypeValue"
          (inputChanged)="onSearchIncidentType($event)"
          (selected)="onSelectIncidentType($event)"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #incidentTypeTemplate let-item>
        @if (item?.key === 0) {
        <div>Add New Incident Type</div>
        } @else {

        <div>{{ item?.name }}</div>

        }
      </ng-template>

      <div class="row row-cols12 mt-2">
        <div class="col">
          <button
            (click)="incidentTypeTemplateRef.close(); newValue = ''"
            class="btn btn-dark btn-lg w-100"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
