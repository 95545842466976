import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DIALOG_DATA, globalLicenseInfo } from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../../../../shared/components/modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'gtapp-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrl: './add-edit-user.component.scss',
})
export class AddEditUserComponent implements OnInit {
  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    first_name: new UntypedFormControl('', Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    display_name: new UntypedFormControl(''),
    contact_number: new UntypedFormControl(''),
  });

  @Input() editUser: any;
  @Input() detailData: any;
  @Input() fromUserPage: boolean = false;

  licenseDetails = {
    individualDetails: globalLicenseInfo?.details,
  };

  lastActivity: any;

  isSuperUser: any;
  userDialogueRef: any;

  isAdmin: boolean = false;
  userGroupListData: any = [];
  isUserAboveManager: boolean = false;
  userData: any;
  isOwner: boolean = false;

  userFullName: string = '';

  reportsConfigured: any;

  showGroupInfo: boolean = false;
  editUserDetails: boolean = false;

  constructor(
    @Optional() protected dialogRef: DialogRef,
    private appService: AppService,
    private userService: UsersService,
    private spinnerService: LoadingSpinnerService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private dialogService: ModelDialogueService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
  }

  ngOnInit(): void {
    this.editUser = this.dialogueData?.editUser;
    this.detailData = this.dialogueData?.detailData;
    this.fromUserPage = this.dialogueData?.fromUserPage;

    if (this.editUser == true) {
      this.spinnerService.show();
      this.userService
        .getUserById(
          this.detailData.pk ? this.detailData.pk : this.detailData.id,
          { reports_configured: this.fromUserPage ? 1 : 0 }
        )
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.formatData(response);
          }
          this.spinnerService.hide();
        });
    }
    if (this.fromUserPage) {
      this.getUserGroups();
    }
  }
  isUserGroupPrivilageCheck() {
    this.detailData?.user_group?.forEach((item: any) => {
      if ([4].includes(item?.group_identity)) {
        this.isOwner = true;
      }
      if ([1, 4].includes(item?.group_identity)) {
        this.isUserAboveManager = true;
      }
    });
  }
  formatData(response: any) {
    this.detailData = response['data'];

    this.reportsConfigured = response?.reports_configured;
    this.formatGroupList();
    this.userFullName = (this.detailData?.full_name || '')?.trim();
    this.lastActivity = response['last_activity'];
    this.isOwner = false;
    this.isUserAboveManager = false;
    this.spinnerService.hide();
    this.isUserGroupPrivilageCheck();
  }
  formatGroupList() {
    this.userGroupListData = this.userGroupListData?.filter(
      (item: any) => item?.group_id !== 4
    );
    if (this.detailData?.user_group?.length) {
      this.userGroupListData?.forEach((grp: any) => {
        grp.disabled = this.disableSelectOption(grp);
        grp.selected = this.detailData?.user_group?.some(
          (selectedGrp: any) => selectedGrp?.id === grp?.id
        );
      });
    } else {
      this.userGroupListData?.forEach((grp: any) => {
        grp.selected = grp?.group_id === 2;
      });
    }
  }

  getUserGroups() {
    this.spinnerService.show();
    this.userService.getUserGroups({ rows: 10 }).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userGroupListData = response?.data;
        this.formatGroupList();
      }
      this.spinnerService.hide();
    });
  }

  onCloseDialogue(val = 'submit') {
    this.dialogRef.close(val);
  }
  onClearForm() {
    this.userForm.reset();
    this.formatGroupList();
  }

  //Send the invitation link in user's email
  sendAddUserLink() {
    this.spinnerService.show();
    const grpIds = [
      ...new Set(
        this.userGroupListData
          ?.filter((grp: any) => grp?.selected)
          ?.map((grp: any) => grp?.id)
      ),
    ];

    let requestData = { data: { ...this.userForm.value, group_ids: grpIds } };
    this.userService.addUser(requestData).then((response: any) => {
      if (response['status'] == 'success') {
        this.router.navigate(['/users'], { fragment: undefined });
        this.onCloseDialogue(response['data']);
      } else {
        this.toasterService.setMessage({
          errorMessage: response['message'],
          successMessage: '',
        });
      }
      this.spinnerService.hide();
    });
  }
  reInviteUser(data: any) {
    let dialogMsg = `Send ${data?.email} another email to sign into Guard Tracker?`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Resend User Sign In Link',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        let requestData = { data: { subscriber_user_id: data?.id } };
        this.userService
          .addUser(requestData, { re_invite: 1 })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.onCloseDialogue();
          });
      }
    });
  }
  disableOrEnableUser(id: any) {
    this.spinnerService.show();
    this.userService.userEnableDisable(id).then((response: any) => {
      if (response['status'] == 'success') {
        this.toasterService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });
      } else {
        this.toasterService.setMessage({
          errorMessage: response['message'],
          successMessage: '',
        });
      }
      this.spinnerService.hide();
      this.onCloseDialogue();
    });
  }
  deleteUserById(data: any) {
    let dialogMsg = `Are you sure to remove ${
      data?.full_name || data?.email
    }'s association ? `;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Remove Association',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.userService.deleteUserById(data?.id).then((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
          } else {
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
          this.spinnerService.hide();
          this.onCloseDialogue();
        });
      }
    });
  }

  redirectUserGroup(group: any) {
    this.dialogRef.close('close');
    this.router.navigate(['/user-group', { uGKey: String(group?.id) }]);
  }

  addRemoveGroup(groupId: string, action: String = 'add') {
    this.spinnerService.show();
    const actionParam: any = { reports_configured: this.fromUserPage ? 1 : 0 };
    actionParam[action === 'add' ? 'add_group' : 'remove_group'] = 1;

    this.updateUser(
      {
        id: this.detailData.id,
        group_id: groupId,
      },
      actionParam
    );
  }

  disableSelectOption(grpData: any) {
    if (
      this.detailData?.user_group?.length === 1 &&
      this.detailData?.user_group?.some(
        (grpId: any) => grpId?.id === grpData?.id
      )
    ) {
      // if only one group is there block its unselection
      return true;
    }
    if (grpData?.group_id === 1 && this.detailData?.subscriber_admin) {
      // block removal of subscriber admin
      return true;
    }
    if (
      grpData?.group_id === 1 &&
      this.userData?.subscriber?.subscriber_user_id === this.detailData?.id
    ) {
      // logged in Managers user cannot remove himself from Managers group
      return true;
    }
    if (grpData?.group_id === 4) {
      // block Allowing to select the other user as Administrator group.
      return true;
    }
    return false;
  }
  updateUser(body: any, params: any) {
    this.spinnerService.show();
    this.userService.updateUser(body, params).then((response: any) => {
      if (response['status'] == 'success') {
        this.formatData(response);

        this.toasterService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });
      } else {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  changeDefaultReportRecipent() {
    this.updateUser(
      {
        id: this.detailData.id,
      },
      { default_recipient: 1 }
    );
  }
  addRemoveReportRecipients(action: String = 'add') {
    let paramDict: any = {
      modify_report_recipient: 1,
      reports_configured: this.fromUserPage ? 1 : 0,
    };
    paramDict[action === 'add' ? '' : 'remove_as_report_recipient'] = 1;
    let dialogMsg =
      action === 'add'
        ? `Send this email address reports for all completed jobs and existing scheduled patrol reports.`
        : `Do not send completed job reports to this email address. \nRemove this email address from all scheduled reports.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Reporting',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.updateUser(
          {
            id: this.detailData.id,
          },
          paramDict
        );
      }
    });
  }
  updateDisplayName() {
    this.userForm.controls['display_name'].setValue(
      `${this.userForm.value?.first_name} ${this.userForm.value?.last_name}`
    );
  }
  mobileNumberCheck() {
    if (!this.userForm.value?.contact_number) {
      this.userForm.controls['contact_number'].setValue(null);
      this.userForm.controls['contact_number'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.userForm.controls['contact_number'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }
  editUserDetailsFn() {
    this.editUserDetails = true;
    this.userForm.patchValue(this.detailData);
    this.userForm.patchValue({
      display_name: this.detailData?.user_add_info?.display_name,
      contact_number: this.detailData?.user_add_info?.contact_number,
    });
  }
  updateUserDetails() {
    this.spinnerService.show();
    this.userService
      .updateUser(
        {
          id: this.detailData?.id,
          display_name: this.userForm.value?.display_name,
          contact_number: this.userForm.value?.contact_number,
        },
        { update_additional_info: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
          this.editUserDetails = false;
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  clearField(fieldId: string) {
    this.userForm.controls[fieldId].setValue('');
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(fieldId);
      element?.focus();
    }, 100);
  }
}
