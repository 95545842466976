import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { interval, takeWhile } from 'rxjs';
import { environment } from '../../../environments/environment';
import { currentTheme, isLargeScreen } from '../../../global.variable';
import {
  MENU_ITEMS,
  appUserMenu,
  superUserMenu,
} from '../../../sidebar-user-menu';
import { AppService } from '../../app.service';
import { PermissionsService } from '../../auth/permissions.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../shared/components/modal-dialogue/model-dialogue.service';
import { MultipleSubscribersComponent } from '../../shared/components/multiple-subscribers/multiple-subscribers.component';
import { PwaInstallationGuideComponent } from '../../shared/components/pwa-installation-guide/pwa-installation-guide.component';
import { WhatsNewComponent } from '../../shared/components/whats-new/whats-new.component';
import { DataCheckService } from '../../shared/services/data-check.service';
import { gTDB } from '../../shared/services/db';
import { DeviceInfoService } from '../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../shared/services/orientationlock.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { WebSocketService } from '../../shared/services/web-socket.service';
import { ProfileService } from '../profile/profile.service';
import { AppChecksComponent } from './app-checks/app-checks.component';
import { CheckpointService } from './checkpoints/checkpoint.service';
import { JobsService } from './jobs/jobs.service';
import { PagesService } from './pages.service';
import { UserPromptService } from './user-prompts/user-prompt.service';

declare var bootstrap: any;
declare var GuideChimp: any;
@Component({
  selector: 'gtapp-pages',
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss',
})
export class PagesComponent implements OnInit {
  currentTheme: any = currentTheme;

  sideBarMenuItems: any;
  sideBarMenuItemsBackup: any = [];

  loggedInUser: any = {};

  navbarToggled: boolean = false;

  settingItems: any = [];

  isSubscriptionActive: any;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  isClockedIn: boolean = false;
  isGuardUser: any;
  isSubscriberAdmin: boolean = false;

  isSuperUser: boolean = false;
  userData: any;

  largeView: Boolean = isLargeScreen;
  // notification params
  notificationCount: any;
  unReadMessageCount: number = 0;
  notificationData: any = [];
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  showWelfareCheckButton: boolean = false;
  welfareTempHoldValue: boolean = false;
  welfareCheckOfflineAlert: any = {};
  userMenu: any = appUserMenu;

  @ViewChild('toggleButton')
  toggleButton!: ElementRef;
  @ViewChild('menu')
  menu!: ElementRef;
  @ViewChild('menuSside')
  menuSside!: ElementRef;

  showBottomButton = true;
  windowScrolled: boolean = false;
  showUserDropdown: boolean = false;

  hasMultipleAssociation: boolean = false;
  ipAddress: any;
  patrolData: any;

  expandIcon: boolean = false;

  currentRoute: string = '';

  dialogRef: any;

  showManageMenu: boolean = false;

  isInBackgroundSync: boolean = false;
  isAppOnline: boolean = true;
  isOnEvent: boolean = false;
  offlineMode: boolean = false;
  messageChannel = new MessageChannel();
  isDragging = false;

  dragging: boolean = false;
  intervalId: any;

  deferredPrompt: any;
  isHomeComponent: boolean = false;
  allowedPaths: boolean = false;

  isOnlyGuardAndDispatcher: boolean = false;
  isPreviousSubscribed: boolean = false;

  feedbackForm = new FormGroup({
    feedback: new FormControl('', [
      Validators.required,
      Validators.maxLength(340),
    ]),
  });
  notificationPermission: any;
  sosAlertCount: number = 0;
  beepIntervalSubscription: any;
  isAlertActive: boolean = false;
  permissionErrorStatus: any;
  // varaible to check how many days are left for the subscriber in the grace period
  isInGracePeriod: any = this.dataCheckService.isInGracePeriod();

  internetConnectionAvailable: boolean = navigator.onLine;
  isAppInstalled: boolean = false;
  serviceWorkerEnabled: boolean = false;
  guideChimp: any;
  onBoarding: any = {};
  guideIdMapping: any = {
    '/client': 'manageSite',
    '/siteList': 'manageSite',
    '/checkpoints': 'manageCheckpoint',
    '/jobs': 'manageJob',
    '/roster': 'manageRoster',
    '/users': 'manageGuard',
    '/dashboard': 'home',
    '/missed-events': 'manageWelfare',
    '/sos-alerts': 'manageSOSAlerts',
    '/hand-over-log': 'manageHOL',
    '/reports': 'manageReports',
  };
  routeInsideRoot: boolean = true;
  rootLevelRoutes: any = [];

  isPhoneIOS = this.deviceInfoService.isPhoneIOS();
  guideChimpInterval: any;
  currentTourElement: string = '';
  guideItems: any = [];
  guideStyleOperationsNeeded: any = {};

  newSupportUpdate: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private profileService: ProfileService,
    private spinnerService: LoadingSpinnerService,
    private pageService: PagesService,
    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private ngZone: NgZone,
    private viewContainerRef: ViewContainerRef,
    private jobService: JobsService,
    private toasterService: ToasterService,
    private swPush: SwPush,
    private orientationLockService: OrientationLockService,
    private permissionsService: PermissionsService,
    private cdr: ChangeDetectorRef,
    private deviceInfoService: DeviceInfoService,
    private webSocketService: WebSocketService,
    private deviceService: DeviceDetectorService,
    private swUpdate: SwUpdate,
    private checkpointService: CheckpointService,
    private userPromptService: UserPromptService
  ) {
    this.currentTheme = this.appService.getGTAppTheme();

    this.appService.switchGTAppTheme(this.currentTheme);
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.triggerCleanUp();
      }
    });
    this.serviceWorkerEnabled = this.swUpdate.isEnabled;

    this.updateAppCheck();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar

        this.enableScrollOnBody();
        this.pageService.refreshToken().then((value: any) => {
          if (value) this.updateLoggedInUserDetail();
        });
      }

      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url.split('#');

        if (currentUrl?.length && currentUrl[0] != this.currentRoute) {
          this.currentRoute = currentUrl[0];
          this.triggerCleanUp();

          if (this.currentRoute !== '/delete-account')
            this.getNotificationCount();

          this.getSideBarBackIcon();

          window.scrollTo(0, 0);
        }

        this.isHomeComponent =
          event.urlAfterRedirects === '/dashboard' ||
          event.urlAfterRedirects === '/';
        this.allowedPaths = ['/profile', '/support', '/subscription'].includes(
          event.urlAfterRedirects
        );
        this.isInGracePeriod = this.dataCheckService.isInGracePeriod();
        if (event.urlAfterRedirects === '/subscription') {
          this.isInGracePeriod = false;
        }
      }

      this.isSubscriptionActive =
        this.dataCheckService.checkIsSubscriptionOrTestUser();
    });

    this.windowScrolled = false;
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService?.isSuperUser();
    this.isSubscriberAdmin = this.dataCheckService?.isSubscriberAdmin();
    this.isAdmin = this.dataCheckService?.isUserAdmin();
    this.isDispatchUser = this.dataCheckService?.isDispatchUser();
    this.isGuardUser = this.dataCheckService?.isGuardUser();
    this.isOnlyGuardAndDispatcher =
      (this.isGuardUser || this.isDispatchUser) && !this.isAdmin;
    this.isClockedIn = this.dataCheckService?.isGuardUserClockendIn();
    this.isPreviousSubscribed =
      this.dataCheckService?.checkIsPreviouslySubscribedAndSubscriptionInActive();
    this.isSubscriptionActive =
      this.dataCheckService.checkIsSubscriptionOrTestUser();
    this.hasMultipleAssociation =
      this.dataCheckService?.hasMultipleSubscriberAssociation();
  }
  triggerCleanUp() {
    this.dialogRef?.close();
    this.appService.removeToolTips();
  }
  goBack() {
    sessionStorage.removeItem('fromSite');
    window.history.back();
  }

  updateAppCheck() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      // to trigger the installation prompt
    });
  }
  openChangeLog() {
    this.spinnerService.show();
    this.profileService.getVersionInfo().subscribe((response: any) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();

        this.dialogRef = this.dialogService.open(WhatsNewComponent, {
          data: { latestVersion: response },
        });
      }
      this.spinnerService.hide();
    });
  }
  showSubscriptionOption(): boolean {
    if (this.isSubscriberAdmin) {
      if (this.dataCheckService.isTestAccount()) return false;
      if (this.dataCheckService.isTrialAccount()) return true;
      return true;
    }

    return false;
  }

  async arrangeUserMenu() {
    this.userMenu = appUserMenu;
    this.userMenu = this.userMenu?.filter((item: any) => {
      if (item.title === 'Subscription') {
        return Boolean(
          this.showSubscriptionOption() && this.checkPrevouslySubscribed()
        );
      } else if (item.title === 'Register QR Code') {
        return (this.isAdmin || this.isClockedIn) && this.isSubscriptionActive;
      } else if (item.title === 'Edit Profile') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Support') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Change Log') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Change Account') {
        return this.hasMultipleAssociation;
      } else {
        return true;
      }
    });
    this.rootLevelRoutes = this.sideBarMenuItemsBackup?.map(
      (route: any) => route?.link
    );

    const settingsRootLevelRoutes = this.settingItems?.map(
      (route: any) => route?.link
    );

    if (settingsRootLevelRoutes?.length) {
      this.rootLevelRoutes = [
        ...this.rootLevelRoutes,
        ...settingsRootLevelRoutes,
      ];
    }
    this.getSideBarBackIcon();
  }
  conditionalUpdateSideBarMenuItems() {
    let subscriberStats = JSON.parse(
      localStorage.getItem('subscriberStats') || '{}'
    );
    const uniqueUserCount =
      this.pageService.getGroupUserCount('unique_user_count');

    this.sideBarMenuItems = this.sideBarMenuItemsBackup?.filter(
      (menuItem: any) => {
        if (menuItem?.link === '/reports') {
          return subscriberStats?.company_count > 0;
        }
        if (menuItem?.link === '/incidents') {
          return subscriberStats?.company_count > 0;
        }
        if (menuItem?.link === '/missed-events') {
          return (
            uniqueUserCount > 1 || subscriberStats?.missed_welfare_check_exists
          );
        }
        if (menuItem?.link === '/sos-alerts') {
          return uniqueUserCount > 1 || subscriberStats?.sos_alert_exists;
        }
        if (menuItem?.link === '/hand-over-log') {
          return uniqueUserCount > 1 || subscriberStats?.hol_exists;
        }
        if (menuItem?.link === '/message') {
          return uniqueUserCount > 1 || subscriberStats?.in_app_message_exists;
        }

        return true;
      }
    );
  }
  getSideBarBackIcon() {
    this.routeInsideRoot =
      this.rootLevelRoutes?.includes(this.currentRoute) ||
      this.currentRoute === '/' ||
      this.currentRoute === '/dashboard' ||
      this.largeView;
  }
  async getSideBar() {
    this.spinnerService.show();

    this.profileService
      .getUserConfig({
        remember_login: this.dataCheckService.isTrustedDevice() ? 1 : 0,
        time_offset: new Date().getTimezoneOffset(),
      })
      .subscribe((resp: any) => {
        if (resp['status'] == 'success') {
          let permissionToken = resp?.user_permission_token;
          let userData = this.appService.getUserData();
          if (permissionToken) {
            userData.user_token = permissionToken;
          }
          this.appService.setUserData(userData);
          this.isSubscriptionActive =
            this.dataCheckService.checkIsSubscriptionOrTestUser();
          if (this.isSubscriberAdmin && !this.isSubscriptionActive) {
            this.router.navigate([
              '/subscription',
              {
                state: 'Account Verified! Subscription Required',
              },
            ]);
          }
          if (this.isClockedIn || this.isAdmin || this.isSubscriberAdmin) {
            this.sideBarMenuItems = resp['data'];
            if (this.sideBarMenuItems.length === 0) {
              this.sideBarMenuItems = MENU_ITEMS;
            }

            if (this.isAdmin) {
              this.settingItems = [
                {
                  title: 'COMPANY PROFILE',
                  link: '/company-profile',
                  icon: 'pantone-outline',
                  id: 'manageCompanyProfile',
                },
                {
                  title: 'USERS',
                  link: '/users',
                  icon: 'people-outline',
                  id: 'manageGuards',
                },
                {
                  title: 'INCIDENT TYPES',
                  link: '/incident-type',
                  icon: 'layers-outline',
                  id: 'manageIncidentTypes',
                },
                {
                  title: 'MONITORING COMPANIES',
                  link: '/monitoring-company',
                  icon: 'shield-outline',
                  id: 'manageMCompanies',
                },
                {
                  title: 'RESPONSE TYPES',
                  link: '/response-type',
                  icon: 'layers-outline',
                  id: 'manageResponseTypes',
                },
                {
                  title: 'SERVICE TYPES',
                  link: '/service-type',
                  icon: 'layers-outline',
                  id: 'manageServiceTypes',
                },
                {
                  title: 'USER PROMPTS',
                  link: '/user-prompts',
                  icon: 'layers-outline',
                  id: 'manageUserPrompts',
                },
                // {
                //   title: 'INVOICING',
                //   link: '/invoice-jobs',
                //   icon: 'layers-outline',
                //   id: 'manageInvoiceJobs',
                // },
              ];
            }
          } else {
            this.sideBarMenuItems = [];
          }
        }
        this.sideBarMenuItemsBackup = Object.freeze(this.sideBarMenuItems);

        this.spinnerService.hide();
        this.arrangeUserMenu();
        this.conditionalUpdateSideBarMenuItems();
      });
  }
  checkWelfareChecks() {
    if (!this.isOnEvent && this.welfareTempHoldValue) {
      this.showWelfareCheckButton = true;
    }
  }
  isServerOnline() {
    return new Promise((resolve) => {
      try {
        this.appService.getCountries().subscribe({
          next: (response: any) => {
            resolve(response?.status === 'success');
          },
          error: (err) => {
            resolve(false);
          },
        });
      } catch (error) {
        resolve(false);
      }
    });
  }

  async checkServerAndSync() {
    if (this.offlineMode && this.isHomeComponent && navigator.onLine) {
      const isServerOnlineVar = await this.isServerOnline();
      if (isServerOnlineVar) {
        this.isAppOnline = true;
        this.offlineMode = !this.isAppOnline;
        this.deviceInfoService.deviceInfoSubject.next({ appStatus: true });
        localStorage.setItem('syncData', 'true');
      }
    }
    setTimeout(() => {
      if (
        this.isAppOnline &&
        this.isHomeComponent &&
        !this.isOnEvent &&
        navigator.onLine &&
        localStorage.getItem('syncData') === 'true'
      ) {
        window.location.reload();
      }
    }, 1000);
  }

  async checkOnlineOffline(isAppOnline: boolean = true) {
    // if the user is not on middle of the update reload the page when the device comes back online

    this.isAppOnline = isAppOnline;
    this.offlineMode = !this.isAppOnline;

    this.checkServerAndSync();
    this.appService.initializeBootstrapTooltips();
    setTimeout(() => {
      this.checkServerAndSync();
    }, 5000);

    if (this.offlineMode && !this.isOnEvent && !this.isHomeComponent) {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: `Unable to connect to server, operating in offline mode.`,
          });
        }, 100);

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
      });
    }
  }
  syncQueuedData() {
    setTimeout(() => {
      if (!this.isInBackgroundSync) {
        this.isInBackgroundSync = true;

        // ask the service worker to start sync
        navigator?.serviceWorker?.controller?.postMessage({
          type: 'syncData',
        });
        // send a fallbackcheck to see background sync is complete
        setTimeout(() => {
          this.fallBackCheck();
        }, 2000);
      }
    }, 100);
  }
  fallBackCheck() {
    /// a delayed to check whether the background sync is over or not
    if (this.isInBackgroundSync) {
      navigator?.serviceWorker?.controller?.postMessage({
        type: 'syncDataCheck',
      });
    }
  }
  serviceWorkerOperations() {
    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_PAGES_PORT',
      },
      [this.messageChannel.port2]
    );

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event?.data?.type === 'syncDataCheck') {
        if (this.isInBackgroundSync) {
          if (event?.data?.syncInProgress) {
            setTimeout(() => {
              this.fallBackCheck();
            }, 5000);
          } else {
            this.isInBackgroundSync = false;
          }
        }
      }
    });

    // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.type === 'syncComplete') {
        if (this.isInBackgroundSync) {
          this.isInBackgroundSync = false;
        }
      }

      if (
        event?.data?.type === 'welfareCheckAlert' &&
        !this.isAppOnline &&
        !this.welfareTempHoldValue &&
        !this.showWelfareCheckButton
      ) {
        this.welfareCheckOfflineAlert = event?.data;
        this.cdr.detectChanges();
      }
      if (event?.data?.message) {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: event?.data?.message,
        });
      }
      if (event?.data?.message) {
        this.ngZone.run(() => {
          if (event?.data?.status === 'success') {
            this.toasterService.setMessage({
              successMessage: event?.data?.message,
              errorMessage: '',
            });
          } else {
            this.toasterService.setMessage({
              errorMessage: event?.data?.message,
              successMessage: '',
            });
          }
        });
      }
    };

    if (localStorage.getItem('syncData') === 'true' && !this.offlineMode) {
      localStorage.removeItem('syncData');
      this.syncQueuedData();
    }

    this.appService.updateGuardTrackerApp();
  }

  async ngOnInit() {
    let scrollTimeout: any;

    window.addEventListener('touchend', () => {
      this.windowScrolled = window.scrollY > 100;
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        this.windowScrolled = false;
        clearTimeout(scrollTimeout);
      }, 3000);
    });

    if (this.isSuperUser !== true && this.userData?.access) {
      await this.getSideBar();
    }
    const isIOS = this.deviceInfoService.isPhoneIOS();
    if (!isIOS) {
      window.addEventListener('appinstalled', (event) => {
        // PWA app is installed
        this.isAppInstalled = true;
      });
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.isAppInstalled = true;
    }
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      let device = this.deviceService.getDeviceInfo();
      if (
        window.matchMedia('(display-mode: standalone)').matches ||
        (device.device !== 'iPhone' && !this.deferredPrompt)
      ) {
        this.isAppInstalled = true;
      }
    });
    if (isIOS) {
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault();
      });

      document.addEventListener('gesturechange', function (e) {
        e.preventDefault();
      });
      document.addEventListener('gestureend', function (e) {
        e.preventDefault();
      });
    } else {
      document.addEventListener('touchstart', function (e) {
        document.body.style.touchAction = 'manipulation';
      });
    }
    this.webSocketService.webSocketSubject.subscribe((event: any) => {
      if (event?.message) {
        this.toasterService.setMessage({
          successMessage: event['message'],
          errorMessage: '',
        });
        this.getNotificationCount();
      }
      if (event?.event === 'hol') {
        sessionStorage.setItem('handOverLogUpdate', '1');
      }
    });

    this.pageService.miscSubjectParam.subscribe((value: any) => {
      if (value?.sosAlertActive === 'turnOff') {
        this.stopAlert();
      }
      if (value?.updateProfileDetail) {
        this.updateLoggedInUserDetail();
      }

      if (value?.syncData) {
        this.syncQueuedData();
      }
      if ('isOnEvent' in value) {
        this.ngZone.run(() => {
          this.isOnEvent = value?.isOnEvent;
          this.checkOnlineOffline(this.isAppOnline);
          this.checkWelfareChecks();
        });
      }
    });

    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('permissionStatus' in value) {
        this.modifyAppStatus(value?.permissionStatus);
      }
      if ('appStatus' in value) {
        this.internetConnectionAvailable = navigator.onLine;
        this.checkOnlineOffline(value?.appStatus);
      }
      if ('geoFenceAlert' in value) {
        this.geoFenceOutsideAlert(value?.geoFenceAlert);
      }
    });
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'queuedActionCheck',
    });

    this.serviceWorkerOperations();

    if (this.isSuperUser == true) {
      this.userMenu = superUserMenu;
    }

    if (window.innerWidth >= 1200) {
      this.expandIcon = true;
    }

    this.updateLoggedInUserDetail();
    if (!this.offlineMode) {
      this.userAppChecks();
      if (localStorage.getItem('loginSuccess') === 'true') {
        setTimeout(() => {
          this.afterLoginActions();
        }, 1000);
      }
    }
    this.removeStaleIndexedDbItems();

    this.appService.initializeBootstrapTooltips();
  }
  showWelfareButton(response: any) {
    this.welfareTempHoldValue = response?.pending_welfare_check;
    this.checkWelfareChecks();
  }
  updateLoggedInUserDetail() {
    let userData = this.appService.getUserData();

    const firstName = userData?.profile?.first_name;
    const lastName = userData?.profile?.last_name;
    const firstLetterFirstName = firstName ? firstName.charAt(0) : '';
    const firstLetterLastName = lastName ? lastName.charAt(0) : '';

    const initialName = `${firstLetterFirstName}${firstLetterLastName}`;
    this.loggedInUser = {
      profile: userData?.profile?.profile_image_url,
      name: `${userData?.profile?.first_name} ${userData?.profile?.last_name}`,
      initialName: initialName,
    };
  }
  geoFenceOutsideAlert(geoFenceAlert: any) {
    let geoFenceLastAlertTime: any = sessionStorage.getItem(
      'geoFenceLastAlertTime'
    );
    // adding a time check also, since this will be triggered simultaneously from the apis calls by the user.
    let booleanCheckValue = Boolean(geoFenceAlert?.length);
    if (geoFenceLastAlertTime) {
      geoFenceLastAlertTime = Number(geoFenceLastAlertTime);

      booleanCheckValue = Boolean(
        geoFenceAlert?.length &&
          new Date().getTime() > geoFenceLastAlertTime + 5 * 60 * 1000
      );
    }
    if (booleanCheckValue) {
      const alertDataCount = geoFenceAlert?.length;
      geoFenceAlert?.forEach((alertData: any, index: number) => {
        if (alertDataCount - 1 === index) {
          sessionStorage.setItem(
            'geoFenceLastAlertTime',
            String(new Date().getTime())
          );
        }

        const body: any = {
          geo_fence_alert: 1,
          alert_data: JSON.stringify({
            alert_distance: alertData?.haversineDistance,
            geo_fence_radius: alertData?.geoFenceDistance,
          }),
          job_id: alertData?.id,
          trigger_gps: JSON.stringify(alertData?.gps),
        };

        this.checkpointService.updateClient(body).then((response: any) => {});
      });
    }
  }

  getNotificationCount() {
    this.profileService.getNotificationCount().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.notificationCount = response?.count || 0;
        this.unReadMessageCount = response?.new_messages;
        this.newSupportUpdate = response?.feedback_updates;
        if (this.currentRoute === '/support') {
          this.newSupportUpdate = false;
        }

        this.checkAndStartGuide(response?.subscriber_user_guide_details);

        this.getSOSAlerts(response?.sos_alerts || 0);
        this.showWelfareButton(response);
        this.updateClientList(response?.client_checksum);
        this.updateQRCheckPointList(response?.qr_checkpoint_checksum);
        this.updateSiteList(response?.site_checksum);
        this.appService.appVersionCheck({
          last_item: response?.last_item,
        });

        localStorage.setItem(
          'groupUserCount',
          JSON.stringify(response?.user_and_group_count)
        );
        this.conditionalUpdateSideBarMenuItems();

        if (response?.welfare_check_intervals)
          this.updateWelfareAlertTime(response?.welfare_check_intervals);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  logOut() {
    if (this.isSuperUser === true) {
      this.appService.logOut();
    } else {
      this.spinnerService.show();
      let userData = this.appService.getUserData();

      this.profileService
        .logOut({ theme: userData?.preferences?.theme })
        .then((response: any) => {
          if (response?.status === 'success') {
            this.appService.logOut();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  cancelSubscription() {
    localStorage.clear();
    sessionStorage.clear();
  }
  toggleSidebar(event: any): boolean {
    event.preventDefault();

    this.expandIcon = !this.expandIcon;
    document.body.classList.toggle('sidebar-open');
    return false;
  }
  navigateHome(reload = false) {
    if (reload) {
      window.location.reload();
    } else {
      this.router.navigate(['/']);
      document.body.classList.remove('sidebar-open');
    }
  }
  scrollToTop() {
    this.windowScrolled = false;
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }

  async openNotifcationTemplate(notificationPopover: TemplateRef<any>) {
    if (!(await this.permissionsService.checkNotificationPermission())) {
      this.modifyAppStatus('notificationDisabled');
    }
    this.notificationPermission =
      await this.permissionsService.checkNotificationPermission();
    this.rows = 10;
    this.previous = 0;
    this.pageNum = 1;
    this.getNotifications();
    this.dialogRef = this.dialogService.open(
      notificationPopover,
      {},
      this.viewContainerRef
    );
  }

  getNotifications() {
    this.spinnerService.show();
    let params: any = {};
    if (this.isSuperUser != true) {
      if (this.rows) {
        params['rows'] = this.rows;
      }
      if (this.previous) {
        params['previous'] = this.previous;
      }
      this.profileService
        .getNotifications(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.notificationData = response['data'];
            this.totalRows = response?.total_count;
            this.totalPages = Math.ceil(this.totalRows / this.rows);

            this.notificationCount = this.totalRows;
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.spinnerService.hide();
        });
    }
  }
  onChangeNotificationPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getNotifications();
  }
  redirectNotification(notification: any) {
    sessionStorage.removeItem('selectedTab');
    if (
      this.isAdmin ||
      ((this.isGuardUser || this.isDispatchUser) && this.isClockedIn)
    ) {
      if (notification?.event_id) {
        if (['job'].includes(notification?.event_type)) {
          this.router.navigate([`/job-detail/JOBID`], {
            queryParams: { jId: notification?.event_id },
          });
        } else if (notification?.event_type === 'feedback') {
          this.router.navigate(['/support'], {
            queryParams: { sId: notification?.event_id },
          });
        } else if (notification?.event_type === 'user_license') {
          this.router.navigate(['/profile']);
        } else if (notification?.event_type === 'company_license') {
          this.router.navigate(['/company-profile']);
        } else if (notification?.event_type === 'patrol_job') {
          this.router.navigate([
            '/view-route',
            {
              rKey: String(notification?.event_id),
            },
          ]);
        }
      } else {
        if (notification?.event_type === 'version_release') {
          this.openChangeLog();
        } else if (notification?.event_type === 'feedback') {
          this.router.navigate(['/support']);
        } else if (notification?.event_type === 'hol_alert') {
          this.router.navigate(['/hand-over-log']);
        } else if (notification?.event_type === 'geo_fence_alert') {
          this.router.navigate(['/guards'], { fragment: 'geoFenceAlerts' });
        } else if (
          notification?.event_type === 'welfare_check' &&
          (this.isAdmin || this.isDispatchUser)
        ) {
          this.router.navigate(['/missed-events']);
        }
      }
      this.deleteNotification(notification);
    } else {
      this.router.navigate(['/']);
    }
  }

  clearNotifications() {
    this.spinnerService.show();
    this.profileService
      .deleteNotifications({ clear_all: true }, {})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.notificationData = [];
          // this.popover?.hide();
          this.getNotificationCount();
          // this.getNotifications();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  deleteNotification(notification: any) {
    this.profileService
      .deleteNotifications({}, { id_list: [notification.id] })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.notificationData = this.notificationData?.filter(
            (item: any) => !(item.id === notification.id)
          );
          if (!this.notificationData?.length) {
            this.dialogRef?.close();
          }
          this.notificationCount = this.notificationData?.length;
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  addfeedback() {
    this.spinnerService.show();
    const feedbackValue: any = this.feedbackForm.get('feedback')?.value;
    let fileData: FormData = new FormData();

    fileData.append('feedback', feedbackValue);
    fileData.append(
      'app_version',
      String(sessionStorage.getItem('appVersion') || '')
    );
    this.appService
      .formDataApi(`feedback/submit_feedback`, fileData)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.feedbackForm.reset();
          this.dialogRef.close();
          this.spinnerService.hide();
          this;
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }

  showHideDraggableFeedbackBtn(value: any) {
    if (value === true) {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'hidden';
    } else {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'visible';
    }
  }

  enableScrollOnBody() {
    document.body.classList.remove('sidebar-open');
  }

  capture(feedBackType: any, requestRef: TemplateRef<any>) {
    this.spinnerService.show();

    this.feedbackForm.controls['feedback'].setValue(null);

    if (feedBackType == 'draggableBtn') {
      this.showHideDraggableFeedbackBtn(true);
      if (this.dragging) {
        this.dragging = false;
      }
    }

    setTimeout(() => {
      this.spinnerService.hide();
      this.dialogRef = this.dialogService.open(
        requestRef,
        {
          data: {},
        },
        this.viewContainerRef
      );
    }, 100);
    this.appService
      .getCurrentVersionInfo()
      .then((data: any) => sessionStorage.setItem('appVersion', data?.version));

    this.dialogRef?.afterClosed().subscribe(() => this.feedbackForm.reset());
  }

  clockedInOut() {
    let params = {};
    if (this.isClockedIn === true) {
      this.checkAndRouteToHandOverLogDraft();
    } else {
      this.spinnerService.show();
      this.profileService.guardClockedInOut(params).then((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          let userData = this.appService.getUserData();
          userData.user_token = response?.user_token;
          this.appService.setUserData(userData);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
        setTimeout(() => {
          window.location.reload();
        });
      });
    }
  }
  welfareCheckIn() {
    this.spinnerService.show();
    this.jobService.welfareCheckIn().then((response: any) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: response?.message,
          errorMessage: '',
        });
        this.showWelfareCheckButton = false;
        this.welfareTempHoldValue = false;
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
        this.spinnerService.hide();
      }
    });
  }

  async userAppChecks() {
    //check whther user has set password, first name last name etc
    if (!this.isSuperUser) {
      if (
        this.dataCheckService.hasPasswordSet() === false ||
        window.localStorage.getItem('resetPasswordPWA')
      ) {
        const dialogRefUser = this.dialogService.open(AppChecksComponent, {
          data: {},
        });
        dialogRefUser.afterClosed().subscribe(async (value: any) => {
          this.spinnerService.hide();
          window.location.reload();
          await this.getSideBar();
        });
      }
    }
  }
  toggleSideBarMenu() {
    this.showUserDropdown = false;
    this.navbarToggled = !this.navbarToggled;
    // Add or remove the no-scroll class based on menu state
    if (this.navbarToggled) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
  toggleManageMenu() {
    this.showManageMenu = !this.showManageMenu;
  }
  toggleUserProfileMenu(delay = false) {
    this.arrangeUserMenu();
    if (delay) {
      // addded this so that focus out will be triggered after the click of menu items
      setTimeout(() => {
        this.showUserDropdown = false;
      }, 200);
    } else this.showUserDropdown = !this.showUserDropdown;
  }

  redirectToPage(menuLink: string, userMenu: boolean = false) {
    this.guideChimp?.stop();
    if (menuLink === 'signOut') {
      this.checkAndRouteToHandOverLogDraft(true);
    } else if (menuLink === 'updateApp') {
      this.openChangeLog();
    } else if (menuLink === 'changeAccount') {
      const fileImportDialogRef = this.dialogService.open(
        MultipleSubscribersComponent,
        {}
      );
      fileImportDialogRef.afterClosed().subscribe((value) => {
        if (value !== 'close') {
          window.location.reload();
        }
      });
    } else {
      this.router.navigate([menuLink]);
    }
    if (window.innerWidth < 1200 && userMenu === false) {
      this.toggleSideBarMenu();
    }
    this.showManageMenu = false;
    this.showUserDropdown = false;
    this.navbarToggled = false;

    sessionStorage.removeItem('selectedTab');
    sessionStorage.removeItem('fromSite');
  }

  handleWrapperClick(event: Event) {
    // Prevent the wrapper click event from interfering with child elements
    event.stopPropagation();
    if (!this.largeView) {
      if (this.navbarToggled === true) {
        this.navbarToggled = false;
      }
    }
    if (this.navbarToggled) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  checkPrevouslySubscribed() {
    return (
      !this.isAdmin ||
      this.dataCheckService.checkIsPreviouslySubscribedAndSubscriptionInActive()
    );
  }
  afterLoginActions() {
    this.callNearestApis();
    this.requestSubscription();
    localStorage.removeItem('loginSuccess');
  }
  // call the job/cp/cleint apis to build the indexed db cache

  callNearestApis() {
    try {
      this.userPromptService
        .getHomeUserPromptList({})
        .subscribe((response: any) => {});
      this.jobService.getNearestCheckpointList().then((response: any) => {});
      this.jobService.getNearesSiteList().then((response: any) => {});
      this.jobService
        .getNearestJobs({
          time_offset: new Date().getTimezoneOffset(),
        })
        .subscribe((response: any) => {});
      this.jobService.getAllSiteList().subscribe((response: any) => {});
    } catch (error) {}
  }
  async checkExisitingSubscription() {
    return new Promise((resolve) => {
      this.swPush.subscription.subscribe((res: any) => {
        resolve(res);
      });
    });
  }
  async unsubscribeFromPush() {
    try {
      await this.swPush.unsubscribe();
      // Successfully unsubscribed
    } catch (err) {}
  }
  requestSubscription = async () => {
    if (!this.swPush.isEnabled) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Warning: You have not enabled push notifications',
      });
      return;
    }
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.vapIDPKey,
      })
      .then((_) => {
        this.profileService
          .registerPushNotification({ subscription: JSON.stringify(_) })
          .then((response: any) => {
            if (response['status'] == 'success') {
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      })
      .catch(async (_: any) => {
        let existingSub = await this.checkExisitingSubscription();
        if (existingSub) {
          await this.unsubscribeFromPush();
          await this.requestSubscription();
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: _,
          });
        }
      });
  };

  async removeStaleIndexedDbItems() {
    try {
      const filterDate = new Date();
      filterDate.setDate(filterDate.getDate() - 3);

      if (this.swPush.isEnabled) {
        const result = await gTDB.gtSavedData.toArray();
        const staleRecords = result
          ?.filter(
            (item: any) =>
              new Date(item?.sync_time).getTime() < filterDate.getTime()
          )
          ?.map((item: any) => item?.indexDBId);

        if (staleRecords?.length) {
          this.deleteCachedData(staleRecords);
        }
      }
    } catch (error) {}
  }

  deleteCachedData(keys: any) {
    gTDB.gtSavedData.bulkDelete(keys);
  }
  updateQRCheckPointList(qrCpCheckSum: any) {
    if (qrCpCheckSum?.qr_checkpoint_count === 0) {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({
              shortPath: '/api/check_point/list_qr_checkpoints/',
            })
            .delete();
        }
      } catch (error) {}
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/check_point/list_qr_checkpoints/' })
            .toArray()
            .then((response: any) => {
              const checkpointData: any = response?.[0]?.data || [];
              const cachedQrCheckpointCount: number = checkpointData?.length;
              const lastUpdatedDate: any = checkpointData?.[0]?.last_updated_at
                ? new Date(checkpointData?.[0]?.last_updated_at)
                : null;
              if (
                qrCpCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(qrCpCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllQRCheckpointList()
                  .subscribe((response: any) => {});
              }

              if (
                cachedQrCheckpointCount !== qrCpCheckSum?.qr_checkpoint_count
              ) {
                this.jobService
                  .getAllQRCheckpointList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {}
    }
  }
  updateClientList(clientCheckSum: any) {
    if (clientCheckSum?.company_count === 0) {
      if (this.swPush.isEnabled) {
        gTDB.cachedData
          .where({
            shortPath: '/api/company/list_all_companies/',
          })
          .delete();
      }
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/company/list_all_companies/' })
            .toArray()
            .then((response: any) => {
              const clientData: any = response?.[0]?.data || [];
              const cachedClientCount: number = clientData?.length;
              const lastUpdatedDate: any = clientData?.[0]?.last_updated_at
                ? new Date(clientData?.[0]?.last_updated_at)
                : null;
              if (
                clientCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(clientCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllClientList()
                  .subscribe((response: any) => {});
              }

              if (cachedClientCount !== clientCheckSum?.company_count) {
                this.jobService
                  .getAllClientList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {}
    }
  }
  updateSiteList(siteCheckSum: any) {
    if (siteCheckSum?.company_count === 0) {
      if (this.swPush.isEnabled) {
        gTDB.cachedData
          .where({
            shortPath: '/api/site/list_all_sites/',
          })
          .delete();
      }
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/site/list_all_sites/' })
            .toArray()
            .then((response: any) => {
              const siteData: any = response?.[0]?.data || [];
              const cachedSiteCount: number = siteData?.length;
              const lastUpdatedDate: any = siteData?.[0]?.last_updated_at
                ? new Date(siteData?.[0]?.last_updated_at)
                : null;
              if (
                siteCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(siteCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllSiteList()
                  .subscribe((response: any) => {});
              }

              if (cachedSiteCount !== siteCheckSum?.company_count) {
                this.jobService
                  .getAllSiteList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {}
    }
  }
  async updateWelfareAlertTime(welfareCheckIntervals: any) {
    if (this.swPush.isEnabled) {
      welfareCheckIntervals = welfareCheckIntervals?.filter(
        (item: any) => new Date(item[0]).getTime() > new Date().getTime()
      );
      await gTDB.userData.delete('welfareCheckIntervals');
      await gTDB.userData.add(welfareCheckIntervals, 'welfareCheckIntervals');
      setTimeout(() => {
        navigator?.serviceWorker?.controller?.postMessage({
          type: 'welfareIntervalCheck',
        });
      }, 500);
    }
  }
  lockScreen() {
    this.orientationLockService.lockOrientation();
  }
  showSOSAlerts() {
    this.router.navigate([
      '/sos-alerts',
      { alertCounts: String(this.sosAlertCount) },
    ]);
  }
  getSOSAlerts(alertCount: number = 0) {
    if (alertCount == 0) {
      this.isAlertActive = false;

      this.sosAlertCount = 0;
      this.beepIntervalSubscription?.unsubscribe();
      this.pageService.miscSubjectParam.next({ sosAlertActive: 'alertMuted' });
    } else if (
      alertCount != this.sosAlertCount &&
      (this.isAdmin || this.isDispatchUser)
    ) {
      this.pageService.miscSubjectParam.next({ sosAlertActive: 'alertActive' });
      this.isAlertActive = true;
      this.alertUserWithSoundFeedback();
      this.sosAlertCount = alertCount;
      this.router.navigate([
        '/sos-alerts',
        { alertCounts: String(alertCount) },
      ]);
    }
  }

  stopAlert() {
    this.pageService.miscSubjectParam.next({ sosAlertActive: 'alertMuted' });
    this.isAlertActive = false;
    this.beepIntervalSubscription?.unsubscribe();
  }
  alertUserWithSoundFeedback() {
    this.beepIntervalSubscription = interval(1000)
      .pipe(takeWhile(() => this.isAlertActive))
      .subscribe((x) => {
        try {
          window.navigator.vibrate(200);
        } catch (error) {}
        this.toasterService.playChord(0.2, 1000, 100);
      });
  }
  modifyAppStatus(value: string) {
    switch (value) {
      case 'locationDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `Warning: Certain functions may not work without access to your location`,
          type: 'locationDisabled',
        };
        break;
      case 'locationEnabled':
        if (this.permissionErrorStatus?.type === 'locationDisabled') {
          this.permissionErrorStatus = null;
        }

        break;
      case 'cameraDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `Warning: Camera access not given`,
        };
        break;
      case 'notificationDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `Warning: Permission for push notifications not given`,
        };
        break;
    }

    this.cdr.detectChanges();
    const tooltipElement = document.getElementById('customTooltipBtn');

    if (tooltipElement) {
      // Dispose of the existing tooltip instance if it exists
      const existingTooltip = bootstrap.Tooltip.getInstance(tooltipElement);
      if (existingTooltip) {
        existingTooltip.dispose();
      }

      const tooltipInstance = new bootstrap.Tooltip(tooltipElement);
      tooltipInstance.setContent({
        '.tooltip-inner': this.permissionErrorStatus?.message,
      });
      tooltipInstance?.show();
      setTimeout(() => {
        tooltipInstance?.hide();
      }, 1000);
    }
  }

  switchTheme() {
    this.spinnerService.show();
    this.currentTheme = this.appService.getGTAppTheme();
    this.currentTheme = this.currentTheme === 'default' ? 'dark' : 'default';

    this.appService.switchGTAppTheme(this.currentTheme);

    this.spinnerService.hide();
  }

  installPWAApp() {
    this.spinnerService.show();
    const isIOS = this.deviceInfoService.isPhoneIOS();
    if (isIOS) {
      this.dialogRef = this.dialogService.open(PwaInstallationGuideComponent, {
        data: {},
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {});

      this.spinnerService.hide();
    } else {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.spinnerService.hide();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            this.isAppInstalled = true;
          }

          // Clear the deferredPrompt variable
          this.deferredPrompt = null;

          // Hide the install button after installation
        });
      } else {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: 'ERROR: Guard Tracker may already be installed',
        });
      }
    }
  }
  checkAndRouteToHandOverLogDraft(signOut = false) {
    const handOverLogDraftCount = Number(
      sessionStorage.getItem('handOverLogDraftCount') || 0
    );
    if (!handOverLogDraftCount) {
      if (signOut) {
        this.checkAndLogOut();
      } else {
        this.clockOutUser();
      }
      return;
    }
    if (handOverLogDraftCount > 0) {
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Pending Handover Log',
          message: 'Review handover log.',
        },
      });
      dialogRef.afterClosed().subscribe((value) => {
        this.navbarToggled = false;
        if (value === true) {
          this.router.navigate(['/add-hand-over-log'], {});
        } else {
          if (signOut) {
            this.checkAndLogOut();
          } else {
            this.clockOutUser();
          }
        }
      });
    }
  }
  checkAndLogOut() {
    if (this.isGuardUser && this.isClockedIn) {
      this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Sign Out',
          message: 'This will also clock you out.',
        },
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {
        if (value === true) {
          let params = {
            clock_out: 1,
          };
          this.spinnerService.show(
            'Syncing Data & Signing Out. Thank you for your patience.'
          );
          this.profileService
            .guardClockedInOut(params)
            .then((response: any) => {
              if (response?.status == 'success') {
                this.spinnerService.hide();

                setTimeout(() => {
                  this.logOut();
                }, 200);
              } else {
                this.toasterService.setMessage({
                  successMessage: '',
                  errorMessage: response?.message,
                });
                this.spinnerService.hide();
              }
            });
        }
      });
    } else {
      this.logOut();
    }
  }
  clockOutUser() {
    this.spinnerService.hide();
    let params = {
      clock_out: 1,
    };
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Clock Out',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.profileService.guardClockedInOut(params).then((response: any) => {
          if (response?.status == 'success') {
            this.spinnerService.hide();
            let userData = this.appService.getUserData();
            userData.user_token = response?.user_token;
            this.appService.setUserData(userData);
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
            this.spinnerService.hide();
          }
          setTimeout(() => {
            window.location.reload();
          });
        });
      }
    });
  }
  continueGuideWithInterval() {
    this.guideChimpInterval = setInterval(() => {
      const guideItemIndex = this.guideItems?.findIndex(
        (item: any) => item.element === this.currentTourElement
      );

      if (guideItemIndex + 1 === this.guideItems?.length) {
        // last step of the tour; onComplete is not triggering, so using a hack
        if (this.guideChimpInterval) clearInterval(this.guideChimpInterval);
        this.guideChimp?.stop();
      } else {
        this.guideChimp?.next();
      }
    }, 2500);
  }

  startGuide(guide: string) {
    let guideItems: any = [];
    switch (guide) {
      case 'clockIn':
        guideItems = [
          {
            element: '#mainScreenClockIn',
            title: 'Clock In',
            description: 'Begin by pressing on the clock in button.',
            showItem: !this.isAdmin,
            position: 'top',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
        ];
        break;
      case 'basics':
        guideItems = [
          {
            element: '#centerScreen',
            title: 'Welcome to Guard Tracker',
            showItem: true,
            position: 'center',
            description:
              'Let us take you on a brief tour to show you what you can do with Guard Tracker.',
            buttons: [
              {
                title: 'Get Started',
                class: 'btn btn-primary m-auto mt-3',
                onClick: () => {
                  this.guideChimp?.next();
                },
              },
            ],
          },
          {
            element: '#gtLogo',
            title: 'Guard Tracker',
            showItem: true,
            position: 'bottom',
            description:
              'Press on the Guard Tracker logo to access the home screen.',
          },
          {
            element: '#eventLogButton',
            title: 'Event Log',
            showItem: !this.largeView && !this.isAdmin,
            position: 'bottom',
            description: 'Updates you have uploaded can be found here.',
          },
          {
            element: '#incidentsButton',
            title: 'Incidents',
            showItem: !this.largeView && !this.isAdmin,
            position: 'bottom',
            description: 'Open an incident from here.',
          },
          {
            element: '#scanQRButton',
            title: 'Scan QR Code',
            showItem: !this.largeView && !this.isAdmin,
            position: 'bottom',
            description: 'Use this to scan QR checkpoints.',
          },

          {
            element: '#sidebar-menu',
            title: 'Menu Toggle',
            description: 'Press here to toggle the menu.',
            showItem: !this.largeView,
            position: 'top',
          },
          {
            element: '.list-group-flush',
            title: 'Navigation',
            description: 'Access key areas of the app.',
            showItem: !this.largeView,
            position: 'top',
          },

          {
            element: '#clock-in-btn',
            title: 'Clock In Button',
            description:
              'Guards must Clock In before they can update jobs and scan checkpoints.',
            showItem: this.isAdmin,
            position: 'top',
          },
          {
            element: '#theme-icon',
            title: 'Toggle Theme',
            description:
              'Pressing this will switch between dark and light themes.',
            showItem: this.isAdmin,
            position: 'top',
          },
          {
            element: '#settings-icon',
            title: 'Settings',
            description: 'Access app settings.',
            showItem: this.isAdmin,
            position: 'top',
          },
          {
            element: '#user-menu',
            title: 'User Profile',
            description: 'Update your user profile.',
            showItem: true,
            position: 'top',
          },
          {
            element: '#globalFeedbackbtn',
            title: 'Support',
            showItem: !this.isAdmin,
            position: 'bottom',
            description: 'Get in touch with us for more help.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                  this.toggleSideBarMenu();
                },
              },
            ],
          },
          {
            element: '#globalFeedbackbtn',
            title: 'Support',
            showItem: this.isAdmin,
            position: 'bottom',
            description: 'Get in touch with us for more help.',
          },
          {
            element: '#centerScreen',
            title: 'Adding Guards',
            showItem: this.isAdmin,
            position: 'center',
            description:
              'To get the most out of Guard Tracker, add at least 1 guard to your account. Start by opening Settings.',
            buttons: [
              {
                title: 'Open Settings',
                class: 'btn btn-primary m-auto mt-3',
                onClick: () => {
                  this.guideChimp?.next();
                },
              },
            ],
          },
          {
            element: '#manageGuards',
            title: '',
            description: 'Then access Users.',
            showItem: this.isAdmin,
            position: 'top',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                  this.router.navigate(['/users']);
                  this.showManageMenu = false;
                  this.toggleSideBarMenu();
                },
              },
            ],
          },

          // {
          //   title: 'Company Profile',
          //   description: 'Manage the company details here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageCompanyProfile',
          // },
          // {
          //   title: 'Guards',
          //   description: 'Manage the guards here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageGuards',
          // },
          // {
          //   title: 'Incident Types',
          //   description: 'Manage the incident types here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageIncidentTypes',
          // },
          // {
          //   title: 'Monitoring Companies',
          //   description: 'Manage the monitoring companies here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageMCompanies',
          // },
          // {
          //   title: 'Response Types',
          //   description: 'Manage the response types here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageResponseTypes',
          // },
          // {
          //   title: 'Service Types',
          //   description: 'Manage the service Types here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageServiceTypes',
          // },
          // {
          //   title: 'USER PROMPTS',
          //   description: 'Manage the user prompts here.',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   element: '#manageUserPrompts',
          // },

          // {
          //   title: 'Assigned Jobs',
          //   description: 'You will be able to see all your assigned jobs here.',
          //   showItem: !this.largeView,
          //   position: 'top',
          //   element: '#updateEventList',
          // },
          // {
          //   title: 'Scan Checkpoints',
          //   description:
          //     'Upload a photo of the respective checkpoint near you.Will be part of the reports',
          //   showItem: !this.largeView,
          //   position: 'top',
          //   element: '#scanCheckpoint',
          // },
          // {
          //   title: 'Scan QR',
          //   description:
          //     'Scan the QR code to mark it as scanned. Will be part of the reports',
          //   showItem: !this.largeView,
          //   position: 'top',
          //   element: '#scanQR',
          // },
          // {
          //   title: 'Patrol Updates',
          //   description:
          //     'Add comments or photos to the site. Will be part of the reports',
          //   showItem: !this.largeView,
          //   position: 'top',
          //   element: '#patrolUpdateHomeBtns',
          // },
        ];

        break;

      case 'users':
        guideItems = [
          {
            element: '#addUserButton',
            title: 'Adding Guards and Users',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Press to add guards and other users.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
        ];
        break;

      case 'jobList':
        guideItems = [
          // {
          //   element: '#filterJob',
          //   title: 'Search/Filter Response Jobs',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'Use this to search or for filtering out response jobs',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.continueGuideWithInterval();
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          {
            element: '#addResponseJob',
            title: 'Alarm Response',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Add alarm response jobs.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          // {
          //   element: '#showResetJob',
          //   title: 'Show All Jobs',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Select this to show all the jobs',
          // },
          // {
          //   element: '#sortJobs',
          //   title: 'Sort Job List',
          //   showItem: this.isAdmin && !this.largeView,
          //   position: 'top',
          //   description: 'You can sort jobs',
          // },
          // {
          //   element: '#listJob',
          //   title: 'Jobs',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'You can manage the jobs here',
          // },
        ];

        break;
      case 'roster':
        guideItems = [
          // {
          //   element: '#rosterChange',
          //   title: 'Date/ Week',
          //   showItem: true,
          //   position: 'top',
          //   description: 'Change the dates/weeks of the calender',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.continueGuideWithInterval();
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          // {
          //   element: '#rosterWeek',
          //   title: 'Calender',
          //   showItem: !this.largeView,
          //   position: 'top',
          //   description:
          //     'You can select the day to see the roster of that particular day',
          // },
          {
            element: '#addRoster',
            title: 'Roster Shifts',
            showItem: this.isAdmin && this.largeView,
            position: 'top',
            description:
              'Click on a date to add and assign a job to one of your guards.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          {
            element: '#addRoster',
            title: 'Roster Shifts',
            showItem: this.isAdmin && !this.largeView,
            position: 'top',
            description: 'Add and assign jobs to your guards.',
          },
          // {
          //   element: '#searchRosterJobs',
          //   title: 'Search',
          //   showItem: this.isAdmin && !this.largeView,
          //   position: 'top',
          //   description: 'You can search for rostered jobs',
          // },
          // {
          //   element: '#rosterJobList',
          //   title: 'Jobs',
          //   showItem: true,
          //   position: 'top',
          //   description:
          //     'You will be able to see the shifts assigned and manage them  here, once created',
          // },
        ];
        break;
      case 'siteList':
        guideItems = [
          // {
          //   element: '#siteTab',
          //   title: 'Site Tab',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'You can maanage your sites here',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          {
            element: '#addSite',
            title: 'Add a Site',
            showItem: this.isAdmin,
            position: 'top',
            description: 'By pressing this button.',
          },
          // {
          //   element: '#filterSite',
          //   title: 'Search/Filter Sites',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Use this to search or for filtering out sites',
          // },
          // {
          //   element: '#siteList',
          //   title: 'Site List',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'You can select a site (once site is/are added) and see the details',
          // },
          {
            element: '#clientTab',
            title: 'Clients',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Or add your clients here.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          // {
          //   element: '#addClient',
          //   title: 'Add Client',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Select this to add new client',
          // },
          // {
          //   element: '#filterClient',
          //   title: 'Search/Filter Clients',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Use this to search or for filtering out clients',
          // },
          // {
          //   element: '#clientList',
          //   title: 'Client List',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'You can select a client (once client is/are added) and see the details',
          // },
        ];
        break;

      case 'checkpointList':
        guideItems = [
          // {
          //   element: '#checkpointTab',
          //   title: 'Checkpoints & QR',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Manage checkpoints, generate QR Codes',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.continueGuideWithInterval();
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          // {
          //   element: '#filterCheckpoint',
          //   title: 'Search/Filter Checkpoint',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Use this to search or for filtering out checkpoints',
          // },
          {
            element: '#addCheckpoint',
            title: 'Add Checkpoint',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Add a checkpoint using this.',
          },
          // {
          //   element: '#toggleCheckpointView',
          //   title: 'Toggle CheckpointView',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Switch to map view',
          // },

          // {
          //   element: '#checkpointList',
          //   title: 'Checkpoints ',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'You can manage checkpoints here',
          // },
          {
            element: '#qrCheckpointTab',
            title: 'QR Codes',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Or generate QR codes to use as checkpoints here.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          // {
          //   element: '#generateQRCodes',
          //   title: 'QR codes',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Generate New QR Codes',
          // },
          // {
          //   element: '#generateOldQRCodes',
          //   title: 'QR codes',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Generate QR Codes for already added QR codes',
          // },
          // {
          //   element: '#missedCheckpointTab',
          //   title: 'Missed Checkpoints',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'See all the scans missed under a patrol shift.',
          // },
        ];
        break;
      case 'reports':
        guideItems = [
          {
            element: '#reportTabs',
            title: 'Reports',
            showItem: this.isAdmin,
            position: 'top',
            description:
              'These tabs allow you to generate reports on demand for completed patrols, alarm responses, static guard shifts, and any service jobs.',
            // buttons: [
            //   {
            //     title: 'Start Tour',
            //     class: 'btn btn-primary rounded-0 m-auto',
            //     onClick: () => {
            //       this.continueGuideWithInterval();
            //       this.guideChimp?.next();
            //     },
            //   },
            // ],
          },
          {
            element: '#configuredReportsTab',
            title: 'Configured Reports',
            showItem: this.isAdmin,
            position: 'top',
            description: 'See all the reports you have setup here.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          // {
          //   element: '#patrolReportTab',
          //   title: 'Patrol Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Generate and mail client patrol reports to contacts',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.continueGuideWithInterval();
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          // {
          //   element: '#jobReportTab',
          //   title: 'Job Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'Generate and mail completed and cancelled job spreadsheet',
          // },
          // {
          //   element: '#discrepancyReportTab',
          //   title: 'Descrepancy Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Generate and mail the discrpencies in pdf',
          // },
          // {
          //   element: '#configuredReportTab',
          //   title: 'Reports Contacts',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'See the contacts configured for recieving job, patrol and incident reports',
          // },
        ];
        break;
      case 'DISABLEviewSite':
        guideItems = [
          // {
          //   element: '#siteDetail',
          //   title: 'Site',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Manage the site here',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.continueGuideWithInterval();
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          // {
          //   element: '#siteName',
          //   title: 'Site Name',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#editSiteName',
            title: 'Update Details',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Press on these icons to make changes.',
          },
          // {
          //   element: '#siteAddress',
          //   title: 'Site Address',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          // {
          //   element: '#editSiteAddress',
          //   title: 'Edit Site Address',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          // {
          //   element: '#siteClient',
          //   title: 'Client',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Client to which the site belongs to',
          // },
          {
            element: '#goToSiteClient',
            title: 'Client Information',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Press on this icon to go to the client details page.',
          },
          // {
          //   element: '#siteLocation',
          //   title: 'Location of the Site',
          //   showItem:this.isAdmin,position: 'top',
          //   description: '',
          // },
          // {
          //   element: '#editSiteManagerNotes',
          //   title: 'Update Manager Notes',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#siteInformation',
            title: 'Site Information',
            showItem: this.isAdmin,
            position: 'top',
            description:
              'Information about the site you want to share with your guards can be saved here.',
          },
          // {
          //   element: '#editSiteInformation',
          //   title: 'Update Site Information',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#siteFiles',
            title: 'Site Files',
            showItem: this.isAdmin,
            position: 'top',
            description:
              'You can also upload PDF and JPG files related to the site.',
          },
          // {
          //   element: '#editSiteFiles',
          //   title: 'Update Site Files',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#siteKeys',
            title: 'Site Keys',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Keys for the site can be saved here',
          },
          // {
          //   element: '#editSiteKeys',
          //   title: 'Update Site Keys',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#siteContacts',
            title: 'Site Contacts',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Contacts for the site can be saved here.',
          },
          // {
          //   element: '#editSiteContacts',
          //   title: 'Update Site Contacts',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#siteManagerNotes',
            title: 'Manager Notes',
            showItem: this.isAdmin,
            position: 'top',
            description:
              'For sensitive information about the site, these notes are only visible to users in the managers group.',
          },
          {
            element: '#sitePatrolReports',
            title: 'Patrol Reports',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Scheduled reports for patrols are listed here.',
          },
          // {
          //   element: '#editSitePatrolReports',
          //   title: 'Update Site Patrol Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#siteJobReports',
            title: 'Completed Job Reports',
            showItem: this.isAdmin && this.largeView,
            position: 'top',
            description:
              'Contacts listed here will recieve reports for all completed jobs including alarm responses.',
          },
          {
            element: '#siteJobReports',
            title: 'Completed Job Reports',
            showItem: this.isAdmin && !this.largeView,
            position: 'top',
            description:
              'Contacts listed here will recieve reports for all completed jobs including alarm responses.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          // {
          //   element: '#editSiteJobReports',
          //   title: 'Update Site Job Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },

          {
            element: '#siteHistory',
            title: 'Updates',
            showItem: this.isAdmin && this.largeView,
            position: 'top',
            description: 'Any changes made to the site are logged here.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },

          // {
          //   element: '#siteDeleteBtn',
          //   title: 'Delete Site',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'Deleting the site will also remove any checkpoints belonging to the site.',
          // },
        ];

        break;
      case 'viewClient':
        guideItems = [
          // {
          //   element: '#clientDetail',
          //   title: 'Client',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Manage the client here',
          //   buttons: [
          //     {
          //       title: 'Start Tour',
          //       class: 'btn btn-primary rounded-0 m-auto',
          //       onClick: () => {
          //         this.continueGuideWithInterval();
          //         this.guideChimp?.next();
          //       },
          //     },
          //   ],
          // },
          // {
          //   element: '#clientDeleteBtn',
          //   title: 'Delete Client',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description:
          //     'Checkpoints belonging to deleted clients will no longer be available.',
          // },
          // {
          //   element: '#clientName',
          //   title: 'Client Name',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#editClientName',
            title: 'Update Details',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Press on these icons to make changes.',
          },
          // {
          //   element: '#clientAddress',
          //   title: 'Client Address',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },

          {
            element: '#clientSiteList',
            title: 'Sites',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Sites belonging to the client are listed here.',
          },
          {
            element: '#addNewClientSite',
            title: 'Add Site',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Add more sites for this client.',
          },
          // {
          //   element: '#clientLocation',
          //   title: 'Location of the Client',
          //   showItem:this.isAdmin,position: 'top',
          //   description: '',
          // },

          // {
          //   element: '#clientInformation',
          //   title: 'Client Information',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: 'Information about the client',
          // },
          // {
          //   element: '#editClientInformation',
          //   title: 'Update Client Information',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          // {
          //   element: '#clientFiles',
          //   title: 'Client Files',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          // {
          //   element: '#editClientFiles',
          //   title: 'Update Client Files',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },

          {
            element: '#clientPatrolReports',
            title: 'Patrol Reports',
            showItem: this.isAdmin,
            position: 'top',
            description: 'Scheduled reports for patrols are listed here.',
          },
          // {
          //   element: '#editClientPatrolReports',
          //   title: 'Update Client Patrol Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#clientJobReports',
            title: 'Completed Job Reports',
            showItem: this.isAdmin && this.largeView,
            position: 'top',
            description:
              'Contacts listed here will recieve reports for all completed jobs including alarm responses.',
          },
          {
            element: '#clientJobReports',
            title: 'Completed Job Reports',
            showItem: this.isAdmin && !this.largeView,
            position: 'top',
            description:
              'Contacts listed here will recieve reports for all completed jobs including alarm responses.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
          // {
          //   element: '#editClientJobReports',
          //   title: 'Update Client Job Reports',
          //   showItem: this.isAdmin,
          //   position: 'top',
          //   description: '',
          // },
          {
            element: '#clientHistory',
            title: 'Updates',
            showItem: this.isAdmin && this.largeView,
            position: 'top',
            description: 'Any changes made to the client are logged here.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
        ];

        break;
      case 'companyProfile':
        guideItems = [
          {
            element: '#companyLogo',
            title: 'Company Logo',
            showItem: true,
            position: 'top',
            description:
              'If uploaded, your company logo will be shown in emails and reports.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.next();
                },
              },
            ],
          },

          {
            element: '#companyLicence',
            title: 'Master Licence',
            showItem: true,
            position: 'top',
            description: 'Add your company level licences here.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
        ];
        break;

      case 'jobDetail':
        guideItems = [
          {
            element: '#jobSiteInfo',
            title: 'Site Information',
            showItem: !this.isAdmin && !this.largeView,
            position: 'top',
            description:
              'Shows the name of the site and the client it belongs to.',
          },
          {
            element: '#jobSiteInfoLink',
            title: 'More Site Information',
            showItem: !this.isAdmin && !this.largeView,
            position: 'top',
            description:
              'Press this link icon to see more detailed information about the site.',
          },
          {
            element: '#jobAddressLink',
            title: 'Open Map',
            showItem: !this.isAdmin && !this.largeView,
            position: 'top',
            description: 'Press this to show the address on a map.',
          },
          {
            element: '#jobChangeStatus',
            title: 'Change Status',
            showItem: !this.isAdmin && !this.largeView,
            position: 'top',
            description: 'Update the status of this job.',
          },
          {
            element: '#jobUpdateButtons',
            title: 'Job Updates',
            showItem: !this.isAdmin && !this.largeView,
            position: 'top',
            description: 'Add photos and comments related to the job.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },
        ];
        break;
      case 'patrolJob':
        this.updateBottomSectionStyling();
        guideItems = [
          {
            element: '#patrolCheckpoints',
            title: 'Checkpoints',
            showItem: true,
            position: 'top',
            description:
              'These checkpoints need to be scanned inside the shift window.',
          },
          {
            element: '#patrolSiteUpdateButton',
            title: 'Patrol Updates',
            showItem: !this.isAdmin && !this.largeView,
            position: 'top',
            description:
              'Add comments and photos related to the patrol using these buttons below. Comments and photos must be linked to a site.',
            buttons: [
              {
                title: 'OK',
                class: 'btn btn-primary mt-3 w-25 ms-auto',
                onClick: () => {
                  this.guideChimp?.stop();
                },
              },
            ],
          },

          // {
          //   element: '#patrolStatus',
          //   title: 'Job Status',
          //   showItem: this.largeView,
          //   position: 'top',
          //   description: 'Inform you about the status of the shift',
          // },
          // {
          //   element: '#addComment',
          //   title: 'Comment',
          //   showItem: this.largeView,
          //   position: 'top',
          //   description:
          //     'Add comment to this patrol. Will be reported to client contacts. More options availabe in mobiles',
          // },
          // {
          //   element: '#patrolHistory',
          //   title: 'Patrol History',
          //   showItem: this.isAdmin && this.largeView,
          //   position: 'top',
          //   description: 'Log of all updated of this patrol job',
          // },
          // {
          //   element: '#patrolUpdate',
          //   title: 'Update',
          //   showItem: !this.largeView,
          //   position: 'top',
          //   description:
          //     'Patrol Job Status, Scan checkpoints, qrs, add comments and photos to this patrol. Will be reported to site contacts',
          // },
        ];

        break;
    }
    guideItems = guideItems?.filter((item: any) => item?.showItem);
    this.guideItems = [...guideItems];
    if (!guideItems?.length) return;

    //https://github.com/Labs64/GuideChimp/wiki/Configure#methods

    this.guideChimp = GuideChimp(guideItems, {
      exitOverlay: false,
      showPagination: false,
      interaction: false,
    });
    setTimeout(() => {
      this.guideChimp?.start();
    }, 500);

    this.guideChimp?.on('onBeforeChange', (to: any, from: any) => {
      if (from && from.condition && !from.condition()) {
        return false; // Prevent transition if condition is not met
      }

      return true;
    });
    this.guideChimp?.on('onStop', () => {
      if (this.guideChimpInterval) clearInterval(this.guideChimpInterval);

      const userGuide = JSON.parse(localStorage.getItem('userGuide') || '{}');
      userGuide[guide] = true;

      localStorage.setItem('userGuide', JSON.stringify(userGuide));
      if (['patrolJob', 'jobDetail'].includes(guide)) {
        this.updateBottomSectionStyling('add');
      }
      this.updateGuideLevel();
    });
    this.guideChimp?.on('onPrevious', (to: any, from: any) => {
      if (
        from.element === '#sidebar-menu' &&
        ['#scanQRButton', '#gtLogo'].includes(to.element)
      ) {
        this.toggleSideBarMenu();
      }

      if (from.element === '#manageGuards' && to.element == '#centerScreen')
        this.showManageMenu = false;
    });

    this.guideChimp?.on('onNext', (to: any, from: any) => {
      this.currentTourElement = to.element;
      if (from.element === '#centerScreen' && to.element == '#manageGuards')
        this.showManageMenu = true;
      if (
        ['#user-menu'].includes(from.element) &&
        to.element == '#updateEventList'
      ) {
        this.toggleSideBarMenu();
      }
      if (
        ['#scanQRButton', '#gtLogo'].includes(from.element) &&
        to.element == '#sidebar-menu'
      ) {
        this.toggleSideBarMenu();
      }
      if (from.element === '#siteList' && to.element == '#clientTab') {
        const clientTab: any = document.querySelector('#clientTab');
        // hack to switch the tabs

        if (clientTab) {
          clientTab?.click();
        }
      }
      if (
        from.element === '#checkpointList' &&
        to.element == '#qrCheckpointTab'
      ) {
        const qrCheckpointTab: any = document.querySelector('#qrCheckpointTab');

        if (qrCheckpointTab) {
          qrCheckpointTab?.click();
        }
      }
      if (
        from.element === '#generateOldQRCodes' &&
        to.element == '#missedCheckpointTab'
      ) {
        const missedCheckpointTab: any = document.querySelector(
          '#missedCheckpointTab'
        );
        // hack to switch the tabs

        if (missedCheckpointTab) {
          missedCheckpointTab?.click();
        }
      }
    });
  }
  getBasePath(url: string): string {
    if (url == '/') return url;
    const urlTree = this.router.parseUrl(url);
    const urlSegments = urlTree.root.children['primary'].segments;
    url = urlSegments?.length ? '/' + urlSegments[0]?.path : '/';
    return url;
  }

  checkAndStartGuide(userGuideStored: any) {
    if (!userGuideStored) {
      localStorage.removeItem('userGuide');
      return;
    }
    // case where the user needs to add password and details
    if (!this.dataCheckService.hasPasswordSet()) return;

    localStorage.setItem('userGuide', JSON.stringify(userGuideStored));

    let key: any = '';

    const baseUrl = this.getBasePath(this.router.url);

    const isNotClockedInGuard = !this.isAdmin && !this.isClockedIn;

    switch (baseUrl) {
      case '/':
        // home page
        key = isNotClockedInGuard ? 'clockIn' : 'basics';
        break;
      case '/dashboard':
        // home page
        key = isNotClockedInGuard ? 'clockIn' : 'basics';
        break;
      case '/client':
        key = 'siteList';
        break;
      case '/roster':
        key = 'roster';
        break;
      case '/jobs':
        key = 'jobList';
        break;
      case '/checkpoints':
        key = 'checkpointList';
        break;
      case '/reports':
        key = 'reports';
        break;
      case '/view-site':
        key = 'viewSite';
        break;
      case '/view-client':
        key = 'viewClient';
        break;
      case '/view-route':
        key = 'patrolJob';
        break;
      case '/job-detail':
        key = 'jobDetail';
        break;
      case '/users':
        key = 'users';
        break;
      case '/company-profile':
        key = 'companyProfile';
        break;
    }
    if (!key) return;

    if (!userGuideStored?.[key]) {
      if (!this.onBoarding?.[key]) {
        this.onBoarding[key] = true;
        this.startGuide(key);
      }
    }
  }
  restartTutorial() {
    this.onBoarding = {};
    localStorage.removeItem('userGuide');
    this.updateGuideLevel();
    setTimeout(() => {
      this.getNotificationCount();
    }, 500);
    this.router.navigate(['/']);
  }
  updateBottomSectionStyling(action = 'remove') {
    if (action == 'remove') {
      const bottomSection = document.querySelector('.bottom-section');

      if (bottomSection) {
        this.guideStyleOperationsNeeded.jobDetails = {
          bottomSection: bottomSection,
        };

        bottomSection.classList.remove('bottom-section');
      }
    } else {
      if (this.guideStyleOperationsNeeded?.jobDetails?.bottomSection)
        this.guideStyleOperationsNeeded.jobDetails.bottomSection.classList.add(
          'bottom-section'
        );
    }
  }
  updateGuideLevel() {
    // method to update the completed steps of the guide in the backend also,
    // so that even if the localStorage is cleared or the user logs in another device it wont be repeated.
    const userGuide = JSON.parse(localStorage.getItem('userGuide') || '{}');
    this.spinnerService.show();
    this.profileService
      .updateUser(
        {
          data: {
            user_guide_info: userGuide,
          },
        },
        { save_preference: 1 }
      )
      .then((response: any) => {
        if (response?.status === 'success') {
          let userData = this.appService.getUserData();
          if (userData?.preferences) {
            userData.preferences.user_guide_info = userGuide;
            this.appService.setUserData(userData);
          }

          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }
}
