import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { startRegistration } from '@simplewebauthn/browser';
import { globalLicenseInfo, isLargeScreen } from '../../../global.variable';
import { AppService } from '../../app.service';
import { DataCheckService } from '../../shared/services/data-check.service';
import { PagesService } from '../pages/pages.service';
import { ProfileService } from './profile.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { AddEditLicenseDetailsComponent } from '../../shared/components/add-edit-license-details/add-edit-license-details.component';
import { ChangePasswordComponent } from '../../shared/components/change-password/change-password.component';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../shared/components/modal-dialogue/model-dialogue.service';
import { WebcamImageComponent } from '../../shared/components/web-cam-image/web-cam-image.component';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { ClientsService } from '../pages/clients/clients.service';

@Component({
  selector: 'gtapp-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  userDetails: any;
  dialogref: any;
  userData: any;
  profileForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    first_name: new UntypedFormControl(''),
    last_name: new UntypedFormControl(''),
    mobile_number: new UntypedFormControl(''),
    time_zone: new UntypedFormControl(''),
  });
  emailUpdateForm: UntypedFormGroup = new UntypedFormGroup({
    new_email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
    v_code: new UntypedFormControl(''),
  });
  message: string = '';

  userProfileUrl: any;

  actualUploadFile: any;
  fileData: any = [];

  cardActionIcons = globalLicenseInfo?.icons;
  licenseDetails = {
    individualDetails: globalLicenseInfo?.details,
  };
  userLicenses: any = [];

  userGroups: any;
  currentTheme: any;

  isTrustedDevice: boolean = false;
  stateTimeZones: any;
  selectedTimeZone: any;
  muteNotification: number = 30;
  largeView: Boolean = isLargeScreen;

  emailUpdateStepper: number = 1;
  newEmailAddress: string = '';
  isDispatchUser = this.dataCheckService.isDispatchUser();
  isAdmin = this.dataCheckService.isUserAdmin();

  // ios triggeres mouseup and touchend when the user taps on the slider, which will execute 2 apis. so will check whether the event is slide or tap and block the other

  isOnSliding: boolean = false;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    private pageService: PagesService,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private dataCheckService: DataCheckService,
    private clientService: ClientsService,
    private toasterService: ToasterService,
    private deviceService: DeviceDetectorService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.userData = this.appService.getUserData();
    this.profileService.getFidoDevices().subscribe(async (response: any) => {
      let currentDevice: any = this.deviceService.getDeviceInfo();

      let isDeviceRegistered = response?.data?.web_authn[0].some(
        (registeredDevice: any) => {
          return (
            registeredDevice.device.device === currentDevice.device &&
            registeredDevice.device.device_type === currentDevice.deviceType &&
            registeredDevice.device.device_os === currentDevice.os &&
            registeredDevice.device.browser === currentDevice.browser
          );
        }
      );
      if (isDeviceRegistered === true && this.userData?.checks) {
        this.userData.checks.fido_credentials = true;
        this.userData.checks.fido_login = true;
        this.appService.setUserData(this.userData);
      }
    });

    this.muteNotification = this.toasterService.getNotificationVolume();

    this.isTrustedDevice = this.dataCheckService.isTrustedDevice();
    if (this.userData?.profile?.profile_image_url) {
      this.userProfileUrl = this.userData?.profile?.profile_image_url;
    }
    this.spinnerService.show();
    this.getProfileData();
    this.getTimeZoneDetails();
  }
  getTimeZoneDetails() {
    this.stateTimeZones = [];
    [
      'Australia/Darwin',
      'Australia/Sydney',
      'Australia/Brisbane',
      'Australia/Adelaide',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
    ].forEach((timeZone: any) => {
      this.stateTimeZones.push(this.getTimezoneInfo(timeZone));
    });
  }

  getProfileData() {
    this.spinnerService.show();
    this.profileService.fetchUserProfile('api').subscribe((res) => {
      if (res['status'] === 'success') {
        this.formatProfileData(res['data']);
        this.spinnerService.hide();
      }
    });
  }
  formatProfileData(data: any) {
    this.userDetails = data;
    this.selectedTimeZone = data?.time_zone;
    if (data?.time_zone) {
      this.userDetails.timeZone = this.getTimezoneInfo(data?.time_zone);
    }

    this.userGroups = [
      ...this.userDetails?.user_group?.map((item: any) => {
        return item?.name;
      }),
    ];

    this.profileForm.reset(this.userDetails);
    this.profileForm.controls['time_zone'].setValue(data?.time_zone);
    this.userLicenses = data?.license;

    this.profileForm.get('email')?.disable();
    if (this.userDetails?.profile_image) {
      this.userProfileUrl = this.userDetails?.profile_image;

      let userData = this.appService.getUserData();
      userData.profile.profile_image_url = this.userProfileUrl;

      this.appService.setUserData(userData);
    }
    this.pageService.miscSubjectParam.next({ updateProfileDetail: true });
  }

  editProfile(template: TemplateRef<any>) {
    const dialogref = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
    dialogref.afterClosed().subscribe((value) => {});
  }

  openPasswordForm() {
    const dialogRefCP = this.dialogService.open(ChangePasswordComponent, {
      data: '',
    });
    dialogRefCP.afterClosed().subscribe((value) => {});
  }
  addNewLicense() {
    const dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close' && value !== undefined) {
        this.userLicenses = value;
      }
    });
  }

  onSubmitClicked(passwordForm: any) {
    if (passwordForm) {
      this.profileService.changePassword(passwordForm).subscribe((response) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }

  UpdateProfileDetails() {
    if (this.profileForm.valid) {
      this.profileForm.get('email')?.enable();
      let requestData = { data: this.profileForm.value };
      requestData['data']['time_zone'] = this.selectedTimeZone;
      if (!this.profileForm.value?.mobile_number) {
        this.profileForm.controls['mobile_number'].setValue(null);
      }
      this.profileService
        .updateProfileDetails(requestData, 'api')
        .then((response: any) => {
          if (response['status'] == 'success') {
            let userData = this.appService.getUserData();
            userData.profile = response['data'];
            this.appService.setUserData(userData);
            // window.location.reload();
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });

            this.formatProfileData(response['data']);
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  deleteLicense(license: any) {
    let dialogMsg = 'Are you sure you want to delete this licence?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Licence',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .deleteLicense(license.id, 0)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.userLicenses = response['data'];
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  sendVerificationCode() {
    this.spinnerService.show();

    this.profileService
      .updateEmail(
        {
          ...this.emailUpdateForm.value,
          override:
            this.newEmailAddress !== this.emailUpdateForm.value?.new_email,
        },
        { send_otp: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.emailUpdateStepper = 2;
          this.newEmailAddress = this.emailUpdateForm.value?.new_email;

          this.emailUpdateForm.controls['v_code'].setValidators([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
          ]);
          this.emailUpdateForm.controls['v_code'].setValue('');
          this.emailUpdateForm.controls['v_code'].updateValueAndValidity();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  updateEmailAddress() {
    this.spinnerService.show();

    this.profileService
      .updateEmail(this.emailUpdateForm.value, { verify_otp: 1 })
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  checkEmailEnter(template: any) {
    this.emailUpdateStepper = 1;
    this.emailUpdateForm.controls['v_code'].removeValidators([
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]);
    this.emailUpdateForm.controls['v_code'].updateValueAndValidity();
    this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openCamPopup() {
    this.dialogref = this.dialogService.open(WebcamImageComponent, {
      data: { showFrom: 'userProfilePage' },
    });

    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.actualUploadFile = value.actualFileUpload;
        this.onFileSubmit();
      }
    });
  }

  onFileSubmit() {
    this.spinnerService.show();
    let fileData: FormData = new FormData();
    fileData.append('file', this.actualUploadFile);

    this.appService
      .formDataApi(`profile/update_profile_image`, fileData, false, {})
      .then((response: any) => {
        if (response['status'] == 'success') {
          let userData = this.appService.getUserData();
          userData.profile.profile_image_url = response['url'];
          this.appService.setUserData(userData);

          this.dialogref.close();
          window.location.reload();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
        this.actualUploadFile = null;
      });
  }
  removeImage() {
    let dialogMsg = `Are you sure to remove the profile picture?`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Remove Picture',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        let params = { remove_profile_image: 1 };
        this.spinnerService.show();
        this.profileService
          .removeProfileImage(params)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.profile.profile_image_url = null;
              this.appService.setUserData(userData);
              this.toasterService.setMessage({
                // successMessage: response['message'],
                errorMessage: '',
              });
              window.location.reload();
            } else {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  async fidoStartRegistration(responseBody: any) {
    try {
      let regResp = await startRegistration({
        optionsJSON: JSON.parse(responseBody),
      });
      if (regResp) {
        this.profileService
          .fidoRegister(regResp, { verify: 1 })
          .then((response: any) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.checks.fido_credentials = true;
              userData.checks.fido_login = true;
              this.appService.setUserData(userData);
              window.location.reload();
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    } catch (err: any) {
      if (err === 'The authenticator was previously registered') {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: 'The authenticator was previously registered',
        });
        let userData = this.appService.getUserData();
        userData.checks.fido_login = true;
        this.appService.setUserData(userData);
        window.location.reload();
      }
    }
  }

  fidoRegister() {
    this.profileService.fidoRegister().then((response: any) => {
      if (response['status'] == 'success') {
        this.fidoStartRegistration(response['data']);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  mobileNumberCheck() {
    if (!this.profileForm.value?.mobile_number) {
      this.profileForm.controls['mobile_number'].setValue(null);
      this.profileForm.controls['mobile_number'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.profileForm.controls['mobile_number'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }

  getTimezoneInfo(timeZone: string): {
    timezone: string;
    localTime: string;
    gmtOffset: string;
  } {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    };

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    let offsetString: any = formatter
      .formatToParts(now)
      .find((part) => part.type === 'timeZoneName')?.value;
    const utcIndex = offsetString.indexOf('GMT');
    offsetString = offsetString.slice(utcIndex + 3);
    // Check if minutes part is zero
    if (offsetString.length <= 3) {
      offsetString += ':00';
    }

    const info: any = {
      localTime: now.toLocaleTimeString('en-AU', options),
      gmtOffset: `(UTC ${offsetString})`,
      value: timeZone,
    };
    return info;
  }
  changeNotificationVolume() {
    this.spinnerService.show();
    this.profileService
      .updateUser(
        {
          data: {
            mute_notification: this.muteNotification,
          },
        },
        { save_preference: 1 }
      )
      .then((response: any) => {
        if (response?.status === 'success') {
          let userData = this.appService.getUserData();
          if (userData?.preferences) {
            userData.preferences.mute_notification = this.muteNotification;
            this.appService.setUserData(userData);
          }
          this.toasterService.setMessage({
            successMessage: 'Volume Updated',
            errorMessage: '',
          });
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  onTimeZoneChange(event: any) {
    this.selectedTimeZone = event?.target.value;
  }

  changeJobAlertStatus() {
    this.isDispatchUser = false;
    this.isAdmin = false;
    const currentValue = this.userDetails?.job_notification;

    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: currentValue
          ? 'Turn off notifications'
          : 'Turn on notifications',
        message: currentValue
          ? 'You will no longer recieve job completed mail summary'
          : 'Start recieving job summary in mail whenever a job is completed',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.profileService
          .updateUser({}, { update_job_notification_preference: 1 })
          .then((response: any) => {
            if (response?.status === 'success') {
              this.formatProfileData(response['data']);
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      } else {
        this.userDetails.job_notification = currentValue;
      }
      this.isDispatchUser = this.dataCheckService.isDispatchUser();
      this.isAdmin = this.dataCheckService.isUserAdmin();
    });
  }
}
