import { Location, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  getFormattedDate,
  getFormattedDateTime,
  getFormattedTime,
  getWelfareCheckInterval,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ClientsService } from '../../clients/clients.service';
import { PagesService } from '../../pages.service';
import { RosterScheduleService } from '../../roster-schedule/roster-schedule.service';
import { JobsService } from '../jobs.service';

import { Subscription } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import { ContactComponent } from '../../../../shared/components/contact/contact.component';
import { ImagePreviewComponent } from '../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { SchedulerComponent } from '../../../../shared/components/scheduler/scheduler.component';
import { WebcamImageComponent } from '../../../../shared/components/web-cam-image/web-cam-image.component';
import { gTDB } from '../../../../shared/services/db';
import { DeviceInfoService } from '../../../../shared/services/device-info.service';
import { OrientationLockService } from '../../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { WebSocketService } from '../../../../shared/services/web-socket.service';
import { AddEditUserComponent } from '../../users/add-edit-user/add-edit-user.component';

@Component({
  selector: 'gtapp-job-details',
  templateUrl: './job-details.component.html',
  styleUrl: './job-details.component.scss',
  // changeDetection: ChangeDetectionStrategy.on,
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  scrollPosition: any;

  detailData: any;

  availableJobStatus: any = [];

  jobHistory: any = [];
  assigneeIdList: any = [];

  guardListData: any = [];

  newComment: any;
  newRequest: any;

  userData: any;
  fullImgSrc: any;
  largeView: Boolean = isLargeScreen;

  actualUploadFile: any;

  jobDetailsTableView: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
      sortOrder: 'desc',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
      sortOrder: 'desc',
    },

    {
      header: 'Update',
      name: 'action',
      sortKey: 'action',
      searchKey: 'action',
      sortOrder: 'desc',
    },
    {
      header: 'Action',
      showAction: true,
    },
  ];
  jobDetailsTableActionIcons: any = [
    {
      condition: (row: any) => {
        if (this.jobEventUpdateCheck(row)) {
          return {
            icon: 'fa-regular fa-pen-to-square md-font-size text-secondary',
            confirm: true,
            type: 'edit',
            title: 'Edit Update',
          };
        }
        return {};
      },
    },
    {
      condition: (row: any) => {
        if (this.jobEventUpdateCheck(row)) {
          return {
            icon: `fa-regular ${
              row?.is_deleted ? 'fa-square-plus' : 'fa-square-minus'
            } md-font-size text-secondary ms-2`,
            confirm: true,
            type: row?.is_deleted ? 'restore' : 'delete',
            title: row?.is_deleted ? 'Restore Update' : 'Delete Update',
          };
        }
        return {};
      },
    },
  ];
  otherParams: any = {
    tableRowStyle: (rowData: any) => {
      if (rowData?.is_deleted || this.isItemNotShownInReport(rowData)) {
        return {
          opacity: '0.6',
        };
      }
      return {};
    },
  };

  tableStyle = {
    'overflow': 'auto',
    'max-height': '400px',
  };

  selectedEvent: any;
  selectedEvenId: string = ''; // for re opening after any update to the event

  cancelReason: any;

  statusList: any = [];

  siteContactList: any;

  cancelReasonData: any = {};
  jobEventDetails: any = {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },

      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  jobCommentsFiles: any = [];
  subscriberUserId: any;
  isReopenJob: boolean = true;

  selectedJobStatus: any;
  selectedStatus: any = 1;

  messageChannel = new MessageChannel();
  apidataUpdation: boolean = false;
  offlineMode: boolean = false;
  hasScheduleStarted: boolean = false;

  isAdmin: any;
  isDispatchUser: any;
  monitoringCompanyList: any = [];
  previousComment: any;

  mcInputValue: any;

  hideSpinner: boolean = true;

  externalNewJobId: any;
  externalOldJobId: any;

  todayDate: any = new Date();
  jobCreationDate: any = new Date();

  onsiteIntervalFn: any;
  onsiteTimeOutFn: any;
  onsiteTimer = '00:00:00';

  onsiteTime: any;
  dateTimeValue: any;

  scheduleCheck: boolean = true;

  visibilityChangeHandler: any;

  scheduleDuration: any;
  intervalData: any = [];
  welfareReminderDict: any = {};
  selectedInterval: any;

  canScheduleBeUpdated: boolean = false;
  showWelfareItem: boolean = true;

  changeSortOrder: boolean = false;
  selectedAssigneesToAdd: any[] = [];
  selectedAssigneesToRemove: any[] = [];
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(null),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });

  lastStatus: any;
  bottomSectionFixedHeight: string = '198 px';

  uploadType: any;
  statusDropdownOpen: boolean = false;

  dialogRef: any;
  isLargeScreenSubscription: Subscription = new Subscription();

  endDateTime: any;
  validEndDate: any;

  assigneeList: any = [];

  jobAssigneeRows: number = 25;
  jobAssigneePrevious: number = 0;
  jobAssigneePageNum: number = 1;
  jobAssigneeTotalPages: number = 0;
  jobAssigneeTotalCount: number = 0;
  indexedDbListData: any = [];
  jobStatusTimeChange: any = {};
  selectedAssignees: any = [];

  etaValue: any;
  etaMinuteTimerInterval: any;
  etaChangeReason: any;
  etaChangeReasonFieldValid: boolean = true;
  minEta = getFormattedDateTime();
  etaValueList = [
    { key: 'N/A', value: 0 },
    { key: '15 minutes', value: 15 },
    { key: '30 minutes', value: 30 },
    { key: '45 minutes', value: 45 },
    { key: '60 minutes', value: 60 },
  ];
  updateBtnPressed: boolean = false;

  geoAlertDistance: number = 500;
  webSocketSubscription: any;

  loggedInDeviceID: any;

  jobCompleteInvoiceRelatedStatus: any = {};

  jobReportPreviewData: any;

  statusTimeStamps: any = [];

  previousResponseTypeValue: any;
  responseTypeList: any = [];
  initalResponseTypeList: any = [];
  serviceTypeListData: any = [];
  initalServiceTypeListData: any = [];
  previousServiceTypeValue: any;

  canDeclineJob: boolean = false;
  declineReason: string = '';

  reportRecipientList: any = [];
  selectedReportRecipientList: any = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,
    private jobService: JobsService,
    private clientService: ClientsService,
    private dialogService: ModelDialogueService,
    private router: Router,
    private _location: Location,
    private pageService: PagesService,
    private cdr: ChangeDetectorRef,
    private dataCheckService: DataCheckService,
    private swUpdate: SwUpdate,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private rsService: RosterScheduleService,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private deviceInfoService: DeviceInfoService,
    private route: ActivatedRoute,
    private webSocketService: WebSocketService,
    private authService: AuthService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });

    this.route.queryParams.subscribe({
      next: (params) => {
        if (params?.['jId']) {
          // mainly used for redirecting links in emails from backend
          localStorage.setItem('urlId', params?.['jId']);

          if (this.detailData?.id && this.detailData?.id !== params?.['jId']) {
            this.getJobDetails();
          }
        }
      },
    });

    this.userData = this.appService.getUserData();

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    this.subscriberUserId = this.userData?.subscriber?.subscriber_user_id;
  }

  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
          this.calculateFixedBottomHeight();
        }
      });
    this.etaValue = getFormattedDateTime();
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.offlineMode = !value?.appStatus;
        if (value?.appStatus) {
          // case where user is not doing update, which implies we can update the ui from offline to online or vice versa
          let offlineReloadCheck = this.offlineMode;

          if (this.offlineMode == false && offlineReloadCheck) {
            this.pageService.miscSubjectParam.next({ syncData: true });
          }
        }
      }
    });
    this.webSocketSubscription =
      this.webSocketService.webSocketSubject.subscribe((event: any) => {
        const isSameUserOnAnotherDevice =
          this.subscriberUserId === event?.request_user?.subs_id &&
          this.loggedInDeviceID &&
          this.loggedInDeviceID !== event?.request_user?.logged_in_device_id;
        const isAnotherUser =
          this.subscriberUserId !== event?.request_user?.subs_id;

        const allowUser = isSameUserOnAnotherDevice || isAnotherUser;

        if (
          event?.event === 'individual_job_update' &&
          event?.data?.id === this.detailData?.id &&
          allowUser
        ) {
          // update the changes made by someone else in realtime

          this.apidataUpdation = true;
          setTimeout(() => {
            this.detailData = event?.data;
            this.formatData();
            setTimeout(() => {
              this.formatCachedList();
            }, 1000);

            this.apidataUpdation = false;
          }, 100);
        }
        if (
          ['user_job_update', 'user_response_job_update'].includes(
            event?.event
          ) &&
          event?.data?.delete &&
          event?.data?.id === this.detailData?.id &&
          allowUser
        ) {
          // one of the user has deleted the job
          // route to home page.
          this.toasterService.setMessage({
            successMessage: `This job was just deleted by ${event?.request_user?.user_name}`,
            errorMessage: '',
          });
          this.router.navigate(['/']);
        }
        if (event?.event === 'user_job_absolved') {
          this.getJobDetails();
        }
      });

    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_JOB_DETAIL_PORT',
      },
      [this.messageChannel.port2]
    );

    // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.data) {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.detailData = event?.data?.data;

            setTimeout(() => {
              this.formatData();
              this.formatCachedList();
            }, 500);

            this.apidataUpdation = false;
          }, 100);
        });
      }
    };

    this.cdr.detectChanges();
    this.getJobDetails();

    this.visibilityChangeHandler = () => {
      if (document.visibilityState === 'visible') {
        this.getTimerValue();
      }
    };
    this.getDeviceID();
  }

  getJobDetails() {
    let id = window.localStorage.getItem('urlId');
    if (!id) {
      this.router.navigate(['/']);
    }

    this.pageService.miscSubjectParam.next({
      isOnEvent: true,
    });
    this.getJobInfo(id);
    //check whether offline and localhost, if so change apidataUpdation = false
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.apidataUpdation = false;
    }
  }
  getJobInfo(id: any) {
    this.spinnerService.show();
    if (this.largeView) {
      this.getLargeDevicesJobDetailById(id);
    } else {
      // this path is different from the other. This will cache the data in the indexed db
      this.getMobileJobDetailById(id);
    }
  }

  getLargeDevicesJobDetailById(jobId: any) {
    this.jobService.getJobById(jobId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.detailData = response['data'];

        this.formatData();
      }
      this.spinnerService.hide();
    });
  }

  getMobileJobDetailById(jobId: any) {
    if (!this.offlineMode) {
      this.apidataUpdation = true;
    }

    this.jobService
      .getJobDetailById({ job_id: jobId })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.detailData = response['data'];

          this.formatData();
        }
        this.spinnerService.hide();
      });
  }
  getCancelledReason() {
    if (this.detailData?.history?.length > 2) {
      let jobHistory = this.detailData?.history;
      let cancelEvent = jobHistory[jobHistory?.length - 1];
      if (
        cancelEvent &&
        [2, 3].includes(this.detailData?.status?.status_identity?.value)
      ) {
        let cancelReason = jobHistory[jobHistory?.length - 2];

        this.cancelReasonData = {
          reason: cancelReason?.detail,
          time: cancelEvent?.updated_at,
          by: cancelReason?.user_name,
        };
      }
    }
  }
  changeEndDateTime(event: any) {
    this.schedulerForm.controls['end_day'].setValue(
      getFormattedDate(event.target.value)
    );
    this.schedulerForm.controls['end_time'].setValue(
      getFormattedTime(event.target.value)
    );
  }
  formatSchedule() {
    this.schedulerForm.patchValue(this.detailData?.roster_scheduler);

    let startDay = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : this.detailData?.roster_scheduler?.start_day
      ? new Date(this.detailData?.roster_scheduler?.start_day)
      : new Date();
    startDay.setDate(startDay.getDate() - 1);

    this.sheduleIfChecks();
  }
  async formatHistory() {
    // deep copy since sw adds item in the jobHistory list
    this.jobHistory = JSON.parse(JSON.stringify(this.detailData?.history));

    if (this.selectedEvenId) {
      // in case of delete/restore/edit comments reopen the selected event after the update has been made.
      const updatedEvent = this.jobHistory?.find(
        (update: any) => update?.id === this.selectedEvenId
      );
      if (updatedEvent?.id) this.onTableRowClick(updatedEvent);
    }
    await this.formatCachedList();
  }
  formatCachedData(item: any) {
    let eventDict: any = {};
    if (item?.pathName === '/api/job/update_job/') {
      if (item?.image_source === 'live') {
        eventDict = { event_action: 8, action: 'Uploaded Image' };
      } else if (item?.image_source === 'device') {
        eventDict = { event_action: 19, action: 'File Uploaded' };
      } else {
        eventDict = { event_action: 7, action: 'Added Comment' };
      }
    }
    return {
      cachedData: true,
      detail: item?.comment || null,
      gps: item?.device_info?.gps,
      updated_at: item?.updated_at * 1000,
      recorded_at: item?.updated_at * 1000,
      index_db_id: item?.indexDBId,
      file: item?.file || null,
      subs_user_id: this.subscriberUserId,
      ...eventDict,
      device_details: {
        Ip: item?.device_info?.gps?.ip,
        Browser: `${item?.device_info?.browser} - ${item?.device_info?.browser_version}`,
        Device: `${item?.device_info?.device} - ${item?.device_info?.deviceType} `,
      },
    };
  }
  deleteItemFromIndexDb(pkId: number) {
    gTDB.gtSavedData
      .where({
        indexDBId: pkId,
      })
      .delete();
    this.jobHistory = JSON.parse(JSON.stringify(this.detailData?.history));
    this.formatCachedList();
  }
  async formatCachedList() {
    const loggedInUsersUpdates = this.jobHistory?.filter(
      (item: any) =>
        item?.subs_user_id == this.subscriberUserId &&
        [7, 8, 19].includes(item?.event_action)
    );

    this.indexedDbListData = await this.getAllIndexedDbItems();

    let remainingDataList: any = [];

    this.indexedDbListData?.forEach((cachedItem: any) => {
      if (cachedItem?.job_id === this.detailData?.id) {
        // making sure its the opened job
        if (cachedItem?.id) {
          // id is there it implies the item is already in the db
          if (cachedItem?.edit_comment && !cachedItem?.apiCallSuccess) {
            this.jobHistory?.forEach((update: any) => {
              if (cachedItem?.id === update?.id) {
                update.cachedData = true;
              }
            });
          }
        } else {
          if (
            !loggedInUsersUpdates?.some(
              (item: any) =>
                item?.index_db_id == cachedItem?.indexDBId &&
                new Date(cachedItem?.updated_at * 1000).getTime() ===
                  new Date(item?.recorded_at).getTime()
            )
          ) {
            remainingDataList.push(cachedItem);
          }
        }
      }
    });

    remainingDataList?.forEach((item: any) => {
      let data = this.formatCachedData(item);

      if (
        !this.jobHistory?.some(
          (item: any) =>
            item?.cachedData && item?.index_db_id == data?.index_db_id
        )
      ) {
        this.jobHistory.push(data);
      }
    });
    this.jobHistory = this.jobHistory?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    this.jobCommentsFiles = [
      ...this.jobHistory?.filter((item: any) =>
        // 8, _("uploaded image")
        // 19, ("file uploaded")
        // 1, _("changed status")
        // 7, _("added comment")
        [8, 19, 7, 1].includes(item?.event_action)
      ),
    ];

    if (this.detailData?.status?.status_identity?.value === 1) {
      this.isReopenJob = this.detailData?.can_reopen;
      // if completd job find the last status to reopen
      if (this.detailData?.can_reopen) {
        const statusChangeList = this.jobHistory?.filter(
          (item: any) => item?.event_action === 1
        );
        if (statusChangeList?.length) {
          // second last from the list as the list has been sorted
          const prevStatus = statusChangeList[statusChangeList?.length - 2];
          this.lastStatus = this.availableJobStatus.find(
            (s: any) => s?.status?.id === prevStatus?.changed_status_id
          );
        }

        if (!this.lastStatus) {
          this.lastStatus = this.backupLastStatus();
        }
      }
    }
    if (this.detailData?.status?.status_identity?.value === 9) {
      // review status; show timestamps and make them editable for admins and dispatchers
      let lastUpdatedStatusDict: any = {};

      this.jobHistory?.forEach((update: any) => {
        if (
          update?.event_action === 1 &&
          [1, 4, 5, 8].includes(update?.status_identity)
        ) {
          lastUpdatedStatusDict[update?.detail] = update;
        }
      });
      this.statusTimeStamps = Object.values(lastUpdatedStatusDict);
    }

    let updatedPatrolEvents: any = this.indexedDbListData
      ?.filter(
        (cachedItem: any) =>
          cachedItem?.job_id === this.detailData?.id &&
          cachedItem?.apiCallSuccess
      )
      ?.map((item: any) => item?.indexDBId);

    this.deleteCachedData(updatedPatrolEvents);

    this.appService.initializeBootstrapTooltips();
  }
  areSameDay(date1: Date, date2: Date): boolean {
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    return year1 === year2 && month1 === month2 && day1 === day2;
  }

  formatData() {
    this.dialogRef?.close();
    const isAdminORDispatcher = this.isAdmin || this.isDispatchUser;
    if (
      this.detailData?.status?.status_identity?.value === 9 &&
      isAdminORDispatcher
    ) {
      // review job
      this.router.navigate([`/job-review/${this.detailData?.job_key}`], {
        queryParams: { jId: this.detailData?.id },
      });
      return;
    }

    this.router.navigate([`/job-detail/${this.detailData?.job_key}`], {
      queryParams: {},
      replaceUrl: true, // Replaces the current URL without adding a new history entry
    });
    if (!isAdminORDispatcher) {
      // if not part of assignees route to home page
      const isUserInAssignees = this.detailData?.assignee?.some(
        (assignee: any) => assignee?.id === this.subscriberUserId
      );
      if (!isUserInAssignees) {
        this.router.navigate([`/`]);
      }
    }
    if (
      (this.detailData?.onsite_time || this.detailData?.on_site_time) &&
      this.detailData?.status?.status_identity?.value === 4
    ) {
      this.startCountup();
      document.addEventListener(
        'visibilitychange',
        this.visibilityChangeHandler
      );
    }
    if (this.detailData?.status?.status_identity?.value !== 4) {
      this.clearIntervalTimeoutFunction();
      document.removeEventListener(
        'visibilitychange',
        this.visibilityChangeHandler
      );
    }

    if (this.detailData?.roster_scheduler?.repeat_type) {
      this.formatSchedule();
      this.getTimeDifference();
    }
    this.jobStatusTimeChange.created_at = getFormattedDateTime(
      this.detailData?.created_date
    );
    this.formatAssigneeDropDown();
    this.newComment = '';
    this.getStatusTextandColor();

    this.formatHistory();

    if (this.detailData?.status?.status_identity?.value === 8) {
      // if the status is `In Transit` show eta
      this.detailData.showEta = true;

      const eta = this.detailData?.additional_info?.eta;

      if (eta > 100000000) {
        this.detailData.eta = new Date(eta);
        this.detailData.sameDay = this.areSameDay(new Date(eta), new Date());

        this.updateEtaMinutes(this.detailData?.eta);

        this.etaValue = getFormattedDateTime(this.detailData.eta);
      }
    }

    this.jobCreationDate = new Date(this.detailData?.history[0]?.updated_at);
    this.newRequest = this.detailData?.request_details;

    this.assigneeIdList =
      this.detailData?.assignee?.map((item: any) => {
        return item.id;
      }) || [];

    if (
      this.detailData?.status?.status_identity?.value === 2 ||
      this.detailData?.status?.status_identity?.value === 3
    )
      this.getCancelledReason();
    this.selectedInterval = this.detailData?.interval_check
      ? this.detailData?.interval_check
      : this.intervalData[0]?.value;
    this.ngZone.run(() => {
      setTimeout(() => {
        this.calculateFixedBottomHeight();
        this.spinnerService.hide();
        this.updateBtnPressed = false;
      }, 100);
    });

    if (this.detailData?.eta) {
      this.etaMinuteTimerInterval = setInterval(() => {
        this.updateEtaMinutes(this.detailData?.eta);
      }, 5000);
    } else if (this.etaMinuteTimerInterval) {
      clearInterval(this.etaMinuteTimerInterval);
    }
  }
  updateEtaMinutes(eta: any) {
    // added an interval to update the eta remaining minutes
    const diffInTime = new Date(eta)?.getTime() - new Date().getTime();
    const diffInMinutes = Math.floor(diffInTime / (1000 * 60)) + 1;

    this.detailData.etaDiff = {
      minutes: diffInMinutes,
    };
  }
  formatAssigneeDropDown() {
    let exisitingAssignees = this.getExisitingAssignees();
    this.guardListData = this.guardListData?.filter(
      (user: any) => !exisitingAssignees.includes(user?.id)
    );
  }

  clearInfo() {
    this.newComment = '';
    this.dialogRef?.close();
    this.newComment = '';
  }
  addComment(data?: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body: any = {
        job_id: this.detailData?.id,
        comment: this.newComment,
      };
      if (Object.keys(data).length) {
        body.id = data?.id;
        body.edit_comment = 1;
      }
      this.jobService.jobUpdate(body).then((response: any) => {
        this.clearInfo();
        this.formatHistory();
        if (this.offlineMode && response?.status == 'success') {
          this.toasterService.setMessage({
            successMessage: 'Action Queued',
            errorMessage: '',
          });
        }

        if (response?.data) {
          this.detailData = response['data'];
          this.selectedEvent = null;
          this.formatData();
        }

        this.spinnerService.hide();
      });
    } else {
      this.toasterService.setMessage({
        successMessage: ' ',
        errorMessage: 'Comment cannot be empty',
      });
    }
  }
  updateJobStatus(value: any) {
    if (value) {
      if (value?.status?.status_identity.value === 3) {
        let dialogMsg =
          'Cancelling job means the job will be deleted and you  will not be able to make any changes.';
        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure?',
            message: dialogMsg,
          },
        });
        dialogRef.afterClosed().subscribe((dialogResponse) => {
          if (dialogResponse === true) {
            this.updateData({
              job_status_id: value.id,
            });
          }
        });
      } else {
        this.updateData({
          job_status_id: value.id,
        });
      }
    }
  }
  nextJobSection(status: any) {
    if (status?.status_identity.value === 1) {
      let dialogMsg = 'Mark job as complete?';
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirmation Needed',
          message: dialogMsg,
        },
      });
      dialogRef.afterClosed().subscribe((dialogResponse) => {
        if (dialogResponse === true) {
          this.updateData(status);
        }
      });
    } else {
      this.updateJobStatus(status);
    }
  }

  getRequestDetails() {
    let requestData: any = {};
    requestData['method'] = 'put';
    requestData['url'] = 'api/job/' + this.detailData.id + '/';
    requestData['id'] = this.detailData.id;
    return requestData;
  }

  updateData(body: any, showToaster: boolean = true) {
    let requestData = this.getRequestDetails();
    if (body) {
      if (this.updateCheck()) {
        this.updateBtnPressed = true;
        this.spinnerService.show();
        requestData['data'] = body;
        this.jobService.updateJob(requestData).then((response: any) => {
          if (response['status'] == 'success') {
            this.detailData = response?.data;
            const isDispatcherAdmin = this.isAdmin || this.isDispatchUser;
            if (
              !response?.data?.id ||
              this.detailData?.status?.status_identity?.value === 2 ||
              this.detailData?.status?.status_identity?.value === 1 ||
              this.detailData?.status?.status_identity?.value === 7 ||
              (this.detailData?.status?.status_identity?.value === 9 &&
                !isDispatcherAdmin)
            ) {
              this._location.back();
            } else {
              if (showToaster === true) {
                this.dialogRef?.close();
                this.toasterService.setMessage({
                  successMessage: response['message'],
                  errorMessage: '',
                });
              }

              this.newComment = '';
              this.etaChangeReason = '';

              this.etaChangeReasonFieldValid = true;
              this.formatData();

              this.spinnerService.hide();
            }
          } else {
            this.updateBtnPressed = false;
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
            });
          }
        });
      }
    }
  }
  openCamPopup() {
    this.dialogRef = this.dialogService.open(WebcamImageComponent, {
      data: {
        showFrom: 'updateJob',
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.actualUploadFile = value.actualFileUpload;
        this.uploadType = value?.uploadType;
        this.newComment = value.newComment;
        this.onFileSubmit();
      } else if (value == 'error') {
        this.toasterService.setMessage({
          errorMessage: 'Unable to access your camera',
        });
      }
    });
  }

  fileUpload(url: string, fileData: any) {
    fileData.append('image_source', this.uploadType);
    this.appService.formDataApi(url, fileData).then((response: any) => {
      this.clearInfo();
      this.formatHistory();
      if (this.offlineMode && response?.status == 'success') {
        this.toasterService.setMessage({
          successMessage: 'Action Queued',
          errorMessage: '',
        });
      }

      if (response?.data) {
        this.detailData = response['data'];

        this.formatData();
      }

      this.spinnerService.hide();
    });
  }
  //Method to upload the photo for selected site or job
  onFileSubmit() {
    if (!this.actualUploadFile) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Please add a photo',
      });
    } else {
      this.spinnerService.show('Uploading Details');
      let fileData: FormData = new FormData();

      fileData.append('file', this.actualUploadFile);
      if (this.newComment) {
        fileData.append('comment', this.newComment);
      }
      let url = `job/update_job`;
      fileData.append('job_id', this.detailData?.id);

      this.fileUpload(url, fileData);
    }
  }

  updateRequestDetails() {
    this.updateData({ request: this.newRequest });
  }
  updateCheck() {
    if (this.detailData?.has_been_24hrs) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage:
          'You cannot make changes to a completed job after 24 hours',
      });
      return false;
    }

    return true;
  }

  deleteJob() {
    let newStatus = this.availableJobStatus?.filter(
      (s: any) =>
        s?.status.status_identity.value === 2 ||
        s?.status.status_identity.value === 3
    )[0];

    let body = {
      job_status_id: newStatus?.status?.id,
      comment: this.cancelReason,
    };

    if (body) {
      this.updateData(body);
    }
  }
  openAssigneeDetail(assignee: any) {
    if (assignee.id) {
      this.dialogService.open(AddEditUserComponent, {
        data: {
          editUser: true,
          detailData: assignee,
        },
      });
    }
  }

  validLicenseCheck(licenses: any) {
    return licenses?.some(
      (licence: any) =>
        licence?.issuer_state === this.detailData.sites[0]?.address?.state_code
    );
  }

  getGuardDetails(guardsData: any) {
    let exisitingAssignees = this.getExisitingAssignees();

    for (let i = guardsData.length - 1; i >= 0; i--) {
      guardsData[i].validStateLicense = this.validLicenseCheck(
        guardsData[i]?.license || []
      );

      if (exisitingAssignees.includes(guardsData[i].id)) {
        guardsData.splice(i, 1);
      }
    }

    return guardsData;
  }
  getAssignees(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.guardListData?.length)
    ) {
      this.jobService
        .getAssignees({ full_name: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.guardListData = this.getGuardDetails(res['data']);
          }
        });
    }
  }
  onLoadMoreAssignees() {
    this.jobAssigneePrevious = this.jobAssigneePrevious + this.jobAssigneeRows;

    this.getJobAssignees();
  }
  addRemoveAssignee(assignee: any) {
    assignee.selected = !assignee?.selected;
    if (assignee.selected) {
      this.onAssigneeSelect(assignee);
    } else {
      this.onAssigneeDeselect(assignee);
    }
  }
  getJobAssignees() {
    let params: any = {};
    if (this.jobAssigneeRows) {
      params['rows'] = this.jobAssigneeRows;
    }
    if (this.jobAssigneePrevious) {
      params['previous'] = this.jobAssigneePrevious;
    }

    this.spinnerService.show();
    this.jobService.getAssignees(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList.push(...response?.data);

        this.jobAssigneeTotalCount = response?.total_count;
        this.jobAssigneeTotalPages = Math.ceil(
          this.jobAssigneeTotalCount / this.jobAssigneeRows
        );

        this.makeAssigneeSelectionChanges();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
      this.spinnerService.hide();
    });
  }
  makeSiteContactSelectionChanges() {
    this.siteContactList.forEach((sContact: any) => {
      sContact.selected = this.detailData?.location_contacts?.some(
        (selectedContact: any) => selectedContact?.id === sContact?.id
      );
    });
    this.siteContactList.sort((a: any, b: any) => {
      if (a?.selected === b?.selected) {
        return 0;
      }
      return a?.selected ? -1 : 1;
    });
  }
  makeAssigneeSelectionChanges() {
    this.assigneeList.forEach((assignee: any) => {
      assignee.selected = this.detailData?.assignee?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
      if (assignee.selected) {
        if (!this.selectedAssignees.some((a: any) => a.id === assignee.id)) {
          this.selectedAssignees.push(assignee);
        }
      }
    });
    this.assigneeList.sort((a: any, b: any) => {
      if (a?.selected === b?.selected) {
        return 0;
      }
      return a?.selected ? -1 : 1;
    });
  }

  getExisitingAssignees() {
    return (
      this.detailData?.assignee?.map((item: any) => {
        return item.id;
      }) || []
    );
  }
  onAssigneeDeselect(assignee: any) {
    if (
      !this.selectedAssigneesToRemove.some((a: any) => a.id === assignee.id)
    ) {
      this.selectedAssigneesToRemove.push(assignee);
    }
    // Remove from addedAssignees if it was there
    this.selectedAssigneesToAdd = this.selectedAssigneesToAdd?.filter(
      (a: any) => a.id !== assignee.id
    );
    this.selectedAssignees = this.selectedAssignees?.filter(
      (item: any) => item.id !== assignee.id
    );
  }
  addAssigneeToList(assignee: any) {
    if (!this.selectedAssigneesToAdd.some((a: any) => a.id === assignee.id)) {
      this.selectedAssigneesToAdd.push(assignee);
    }
    // Remove from removedAssignees if it was there
    this.selectedAssigneesToRemove = this.selectedAssigneesToRemove?.filter(
      (a: any) => a.id !== assignee.id
    );
    this.selectedAssignees = [...this.selectedAssignees, assignee];
  }

  onAssigneeSelect(assignee: any) {
    if (
      assignee?.license?.some(
        (item: any) =>
          item.issuer_state === this.detailData.sites[0]?.address?.state_code
      ) ||
      assignee.status === 'ASSIGNED'
    ) {
      assignee.status = 'ASSIGNED';
      this.addAssigneeToList(assignee);
    } else {
      let dialogMsg: any = '';
      if (assignee.id === this.subscriberUserId) {
        dialogMsg =
          'You do not hold a licence for this location, are you sure you want to take the job?';
      } else {
        dialogMsg = `${
          assignee.full_name || assignee.email
        } does not hold a licence for this location, are you sure you want assign to ${
          assignee.full_name || assignee.email
        }?`;
      }
      const dialogRef = this.dialogService.open(
        ConfirmDialogComponent,
        {
          data: {
            title: 'Add Assignee',
            message: dialogMsg,
          },
        },
        this.viewContainerRef
      );
      dialogRef.afterClosed().subscribe((value) => {
        if (value === true) {
          this.addAssigneeToList(assignee);
        } else {
          this.makeAssigneeSelectionChanges();
        }
      });
    }
  }
  backupLastStatus() {
    const reopenStatus =
      this.availableJobStatus.find(
        (s: any) => s?.status.status_identity.value === 6
      ) ||
      this.availableJobStatus.find(
        (s: any) => s?.status.status_identity.value === 0
      );
    return reopenStatus;
  }

  moveJobBack() {
    if (this.detailData?.has_been_24hrs) {
      this.toasterService.setMessage({
        errorMessage:
          'You cannot make changes to a completed job after 24 hours',
        successMessage: '',
      });
    } else {
      if (this.lastStatus?.status?.id) {
        const dialogRef = this.dialogService.open(
          ConfirmDialogComponent,
          {
            data: {
              title: 'Reopen Job',
              message:
                'WARNING: Site contacts will be notified if you reopen this job.',
            },
          },
          this.viewContainerRef
        );
        dialogRef.afterClosed().subscribe((value: any) => {
          if (value === true) {
            this.updateJobStatus(this.lastStatus?.status);
          }
        });
      }
    }
  }
  reviewJob() {
    const dialogRef = this.dialogService.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Edit Report',
        },
      },
      this.viewContainerRef
    );
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.updateData({
          job_status_id:
            this.jobCompleteInvoiceRelatedStatus?.review?.status?.id,
        });
      }
    });
  }
  approveJob(sendReport = false) {
    const dialogRef = this.dialogService.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Approve Job',
          message: `Mark the job as complete. ${
            sendReport ? 'Send reports to client' : ''
          }`,
        },
      },
      this.viewContainerRef
    );
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        let reqBody: any = {
          job_status_id:
            this.jobCompleteInvoiceRelatedStatus?.approve?.status?.id,
        };
        if (sendReport) {
          reqBody.send_report = true;
        }
        this.updateData(reqBody);
      }
    });
  }
  getJobReportRecipients() {
    this.spinnerService.show();
    this.jobService
      .getJobById(this.detailData?.id, { job_report_contacts: 1 })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.reportRecipientList = response?.data;
          this.selectedReportRecipientList = [
            ...new Set(
              this.reportRecipientList
                ?.filter((recipient: any) => !recipient?.isSnubbed)
                ?.map((recipient: any) => recipient?.id)
            ),
          ];
        } else {
          this.spinnerService.hide();
        }
      });
  }
  resendJobReport(template: any) {
    this.getJobReportRecipients();

    const dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.updateData({
          resend_report: 1,
          recipient_ids: this.selectedReportRecipientList,
        });
      }
    });
  }

  onTableRowClick(event: any) {
    this.selectedEvent = event;
    this.selectedEvenId = event?.id;

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  }

  hideDetails() {
    this.selectedEvent = null;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }

  closeStatusDropDown() {
    setTimeout(() => {
      this.statusDropdownOpen = false;
      this.cdr.detectChanges();
    }, 200);
  }
  checkAndModifyETA(etaValue: any) {
    const selectedETA = new Date(etaValue).getTime();
    return selectedETA > new Date().getTime() + 1000 * 60 ? selectedETA : 0; // implies the user has selected N/A;
  }

  changeJobStatus(
    status: any,
    jobCancelTemplate: any,
    onsiteTimeTempalteRef: any,
    inTransitTemplate: any
  ) {
    if ([4, 5, 8].includes(status?.status?.status_identity.value)) {
      this.dateTimeValue = getFormattedDateTime();
      this.jobStatusTimeChange.currentTime = getFormattedDateTime();
      this.jobStatusTimeChange.valid = true;
      const selectedTemplate = [4, 5].includes(
        status?.status?.status_identity.value
      )
        ? onsiteTimeTempalteRef
        : inTransitTemplate;
      const dialogRef = this.dialogService.open(
        selectedTemplate,
        {
          data: { name: status?.status?.name },
        },
        this.viewContainerRef
      );

      dialogRef.afterClosed().subscribe((value: any) => {
        if (value !== false) {
          const newStatus = this.availableJobStatus.find(
            (s: any) => s?.status?.id === status?.status?.id
          );

          if (newStatus) {
            let body: any = {
              job_status_id: newStatus?.status?.id,
            };
            if ([4, 5].includes(status?.status?.status_identity.value)) {
              body.user_provided_timestamp = new Date(this.dateTimeValue);
            } else if (typeof value === 'string') {
              body.eta = this.checkAndModifyETA(value);
            }

            this.updateData(body);
          }
        } else {
          this.selectedStatus = null;
        }
      });
    } else {
      let newStatus = this.availableJobStatus?.filter(
        (s: any) => s?.status?.id === status?.status?.id
      )[0];

      if (newStatus) {
        if (
          newStatus?.status?.status_identity?.value === 2 ||
          newStatus?.status?.status_identity?.value === 3
        ) {
          this.openCancelJobTemplate(jobCancelTemplate);
        } else if ([1, 7].includes(newStatus?.status?.status_identity?.value)) {
          let dialogMsg =
            newStatus?.status?.status_identity?.value === 1
              ? 'Mark job as complete?'
              : 'Delete the Job';
          this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
            data: {
              title: 'Confirmation Needed',
              message: dialogMsg,
            },
          });
          this.dialogRef.afterClosed().subscribe((dialogResponse: any) => {
            if (dialogResponse === true) {
              this.updateJobStatus(newStatus.status);
            } else {
              this.selectedStatus = null;
            }
          });
        } else {
          this.updateJobStatus(newStatus.status);
        }
      }
    }
    this.statusDropdownOpen = false;
  }
  addRemoveSiteContact(contactData: any) {
    contactData.selected = !contactData?.selected;
    if (contactData.selected) {
      this.addSiteContact(contactData?.id);
    } else {
      this.onSiteContactDeselect(contactData);
    }
  }
  modifySiteContactTemplate(modifySiteContactRef: any) {
    this.siteContactList = [];
    this.getSiteContacts();
    this.dialogRef = this.dialogService.open(
      modifySiteContactRef,
      {},
      this.viewContainerRef
    );
  }
  getSiteContacts() {
    this.clientService
      .getSiteContacts(this.detailData?.sites[0]?.id)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.siteContactList = response['data'];
          this.makeSiteContactSelectionChanges();
        }
      });
  }
  addSiteContact(contactId: any) {
    this.updateData({ new_site_contact: contactId });
  }
  onSiteContactDeselect(contact: any) {
    this.updateData({ remove_site_contact: contact.id });
  }
  addLocationContact() {
    let siteData: any = this.detailData?.sites[0];
    siteData['contacts'] = this.siteContactList;

    const dialogRef = this.dialogService.open(ContactComponent, {
      data: {
        siteData: siteData,
        addNewSiteContact: true,
        contactType: 'lc',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'false') {
        this.siteContactList = value;
        let lastAddedContact =
          this.siteContactList[this.siteContactList.length - 1];
        this.makeSiteContactSelectionChanges();
        this.addSiteContact(lastAddedContact?.id);
      }
    });
  }

  getStatusTextandColor() {
    this.availableJobStatus = this.detailData?.service_type?.statuses;
    this.selectedJobStatus = this.detailData?.status || {};

    this.statusList = this.detailData?.service_type?.statuses
      ?.map((obj: any) => {
        const statusValue = obj?.status?.status_identity?.value;
        if ([9, 10, 11, 12, 13].includes(statusValue)) {
          this.formatAfterCompleteStatus(obj);
        }
        if (statusValue === 2 || statusValue === 3) {
          return {
            ...obj,
            status: {
              ...obj.status,
              name: 'Cancel Job',
              color: 'var(--button--danger-text-color)',
            },
          };
        } else if (
          statusValue !== 6 &&
          this.selectedJobStatus?.id !== obj?.status?.id
        ) {
          return obj;
        }
      })
      ?.filter(Boolean);

    this.statusList = this.filterStatuses(this.statusList, [
      this.detailData?.next_status?.forward_status,
      this.detailData?.next_status?.backward_status,
      this.detailData?.next_status?.reverse_status,
    ]);

    if (this.detailData?.status?.status_identity === 1) {
      this.isReopenJob = this.detailData?.can_reopen;
    }
  }

  formatAfterCompleteStatus(statuObj: any) {
    const statusIdentiy = statuObj?.status?.status_identity?.value;
    let statusKey = '';

    switch (statusIdentiy) {
      case 9:
        statusKey = this.detailData?.sites?.[0]?.auto_send_reports
          ? ''
          : 'review';
        if (this.detailData?.status?.status_identity?.value === 10)
          statusKey = 'review';
        break;
      case 10:
        statusKey = 'approve';
        break;
    }
    if (statusKey) {
      this.jobCompleteInvoiceRelatedStatus[statusKey] = statuObj;
    }
  }
  mapsSelector(lat: any, lon: any) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }
  modifyAssignees(modifyAssigneeRef: any) {
    this.assigneeList = [];
    this.getJobAssignees();
    this.dialogRef = this.dialogService.open(
      modifyAssigneeRef,
      {},
      this.viewContainerRef
    );
  }
  modifyRequestDetails(requestRef: any) {
    if (this.isAdmin === true || this.isDispatchUser === true) {
      this.dialogRef = this.dialogService.open(
        requestRef,
        {},
        this.viewContainerRef
      );
    }
  }
  openSiteContactTemplate(data: any) {
    let siteData: any = this.detailData?.sites[0];

    this.dialogRef = this.dialogService.open(ContactComponent, {
      data: {
        contactData: data,
        siteData: siteData,
      },
    });
    this.dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse !== 'false') {
        this.getJobInfo(this.detailData?.id);
      }
    });
  }
  openAddCommentTemplate(requestRef: any) {
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }

  openCancelJobTemplate(requestRef: any) {
    this.dialogRef = this.dialogService.open(
      requestRef,
      {},
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse === 'Yes') {
      } else {
        this.selectedStatus = null;
      }
    });
  }
  redirect(location: string) {
    if (location === 'mc') {
      this.router.navigate([
        '/view-company',
        { cKey: String(this.detailData?.monitoring_company?.id) },
      ]);
    } else if (location === 'client') {
      this.router.navigate([
        '/view-client',
        { cKey: String(this.detailData?.company_id) },
      ]);
    } else {
      this.router.navigate([
        '/view-site',
        { sKey: String(this.detailData?.sites[0]?.id) },
      ]);
    }
  }

  onOpenImage(event: any, data: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.fullImgSrc = srcAttr.nodeValue;
    this.dialogRef = this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: srcAttr.nodeValue, timeStamp: data.updated_at },
    });
  }

  showOfflineMessage() {
    this.toasterService.setMessage({
      successMessage: '',
      errorMessage: 'Feature not available while offline',
    });
  }
  editEvent(eventData: any, requestRef?: any, timeStampTempRef?: any) {
    if (eventData?.event_action === 1) {
      this.editTimeStamp(eventData, timeStampTempRef);
    } else {
      this.editComment(eventData, requestRef);
    }
  }
  editComment(eventData: any, requestRef: any) {
    this.newComment = eventData?.detail;
    this.previousComment = eventData?.detail;
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: eventData,
      },
      this.viewContainerRef
    );
  }
  changeStatusTimeStamp(event: any, eventData: any) {
    const selectedTime = event?.target.value
      ? new Date(event?.target.value).getTime()
      : new Date(eventData?.currentValue).getTime();

    eventData.valid =
      selectedTime >= eventData?.minDateTime?.getTime() &&
      selectedTime <= eventData?.maxDateTime?.getTime();
  }
  editTimeStamp(event: any, timeStampTempRef: any) {
    function getEventTime(item: any) {
      return item?.user_provided_timestamp
        ? item?.user_provided_timestamp
        : item?.updated_at;
    }
    const currentValue = getFormattedDateTime(
      event?.user_provided_timestamp
        ? event?.user_provided_timestamp
        : event?.updated_at
    );

    let updatedAtMax: any;
    let updatedAtMin: any;
    let maxDateTime = new Date();
    let minDateTime = new Date(this.detailData?.created_date);

    switch (event?.status_identity) {
      case 1: // complete
        // min can be the timestamp of offsite ie this should be greater than offsite timestamp
        updatedAtMin = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 5
          )
        );

        maxDateTime = new Date(); // max can be the now()
        minDateTime = updatedAtMin ? new Date(updatedAtMin) : minDateTime;
        break;
      case 5: // offsite
        // max can be the timestamp of complete ie this should be less than complete timestamp

        updatedAtMax = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 1
          )
        );
        // min can be the timestamp of onsite ie this should be greater than onsite timestamp
        updatedAtMin = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 4
          )
        );

        maxDateTime = updatedAtMax ? new Date(updatedAtMax) : new Date();
        minDateTime = updatedAtMin ? new Date(updatedAtMin) : minDateTime;
        break;
      case 4: // onsite
        // max can be the timestamp of offsite ie this should be less than offsite timestamp

        updatedAtMax = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 5
          )
        );
        // min can be the timestamp of in transit ie this should be greater than in transit timestamp
        updatedAtMin = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 8
          )
        );

        maxDateTime = updatedAtMax ? new Date(updatedAtMax) : new Date();
        minDateTime = updatedAtMin ? new Date(updatedAtMin) : minDateTime;
        break;

      case 8: // in transit
        // max can be the timestamp of onsite ie this should be less than onsite timestamp

        updatedAtMax = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 4
          )
        );
        maxDateTime = updatedAtMax ? new Date(updatedAtMax) : new Date();
        minDateTime = updatedAtMin ? new Date(updatedAtMin) : minDateTime;
        break;
    }

    const dialogRef = this.dialogService.open(
      timeStampTempRef,
      {
        data: {
          name: event?.detail,
          currentValue: currentValue,
          newValue: currentValue,
          minDateTime: minDateTime,
          maxDateTime: maxDateTime,
        },
      },
      this.viewContainerRef
    );

    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        let body: any = {
          event_id: event?.id,
          user_provided_timestamp: new Date(
            value?.newValue || value?.currentValue
          ),
          change_status_time_stamp: 1,
        };

        this.updateData(body);
      }
    });
  }

  onMcSearch(event: any) {
    this.mcInputValue = event?.target?.value;
    if (event?.target?.value.length > 2) {
      this.getMonitoringCompanyList({ search_str: event?.target?.value });
    }
  }

  getMonitoringCompanyList(params?: any) {
    this.clientService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.monitoringCompanyList = res['data'];
        }
      });
  }
  modifyMonitoringCompany(requestRef: any) {
    this.getMonitoringCompanyList();
    this.mcInputValue = this.detailData?.monitoring_company?.company_name;
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: {},
      },
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe(() => {});
  }
  addMC(monitoringCompany: any) {
    if (monitoringCompany.key === 0) {
      this.openAddMonitoringCompany();
    }
    this.updateData({ monitoring_company_id: monitoringCompany?.id });
    this.dialogRef.close();
  }
  openAddMonitoringCompany() {
    if (this.mcInputValue?.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createMonitoringCompany({
          company_name: String(this.mcInputValue),
        })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.addMC(response?.data);
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Monitoring Company value can't be empty",
        successMessage: '',
      });
    }
  }
  onOpenFile(file: any) {
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogRef = this.dialogService.open(ImagePreviewComponent, {
        data: { imageSrc: file?.file_url },
      });
    }
  }

  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;
    a.download = `${file?.name}${file?.extension}`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  updateJobId() {
    this.updateData({ external_job_id: this.externalNewJobId });
  }

  modifyExternalJobId(template: any) {
    this.externalNewJobId = this.detailData?.ext_job_id;
    this.externalOldJobId = this.detailData?.ext_job_id;
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe(() => {});
  }

  getImgSrc(file: File): SafeUrl {
    let url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  clearIntervalTimeoutFunction() {
    if (this.onsiteTimeOutFn) {
      clearTimeout(this.onsiteTimeOutFn);
    }
  }
  ngOnDestroy() {
    if (this.webSocketSubscription) this.webSocketSubscription.unsubscribe();
    this.isLargeScreenSubscription.unsubscribe();
    this.clearIntervalTimeoutFunction();
    if (this.etaMinuteTimerInterval) {
      clearInterval(this.etaMinuteTimerInterval);
    }
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandler
    );
  }

  resyncTimer() {
    this.getTimerValue();
    this.onsiteTimeOutFn = setTimeout(() => {
      this.resyncTimer();
    }, 1000 * 180);
  }

  startCountup() {
    this.clearIntervalTimeoutFunction();
    this.onsiteTime = this.getOnsiteTime();
    this.ngZone.run(() => {
      this.resyncTimer();
      this.onsiteIntervalFn = setInterval(() => {
        this.getTimerValue();
      }, 1000);
    });
  }
  getTimerValue() {
    if (!this.onsiteTime) {
      this.onsiteTime = this.getOnsiteTime();
    }
    let diffInTime = new Date().getTime() - this.onsiteTime;
    let seconds = Math.floor(diffInTime / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    this.onsiteTimer = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(
      remainingSeconds
    )}`;
  }
  getOnsiteTime() {
    let onsiteTime =
      this.detailData.onsite_time || this.detailData?.on_site_time;
    return new Date(onsiteTime).getTime();
  }

  pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  getTimeDifference() {
    let startTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();
    let endTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();

    if (this.detailData?.roster_scheduler?.repeat_type == 'once') {
      startTime = new Date(
        this.detailData?.roster_scheduler?.start_day +
          ' ' +
          this.detailData?.roster_scheduler?.start_time
      );
      endTime = new Date(
        this.detailData?.roster_scheduler?.end_day +
          ' ' +
          this.detailData?.roster_scheduler?.end_time
      );
    } else {
      if (
        this.detailData?.roster_scheduler?.start_time >
        this.detailData?.roster_scheduler?.end_time
      ) {
        endTime.setDate(startTime.getDate() + 1);
      }
      this.setTime(startTime, this.detailData?.roster_scheduler?.start_time);
      this.setTime(endTime, this.detailData?.roster_scheduler?.end_time);
    }

    this.scheduleDuration = this.getTimeDiff(startTime, endTime);

    this.scheduleCheckFn(startTime, endTime);
  }

  setTime(date: Date, time: string) {
    const [hours, minutes] = time?.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
  }

  getTimeDiff(startTime: any, endTime: any) {
    const maxStartTime = Math.max(startTime?.getTime(), new Date().getTime());
    const diffValue = endTime?.getTime() - maxStartTime;
    var minuteDifference = diffValue / (1000 * 60);
    [this.intervalData, this.welfareReminderDict] =
      getWelfareCheckInterval(minuteDifference);

    let diffInTime = endTime?.getTime() - startTime?.getTime();

    if (!diffInTime) return '00:00';
    let seconds = Math.floor(diffInTime / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);

    return `${this.pad(hours)}:${this.pad(minutes)}`;
  }
  openIntervalCheckTemplate(template: any) {
    this.dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
  }
  updateInterval(interval: Number) {
    this.updateData({ interval_check: interval });
  }
  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  scheduleCheckFn(startDatetime: any, endDateTime: any) {
    const isAdminORDispatcher = this.isAdmin || this.isDispatchUser;

    this.canDeclineJob =
      !isAdminORDispatcher &&
      new Date().getTime() + 3600000 * 24 < new Date(startDatetime).getTime();
    // guards can Decline Shift if its after 24 hrs https://app.shortcut.com/sendem/story/5680/guards-can-decline-shifts
    this.scheduleCheck =
      !this.detailData?.roster_scheduler?.repeat_type || isAdminORDispatcher;
    if (!this.scheduleCheck) {
      const today = this.formatDateWithoutTime(new Date());

      this.ngZone.run(() => {
        this.scheduleCheck =
          this.formatDateWithoutTime(startDatetime) <= today &&
          this.formatDateWithoutTime(endDateTime) >= today;
      });
    }
  }

  sheduleIfChecks() {
    const now = new Date();

    const startTime = new Date(
      this.detailData.roster_scheduler.start_day +
        ' ' +
        this.detailData.roster_scheduler.start_time
    );
    const endTime = new Date(
      this.detailData.roster_scheduler.end_day +
        ' ' +
        this.detailData.roster_scheduler.end_time
    );

    const canUpdateSchedule =
      endTime.getTime() > now.getTime() &&
      (!this.detailData?.schedule_event_date ||
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) >= this.formatDateWithoutTime(now));

    if (!(this.isAdmin || this.isDispatchUser)) {
      this.canScheduleBeUpdated = false;
      this.showWelfareItem = canUpdateSchedule;

      return;
    }

    this.canScheduleBeUpdated = canUpdateSchedule;

    this.showWelfareItem = this.canScheduleBeUpdated;

    this.hasScheduleStarted = startTime.getTime() < new Date().getTime();
  }
  startEndTimeComparison() {
    let startTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.start_time
    );
    let endTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.end_time
    );

    return startTime.getTime() > endTime.getTime();
  }
  openTemplate(template: any) {
    this.dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== false && typeof value === 'string') {
        let body: any = {
          change_eta: 1,
          eta: this.checkAndModifyETA(value),
          reason: this.etaChangeReason,
        };

        this.updateData(body);
      }
    });
  }
  openFenceAlertTemplate(template: any, seeRadiusOnly = false) {
    this.dialogService.open(
      template,
      { data: { seeRadiusOnly: seeRadiusOnly } },
      this.viewContainerRef
    );
  }
  updateMinDistance(event: any) {
    this.geoAlertDistance = event;
  }
  updateGeoFenceAlert() {
    let body: any = {
      change_geofence: 1,
      geofence_distance: this.geoAlertDistance,
    };

    this.updateData(body);
  }

  openScheduleTemplate(template: any) {
    if (this.isAdmin === true || this.isDispatchUser === true) {
      this.schedulerForm.patchValue(this.detailData?.roster_scheduler);
      if (
        (this.detailData?.roster_scheduler?.repeat_type === 'once' &&
          this.hasScheduleStarted) ||
        (this.hasScheduleStarted &&
          this.formatDateWithoutTime(
            new Date(this.detailData?.schedule_event_date)
          ) == this.formatDateWithoutTime(new Date()))
      ) {
        this.validEndDate = getFormattedDateTime();
        let endDate = new Date(
          this.schedulerForm.value.end_day +
            ' ' +
            this.schedulerForm.value.end_time
        );
        if (this.detailData?.roster_scheduler?.repeat_type !== 'once') {
          endDate = this.detailData?.schedule_event_date
            ? new Date(
                this.detailData?.schedule_event_date +
                  ' ' +
                  this.schedulerForm.value.end_time
              )
            : new Date();
          if (this.startEndTimeComparison()) {
            endDate.setDate(endDate.getDate() + 1);
          }
          this.schedulerForm.controls['start_day'].setValue(
            this.detailData?.schedule_event_date
              ? new Date(this.detailData?.schedule_event_date)
              : new Date()
          );

          this.schedulerForm.controls['end_day'].setValue(endDate);
        }

        this.endDateTime = getFormattedDateTime(endDate);
        this.schedulerForm.controls['repeat_type'].setValue('once');
        this.dialogRef = this.dialogService.open(
          template,
          {},
          this.viewContainerRef
        );
      } else {
        this.dialogRef = this.dialogService.open(SchedulerComponent, {
          data: {
            schedulerForm: this.schedulerForm,
            editSchedule: true,
            repeatAllowed:
              this.detailData?.roster_scheduler?.repeat_type != 'once',
          },
        });
        this.dialogRef.afterClosed().subscribe((value: any) => {
          if (value) {
            this.schedulerForm.patchValue(value);
            const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
              data: {
                title: 'Are you sure?',
              },
            });
            dialogRef.afterClosed().subscribe((dialogResponse) => {
              if (dialogResponse === true) {
                this.updateSchedule();
              }
            });
          }
        });
      }
    }
  }

  getUpdateScheduleParams() {
    let params: any = {};

    let paramKey: any = this.hasScheduleStarted
      ? 'started_schedule'
      : 'future_schedule';
    if (
      this.formatDateWithoutTime(
        new Date(this.detailData?.schedule_event_date)
      ) == this.formatDateWithoutTime(new Date()) &&
      this.hasScheduleStarted &&
      this.detailData?.roster_scheduler?.repeat_type !== 'once'
    ) {
      paramKey = 'started_job';
    }
    params[paramKey] = 1;
    return params;
  }
  formatScheduleForm(form: any) {
    form.value.start_day =
      form.value.start_day == ''
        ? ''
        : formatDate(form.value.start_day, 'yyyy-MM-dd', 'en');
    form.value.end_day =
      form.value.end_day == null
        ? null
        : formatDate(form.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();
    return {
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...form.value,
    };
  }
  updateSchedule() {
    this.spinnerService.show();

    let data: any = {
      job_id: this.detailData?.id,
      time_offset: new Date().getTimezoneOffset(),
      ...((this.detailData?.roster_scheduler?.repeat_type === 'once' &&
        this.hasScheduleStarted) ||
      (this.hasScheduleStarted &&
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) == this.formatDateWithoutTime(new Date()))
        ? this.formatScheduleForm(this.schedulerForm)
        : this.schedulerForm.value),
    };
    this.rsService
      .updateRosterSchedule(
        this.detailData?.roster_scheduler?.id,
        data,
        this.getUpdateScheduleParams()
      )
      .then((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          if (
            this.detailData?.roster_scheduler?.repeat_type === 'once' &&
            this.hasScheduleStarted
          )
            this.getJobDetails();
          else {
            this.router.navigate(['/roster']);
          }
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  filterStatuses(statusArray: any, idArrays: any) {
    return statusArray?.filter((status: any) => {
      for (const idArray of idArrays) {
        if (idArray && idArray.includes(status.status.id)) {
          return true;
        }
      }

      return false;
    });
  }

  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID

    const bottomSectionFixed = document.getElementById('bottom-section-fixed');

    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() + 'px';
    }
    setTimeout(() => {
      // added this becuase review status is taking some time to load
      const bottomSectionFixed = document.getElementById(
        'bottom-section-fixed'
      );

      if (bottomSectionFixed) {
        this.bottomSectionFixedHeight =
          bottomSectionFixed.offsetHeight.toString() + 'px';
      }
    }, 1000);
  }

  changeUserProvidedTime(event: any) {
    const selectedTime = new Date(event?.target.value);
    this.jobStatusTimeChange.valid = false;
    if (selectedTime.getTime() > new Date().getTime()) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Cannot set future date',
      });
    } else if (
      selectedTime.getTime() <
      new Date(this.jobStatusTimeChange?.created_at).getTime()
    ) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Date must be greater than job created date',
      });
    } else {
      this.jobStatusTimeChange.valid = true;
      this.dateTimeValue = getFormattedDateTime(selectedTime);
    }
  }
  async getAllIndexedDbItems() {
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const data = await gTDB.gtSavedData.toArray();
          resolve(data);
        }, 500);
      } catch (error) {
        resolve([]);
      }
    });
  }
  deleteCachedData(keys: []) {
    gTDB.gtSavedData.bulkDelete(keys);
  }
  assigneeUpdateDisabling() {
    // If no assignees are selected, disable the button
    if (!this.selectedAssignees?.length) {
      return true;
    }

    const hasChanges = !(
      this.selectedAssignees.length === this.detailData?.assignee?.length &&
      this.selectedAssignees.every((selectedAssignee: any) =>
        this.detailData?.assignee.some(
          (originalAssignee: any) => originalAssignee.id === selectedAssignee.id
        )
      )
    );

    // Return true if no changes are detected, otherwise return false to enable the button
    return !hasChanges;
  }

  updateAssignee() {
    this.selectedAssigneesToAdd.forEach((assignee: any) => {
      this.updateData({ new_assignee: assignee.id }, false);
    });

    this.selectedAssigneesToRemove.forEach((assignee: any) => {
      this.updateData({ remove_assignee: assignee.id }, false);
    });
    this.selectedAssigneesToAdd = [];
    this.selectedAssigneesToRemove = [];
    this.selectedAssignees = [];
    this.toasterService.setMessage({
      successMessage: 'Assignee Updated!',
      errorMessage: '',
    });
    this.dialogRef?.close();
  }
  updateEtaTime(eta: any) {
    if (eta?.value) {
      let currentDateTime = new Date();
      currentDateTime.setMinutes(currentDateTime.getMinutes() + eta?.value);
      this.etaValue = getFormattedDateTime(currentDateTime);
    } else {
      if (!eta?.target?.value) {
        let currentDateTime = new Date();
        this.etaValue = getFormattedDateTime(currentDateTime);
      }
    }

    if (this.etaChangeReasonFieldValid)
      this.etaChangeReasonFieldValid = Boolean(this.etaChangeReason);
  }
  clearAndFocusTextBox(elementId: string) {
    this.newComment = '';
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 100);
  }
  getDeviceID() {
    this.loggedInDeviceID = localStorage.getItem(btoa('loggedInDeviceID'));
    if (!this.loggedInDeviceID) {
      this.authService.checkDevice({ get_device_id: 1 }).then((resp: any) => {
        this.spinnerService.hide();
        if (resp?.device_id) {
          this.loggedInDeviceID = resp?.device_id;
          localStorage.setItem(btoa('loggedInDeviceID'), resp?.device_id);
        }
      });
    }
  }
  deleteRestoreUserUpdate(event: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: event?.type === 'delete' ? 'Delete Event' : 'Restore Event',
        message:
          event?.type === 'delete'
            ? `Are you sure you want to delete this event?`
            : 'Are you sure you want to restore this event?',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();
        let body: any = {
          id: event?.event?.id,
          job_id: this.detailData?.id,
        };
        body[event?.type === 'delete' ? 'delete_update' : 'restore_update'] = 1;

        this.jobService.jobUpdate(body).then((response: any) => {
          this.clearInfo();
          this.formatHistory();
          if (this.offlineMode && response?.status == 'success') {
            this.toasterService.setMessage({
              successMessage: 'Action Queued',
              errorMessage: '',
            });
          }

          if (response?.data) {
            this.detailData = response['data'];
            this.selectedEvent = null;
            this.formatData();
          }
          if (response?.status === 'failure') {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
          }

          this.spinnerService.hide();
        });
      }
    });
  }

  isItemNotShownInReport(eventData: any) {
    return ![0, 1, 7, 8, 19, 20].includes(eventData?.event_action);
  }
  jobEventUpdateCheck(selectedEvent: any) {
    return (
      (this.subscriberUserId == selectedEvent?.subs_user_id ||
        this.isAdmin ||
        this.isDispatchUser) &&
      ![1, 2, 3, 10, 11, 12, 13].includes(
        this.detailData?.status?.status_identity?.value
      ) &&
      !this.offlineMode &&
      [7, 8, 19].includes(selectedEvent?.event_action)
    );
  }
  onActionIconClick(event: any, requestRef: TemplateRef<any>) {
    if (event?.type === 'edit') {
      this.editComment(event?.event, requestRef);
    } else {
      this.deleteRestoreUserUpdate(event);
    }
  }
  getResponseTypes(params?: any) {
    this.clientService.fetchResponseTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.responseTypeList = res['data'];

        this.initalResponseTypeList = JSON.parse(JSON.stringify(res?.data));
      }
    });
  }
  openUpdateResponseTypeTemplate(template: TemplateRef<any>) {
    this.getResponseTypes();
    this.previousResponseTypeValue = this.detailData?.response_type?.name;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  onSelectResponseType(event: any) {
    if (event?.key === 0) {
      this.openAddResponseTypeForm();
    }
    if (event?.id) {
      let body: any = {
        response_type_id: event?.id,
      };

      this.updateData(body);
    }
  }
  onSearchResponseType(event: any) {
    this.previousResponseTypeValue = event.target.value;
    if (event.target.value.length) {
      this.responseTypeList = this.initalResponseTypeList?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    } else {
      this.responseTypeList = this.initalResponseTypeList;
    }
  }

  openAddResponseTypeForm() {
    if (this.previousResponseTypeValue.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createResponseType({
          name: String(this.previousResponseTypeValue),
        })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.onSelectResponseType(response?.data);
            this.getResponseTypes();
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Response Type Name can't be empty",
        successMessage: '',
      });
    }
  }
  editField(field: string) {
    if (field == 'rt') {
      this.previousResponseTypeValue = null;
      this.responseTypeList = this.initalResponseTypeList;
      this.shiftFocus('rtId');
    }
    if (field == 'st') {
      this.previousServiceTypeValue = null;
      this.serviceTypeListData = this.initalServiceTypeListData;
      this.shiftFocus('stId');
    }
    if (field == 'mc') {
      this.mcInputValue = null;
      this.getMonitoringCompanyList();
      this.shiftFocus('mcId');
    }
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  openUpdateServiceTypeTemplate(template: TemplateRef<any>) {
    this.getServiceTypes();
    this.previousServiceTypeValue = this.detailData?.job_service_type?.name;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  onSTSearch(event: any) {
    this.previousServiceTypeValue = event.target.value;

    if (event.target.value.length) {
      this.serviceTypeListData = this.initalServiceTypeListData?.filter(
        (type: any) =>
          type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    } else {
      this.serviceTypeListData = this.initalServiceTypeListData;
    }
  }

  getServiceTypes(params?: any) {
    this.clientService.fetchServiceTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.serviceTypeListData = res['data'];

        this.initalServiceTypeListData = JSON.parse(JSON.stringify(res?.data));
      }
    });
  }
  onServiceTypeSelect(sType: any) {
    if (sType.key === 0) {
      this.openAddServiceType();
    }
    if (sType?.id) {
      let body: any = {
        service_type_id: sType?.id,
      };

      this.updateData(body);
    }
  }
  openAddServiceType() {
    if (this.previousServiceTypeValue.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createServiceType({ name: String(this.previousServiceTypeValue) })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.onServiceTypeSelect(response?.data);

            this.getServiceTypes();
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Service Type value can't be empty",
        successMessage: '',
      });
    }
  }
  declineJob() {
    this.updateData({
      decline_job: this.subscriberUserId,
      decline_reason: this.declineReason,
    });
  }
  openJobDeclineTemplate(template: any) {
    this.declineReason = '';
    this.dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
  }
  redirectToSite(route = 'site') {
    if (route === 'site') {
      this.router.navigate(
        ['/view-site', { sKey: String(this.detailData?.sites[0]?.id) }],
        { fragment: 'jc' }
      );
    } else {
      this.router.navigate(
        ['/view-client', { cKey: String(this.detailData?.company?.id) }],
        { fragment: 'jc' }
      );
    }
  }
  selectUnselectRecipients(contact: any) {
    contact.isSnubbed = !contact?.isSnubbed;

    this.selectedReportRecipientList = [
      ...new Set(
        this.reportRecipientList
          ?.filter((recipient: any) => !recipient?.isSnubbed)
          ?.map((recipient: any) => recipient?.id)
      ),
    ];
  }
}
