import { Location } from '@angular/common';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isLargeScreen } from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { AddEditSiteComponent } from '../../../../shared/components/add-edit-site/add-edit-site.component';
import { ClientSiteContactsComponent } from '../../../../shared/components/client-site-contacts/client-site-contacts.component';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../../../../shared/components/contact/contact.component';
import { EventSchedulerComponent } from '../../../../shared/components/event-scheduler/event-scheduler.component';
import { ImagePreviewComponent } from '../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ClientsService } from '../../clients/clients.service';
import { companyTableDetails } from '../../tableDetails';
@Component({
  selector: 'gtapp-view-site',
  templateUrl: './view-site.component.html',
  styleUrl: './view-site.component.scss',
})
export class ViewSiteComponent implements OnInit {
  selectedSite: any;
  isAdmin = this.dataCheckService.isUserAdmin();
  isDispatchUser = this.dataCheckService.isDispatchUser();
  selectedSiteFiles: any = [];
  selectedSiteKeys: any = [];

  siteId: string = '';

  dialogRef: any;
  fileUploadError: any;
  fileDescription: any;
  uploadFile: any;
  siteContactListTemp: any = [];
  newInfromation: any;
  siteKeyList: any = [];
  mapLatLong: any;
  siteReportUsers: any = [];

  slicedSiteReportUsers: any = [];
  slicedSelectedSiteFiles: any = [];
  selectedUsers: any = [];
  patrolReportSchedules: any = [];

  userEventDetailsTableView = companyTableDetails?.desktopTable;
  selectedEvent: any;
  largeView: Boolean = isLargeScreen;
  constructor(
    private dialogService: ModelDialogueService,
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private _location: Location,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['sKey']) {
          this.siteId = params?.['sKey'];
          this.getSiteDetailById();
          const routeFragment: any = this.route.snapshot.fragment;
          if (['jc', 'pc'].includes(routeFragment)) {
            this.scrollToReportSection(`#${routeFragment}`);
          }
        }
      },
    });
  }

  ngOnInit(): void {}
  scrollToReportSection(containerId: any) {
    setTimeout(() => {
      const idElement = document.querySelector(containerId);

      if (idElement) {
        idElement?.scrollIntoView();
      }
    }, 200);
  }

  getSiteDetailById() {
    this.spinnerService.show();
    this.clientService.getSiteById(this.siteId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.formatSiteData(response);
      }
      this.spinnerService.hide();
    });
  }
  formatSiteData(response: any) {
    this.appService.initializeBootstrapTooltips();
    this.siteKeyList = [];
    this.selectedSite = response?.data;
    this.siteContactListTemp = this.selectedSite?.contacts || [];
    let gpscoords = {
      lat: this.selectedSite?.address?.latitude,
      lon: this.selectedSite?.address?.longitude,
    };
    this.mapLatLong = gpscoords;

    this.selectedSiteFiles = this.selectedSite?.site_files || [];
    this.slicedSelectedSiteFiles = this.selectedSiteFiles?.slice(0, 3);

    this.selectedSiteKeys = this.selectedSite?.site_formatted_keys || [];

    this.siteReportUsers = this.selectedSite?.report_users || [];
    this.slicedSiteReportUsers = this.siteReportUsers?.slice(0, 3);
    this.patrolReportSchedules =
      this.selectedSite?.patrol_report_schedules || [];

    this.siteKeyList = JSON.parse(JSON.stringify(this.selectedSiteKeys));
  }

  openSiteLocationContactTemplate() {
    if (this.isAdmin || this.isDispatchUser) {
      this.dialogRef = this.dialogService.open(ClientSiteContactsComponent, {
        data: {
          siteData: this.selectedSite,

          selectedUsers: this.selectedSite?.contacts,
          contactType: 'lc',
        },
      });
      this.dialogRef.afterClosed().subscribe((value: any) => {
        this.spinnerService.hide();
        this.getSiteDetailById();
      });
    }
  }
  onTableRowClick(event: any) {
    this.selectedEvent = event;
  }

  getSiteContacts() {
    this.spinnerService.show();
    this.clientService
      .getSiteContacts(this.selectedSite?.id)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.siteContactListTemp = response['data'];
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }
  primaryContact(contact: any) {
    this.clientService
      .editLocationContact(contact?.id)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.getSiteContacts();
        }
      });
  }

  editSite(site: any, editSiteName = true) {
    if (this.isAdmin || this.isDispatchUser) {
      const addSiteDialogRef = this.dialogService.open(AddEditSiteComponent, {
        data: { detailData: site, editSite: true, editSiteName: editSiteName },
      });
      addSiteDialogRef.afterClosed().subscribe((value) => {
        if (value !== 'close') {
          this.getSiteDetailById();
        }
      });
    }
  }
  openJobView() {
    if (this.selectedSite?.site_stats?.job_count > 0) {
      this.router.navigate(['/jobs', { siteId: this.siteId }]);
    } else {
      this.router.navigate(['/add-job']);
    }

    sessionStorage.setItem(
      'fromSite',
      JSON.stringify({
        site: {
          id: this.siteId,
          company_name: this.selectedSite?.company_name,
        },
        client: {
          id: this.selectedSite?.company?.id,
          company_name: this.selectedSite?.company?.company_name,
        },
      })
    );
  }
  openCPView() {
    if (this.selectedSite?.site_stats?.checkpoint_count > 0) {
      this.router.navigate(['/checkpoints', { siteId: this.siteId }]);
    } else {
      this.router.navigate(['/add-checkpoint']);
    }
    sessionStorage.setItem(
      'fromSite',
      JSON.stringify({
        site: {
          id: this.siteId,
          company_name: this.selectedSite?.company_name,
        },
        client: {
          id: this.selectedSite?.company?.id,
          company_name: this.selectedSite?.company?.company_name,
        },
      })
    );
  }
  redirectToClientPage() {
    this.router.navigate([
      '/view-client',
      { cKey: String(this.selectedSite?.company?.id) },
    ]);
  }

  openJobReportContactTemplate() {
    this.selectedUsers = [];

    this.selectedSite?.report_users?.forEach((item: any) => {
      this.selectedUsers.push(item);
    });

    if (this.isAdmin || this.isDispatchUser) {
      const dialogRef = this.dialogService.open(ClientSiteContactsComponent, {
        data: {
          siteData: this.selectedSite,
          selectedUsers: this.selectedUsers,
        },
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        this.getSiteDetailById();
      });
    }
  }

  openSiteContactTemplate(data: any, siteData: any, contactType: any) {
    this.dialogRef = this.dialogService.open(ContactComponent, {
      data: {
        contactData: data,

        siteData: siteData,
        contactType: contactType,
      },
    });
    this.dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse !== false) {
        this.getSiteDetailById();
      }
    });
  }

  openInformationTemplate(template: any) {
    this.newInfromation = this.selectedSite?.information;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: { siteInfo: true },
      },
      this.viewContainerRef
    );
  }
  openManagerInfoTemplate(template: any) {
    this.newInfromation = this.selectedSite?.manager_notes;
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openFileUploadTemplate(template: any) {
    this.fileUploadError = null;
    this.fileDescription = null;
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openSiteKeysTemplate(template: any) {
    this.siteKeyList = JSON.parse(JSON.stringify(this.selectedSiteKeys));

    this.siteKeyList.push({ isFocused: true });

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }

  async fileCheck(fileExtension: string, fileSize: number) {
    let fileLimit: number = 1024 * 1024 * 8; // 8MB
    return new Promise((resolve) => {
      if (!['pdf', 'jpeg', 'jpg', 'png'].includes(fileExtension)) {
        this.fileUploadError = 'Unsupported file format';
        resolve(false);
      } else if (fileSize > fileLimit) {
        this.fileUploadError = 'File Too Large ' + fileSize + ' / ' + fileLimit;
        resolve(false);
      } else {
        this.fileUploadError = null;
        resolve(true);
      }
    });
  }
  async handleFileInput(event: any) {
    let file = event?.target?.files.item(0);
    let fileSize: number = file.size;
    let fileName: string = file.name;
    let fileExtension: string = fileName.split('.').pop() || '';
    let fileCheck = await this.fileCheck(fileExtension, fileSize);
    if (fileCheck) {
      this.uploadFile = event?.target?.files.item(0);
    }
  }

  onUploadFile() {
    if (this.uploadFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.uploadFile);
      fileData.append('upload_files', '1');
      fileData.append('description', this.fileDescription);

      this.appService
        .formDataApi(`site/${this.selectedSite?.id}/update`, fileData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.formatSiteData(response);
            this.fileUploadError = null;
            this.fileDescription = null;
            this.uploadFile = null;
            this.spinnerService.hide();
          } else {
            this.fileUploadError = null;
            this.fileDescription = null;
            this.uploadFile = null;
            this.spinnerService.hide();
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  onOpenFile(file: any) {
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogRef = this.dialogService.open(
        ImagePreviewComponent,
        {
          data: { imageSrc: file?.file_url },
        },
        this.viewContainerRef
      );
    }
  }
  openSchedulerTemplate(schedule?: any) {
    this.dialogRef = this.dialogService.open(EventSchedulerComponent, {
      data: { siteData: this.selectedSite, scheduleData: schedule },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        this.formatSiteData(value);
      }
    });
  }

  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;
    a.download = `${file?.name}${file?.extension}`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  deleteSiteFile(file: any) {
    this.clientService
      .updateSite(this.selectedSite?.id, {
        file_id: file?.id,
        delete_file: 1,
      })
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }

  deleteFile(file: any) {
    let dialogMsg = `Permanently delete file: ${file.description}`;
    this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete File`,
        message: dialogMsg,
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();

        this.deleteSiteFile(file);
      }
    });
  }

  updateSiteInformation() {
    this.spinnerService.show();
    this.clientService
      .updateSite(
        this.selectedSite?.id,
        {
          information: this.newInfromation,
        },
        { update_information: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);

      element?.focus();
    }, time);
  }
  addNewSiteKeyField(index: number) {
    this.siteKeyList = this.siteKeyList?.filter(
      (element: any) => element?.value
    );
    this.siteKeyList.forEach((element: any) => (element.isFocused = false));
    this.siteKeyList.push({ isFocused: true });
    this.shiftFocus(`siteKey${index}`, 500);
  }
  deleteSiteKeyField(index: number) {
    this.siteKeyList.splice(index, 1);
    if (!this.siteKeyList?.length) {
      this.siteKeyList.push({});
    }
  }
  updateSiteKeys() {
    this.spinnerService.show();
    this.clientService
      .updateSite(
        this.selectedSite?.id,
        {
          site_keys: this.siteKeyList
            ?.map((item: any) => {
              const { description, value } = item;

              return { description, value };
            })
            ?.filter((item: any) => item?.value),
        },
        { update_site_keys: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  updateSiteManagerNotes() {
    this.spinnerService.show();
    this.clientService
      .updateSite(
        this.selectedSite?.id,
        {
          manager_notes: this.newInfromation,
        },
        { update_manager_notes: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }

  selectFile() {
    var button: any = document.getElementById('fileInput');
    button.click();
  }
  deleteSite(site: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete Site`,
        message: `Checkpoints belonging to deleted sites will no longer be available.`,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .enableDisableSiteById(site?.id)
          .then((response: any) => {
            if (response?.status == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this._location.back();
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  onFocus(inputType: string, index: number) {
    const keyInput = document.getElementById(`Key${index}`);
    const descriptionInput = document.getElementById(`Description${index}`);

    if (inputType === 'Key') {
      keyInput?.classList.add('focused');
      descriptionInput?.classList.remove('focused');
    } else {
      descriptionInput?.classList.add('focused');
      keyInput?.classList.remove('focused');
    }
  }

  onBlur(inputType: string, index: number) {
    const keyInput = document.getElementById(`Key${index}`);
    const descriptionInput = document.getElementById(`Description${index}`);

    keyInput?.classList.remove('focused');
    descriptionInput?.classList.remove('focused');
  }
  reportSendApproval() {
    this.spinnerService.show();
    this.clientService
      .updateSite(this.selectedSite?.id, {}, { send_report_approval: 1 })
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
}
