<ng-container *ngIf="detailData?.id">
  <div
    class="row row-cols-2 mb-3 row-cols-lg-4"
    *ngIf="isAdmin || isDispatchUser"
  >
    <div class="col">
      <button
        class="btn btn-md btn-outline-primary w-100"
        (click)="moveJobBack()"
        [disabled]="!lastStatus?.status?.id"
      >
        Reopen Job
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-md btn-success w-100"
        (click)="approveJob(true, reportConfirmationTemplate)"
      >
        Send Report
      </button>
    </div>
  </div>
</ng-container>

<div class="mb-2 text-end report-preview" *ngIf="isAdmin || isDispatchUser">
  <button
    (click)="
      updateData({ hide_header: !detailData?.additional_info?.hide_header })
    "
    class="btn btn-sm btn-primary"
  >
    {{
      detailData?.additional_info?.hide_header ? "Show Header" : "Hide Header"
    }}
  </button>
</div>

<div class="p-lg-5 p-2 report-preview shadow bg-white text-black">
  <ng-container *ngIf="detailData?.id">
    <div
      class="header-container"
      *ngIf="!detailData?.additional_info?.hide_header"
    >
      <div class="subscriber_company_information_section">
        <div class="strong">
          {{ job_details?.subscriber_info?.name }}
        </div>

        <div>{{ job_details?.subscriber_info?.address }}</div>
        <div>{{ job_details?.subscriber_info?.city }}</div>

        <div *ngIf="job_details?.subscriber_info?.mobile_number">
          Ph: {{ job_details?.subscriber_info?.mobile_number }}
        </div>

        <div>{{ job_details?.subscriber_info?.email }}</div>
      </div>

      <div
        class="subscriber_logo"
        *ngIf="job_details?.subscriber_info?.logo_url"
      >
        <img
          class="logo_img"
          alt="placeholder_logo"
          src="{{ job_details?.subscriber_info?.logo_url }}"
        />
      </div>
    </div>

    <div class="report-type-heading">Job Completion Report</div>
    <!--CLIENT INFORMATION SECTION-->
    <div class="client_information_section">
      <div>{{ job_details?.client }}</div>
      <div>{{ job_details?.address }}</div>
      <div>{{ job_details?.city }}</div>
    </div>
    <div class="patrol-table mt-5">
      <div
        style="
          font-size: 10px;
          padding-bottom: 0.5rem;
          color: #333;
          font-weight: bold;
          font-style: italic;
        "
        *ngIf="job_details?.time_zone && job_details?.time_zone_diff"
      >
        All timestamps are shown in {{ job_details?.time_zone }} time
        {{ job_details?.time_zone_diff }}
      </div>
      <table style="width: 100%; max-width: 100%" class="responsive">
        <tr>
          <td class="cell-heading">Date</td>
          <td colspan="3">{{ job_details?.report_date }}</td>
        </tr>
        @if(largeView){
        <tr>
          <td class="cell-heading">Job ID</td>
          <td>{{ job_details?.job_key }}</td>
          <ng-container *ngIf="detailData?.service_type?.type_identity === 0">
            <td class="cell-heading">External Job ID</td>
            <td>
              {{ job_details?.ext_job_id }}
              <span
                *ngIf="isAdmin || isDispatchUser"
                (click)="modifyExternalJobId(modifyExternalJobIdTemplate)"
                class="ms-1"
              >
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i>
              </span>
            </td>
          </ng-container>
        </tr>

        <tr>
          <td class="cell-heading">Job Type</td>
          <td>
            @if(job_details?.response_type){
            {{ job_details?.type }} -
            <span class="fw-semibold">{{ job_details?.response_type }}</span>
            } @else {
            {{ job_details?.type }}
            }
            <span
              *ngIf="
                [0, 1].includes(detailData?.service_type?.type_identity) &&
                (isAdmin || isDispatchUser)
              "
              class="ms-1"
              (click)="
                detailData?.service_type?.type_identity === 0
                  ? openUpdateResponseTypeTemplate(responseTypeTemplateRef)
                  : openUpdateServiceTypeTemplate(serviceTypeTemplateRef)
              "
            >
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i>
            </span>
          </td>
          <ng-container *ngIf="detailData?.service_type?.type_identity === 0">
            <td class="cell-heading">Monitoring Company</td>
            <td>
              {{ job_details?.monitoring_company }}
              <span
                *ngIf="isAdmin || isDispatchUser"
                class="ms-1"
                (click)="modifyMonitoringCompany(modifyMCTemplate)"
              >
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i>
              </span>
            </td>
          </ng-container>
        </tr>

        }@else {
        <tr>
          <td class="cell-heading">Job ID</td>
          <td>{{ job_details?.job_key }}</td>
        </tr>
        <tr *ngIf="detailData?.service_type?.type_identity === 0">
          <td class="cell-heading">
            <div>
              <div class="flex-grow-1">Ext. Job ID</div>
            </div>
          </td>
          <td>
            {{ job_details?.ext_job_id }}
            <span
              *ngIf="isAdmin || isDispatchUser"
              (click)="modifyExternalJobId(modifyExternalJobIdTemplate)"
              class="ms-1"
            >
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i>
            </span>
          </td>
        </tr>

        <tr>
          <td class="cell-heading">Job Type</td>
          <td>
            {{ job_details?.type }}
            {{ job_details?.response_type }}
            <span
              *ngIf="
                [0, 1].includes(detailData?.service_type?.type_identity) &&
                (isAdmin || isDispatchUser)
              "
              class="ms-1"
              (click)="
                detailData?.service_type?.type_identity === 0
                  ? openUpdateResponseTypeTemplate(responseTypeTemplateRef)
                  : openUpdateServiceTypeTemplate(serviceTypeTemplateRef)
              "
            >
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i>
            </span>
          </td>
        </tr>
        <tr *ngIf="detailData?.service_type?.type_identity === 0">
          <td class="cell-heading">Monitoring Company</td>
          <td>
            {{ job_details?.monitoring_company }}
            <span
              *ngIf="isAdmin || isDispatchUser"
              class="ms-1"
              (click)="modifyMonitoringCompany(modifyMCTemplate)"
            >
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i>
            </span>
          </td>
        </tr>

        }

        <tr>
          <td class="cell-heading">Location</td>

          <td colspan="3" *ngFor="let item of job_details?.site_address">
            <span class="site-name">{{ item?.site_name }}</span> &emsp;
            {{ item?.address }} {{ item?.city }}
          </td>
        </tr>

        <tr *ngFor="let item of statusTimeStamps">
          <td class="cell-heading">
            {{ item?.value }}
          </td>
          <td colspan="3" class="status-change">
            {{
              (item?.user_provided_timestamp
                ? item?.user_provided_timestamp
                : item?.updated_at
              ) | date : "dd MMM yyyy HH:mm"
            }}
            <span
              *ngIf="isAdmin || isDispatchUser"
              (click)="editTimeStamp(item, statusTimeStampUpdateTemplateRef)"
              class="ms-1"
            >
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i>
            </span>
          </td>
        </tr>

        <tr>
          <td class="cell-heading line-height">Job Details</td>
          <td colspan="3">
            <div class="d-flex">
              <div class="pe-2">
                <pre class="multi-line line-height fs-6">{{
                  job_details?.additional_info
                }}</pre>
              </div>
              <div>
                <span
                  *ngIf="isAdmin || isDispatchUser"
                  (click)="modifyRequestDetails(requestRef)"
                >
                  <i
                    class="fa-solid fa-pen-to-square md-font-size text-secondary"
                  ></i>
                </span>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!-- REPORT SUMMARY -->
    <div class="patrol-table mt-5">
      <div class="report-summary-heading mb-2">Report Summary</div>
      <table style="width: 100%; max-width: 100%">
        <thead>
          <tr>
            <th style="text-align: center">ID</th>
            <th>Time</th>
            <th>Event</th>
            <th style="border-left: hidden"></th>
            <th>Guard</th>
            <th *ngIf="isAdmin || isDispatchUser"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="
              page-break-inside: avoid !important;
              break-inside: avoid !important;
            "
            id="section_up{{ i + 1 }}"
            *ngFor="let row of event_data; let i = index"
          >
            <td
              [ngClass]="row?.is_deleted ? 'opacity-25' : ''"
              style="text-align: center"
              class="line-height"
            >
              {{ i + 1 }}
            </td>
            <td
              [ngClass]="row?.is_deleted ? 'opacity-25' : ''"
              class="line-height"
            >
              {{
                (row?.user_provided_timestamp
                  ? row?.user_provided_timestamp
                  : row?.updated_at
                ) | date : "dd MMM yyyy HH:mm"
              }}
            </td>
            <td
              [ngClass]="row?.is_deleted ? 'opacity-25' : ''"
              class="uppercase line-height"
            >
              @if(row?.file_url){
              <a
                class="cursor-pointer"
                (click)="scrollToSection($event, 'section_down' + (i + 1))"
                style="color: #00538d"
              >
                {{ row.action }}
              </a>
              }@else {
              {{ row.action }}
              }
            </td>
            <td
              [ngClass]="row?.is_deleted ? 'opacity-25' : ''"
              style="border-left: hidden"
            >
              <div class="d-flex">
                <pre class="multi-line line-height fs-6">{{ row.value }}</pre>
                <div *ngIf="isAdmin || isDispatchUser">
                  <div class="mx-2">
                    <span
                      (click)="
                        editEvent(
                          row,
                          commentRef,
                          statusTimeStampUpdateTemplateRef
                        )
                      "
                    >
                      <i
                        class="fa-solid fa-pen-to-square md-font-size text-secondary"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <td
              [ngClass]="row?.is_deleted ? 'opacity-25' : ''"
              class="line-height"
            >
              {{ row.event_by }}
            </td>
            <td *ngIf="isAdmin || isDispatchUser">
              <div>
                <div
                  class="ms-1"
                  *ngIf="[7, 8, 19].includes(row?.event_action)"
                  (click)="
                    deleteRestoreUserUpdate({
                      event: row,
                      type: row?.is_deleted ? 'restore' : 'delete'
                    })
                  "
                >
                  @if(row?.is_deleted){
                  <span>
                    <i
                      class="fa-solid fa-trash-can-arrow-up md-font-size text-secondary"
                    ></i>
                  </span>
                  }@else {
                  <span>
                    <i
                      class="fa-regular fa-trash-can md-font-size text-secondary"
                    ></i>
                  </span>
                  }
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- DETAILS SECTION -->
    <div
      class="mt-5"
      style="box-decoration-break: clone; -webkit-box-decoration-break: clone"
    >
      <ng-container *ngFor="let item of event_data; let i = index">
        <ng-container *ngIf="item?.file_url && !item?.is_deleted">
          <div id="section_down{{ i + 1 }}">
            <div class="d-flex mb-2">
              <div [ngClass]="largeView ? 'w-75' : 'w-50'">
                <img
                  src="{{ item?.file_url }}"
                  style="max-height: 100%; max-width: 100%; display: flex"
                />
              </div>

              <div [ngClass]="largeView ? 'w-25 p-2 small' : 'w-50 p-2 small'">
                <!--TIMESTAMP-->
                <div style="font-weight: 600" class="key-value">
                  {{ item?.updated_at | date : "dd MMM yyyy HH:mm" }}
                </div>
                <div
                  style="
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-weight: 600;
                  "
                  class="key-value"
                >
                  {{ item?.action }}
                </div>
                <ng-container *ngIf="item?.value">
                  <div class="gt-multi-line">
                    {{ item?.value }}
                  </div>
                </ng-container>

                <!-- GPS ACCURACY NOTIFICATION-->

                <div class="warning distance">
                  @if(item?.event_gps?.source == 1){ Location may not be
                  accurate. }@else if(item?.event_gps?.source == 2) { Location
                  is an approximation only. }
                </div>

                <!--SECTION: EVENT LOCATION-->

                <div
                  style="margin-top: 0.3rem; padding-right: 0; margin-right: 0"
                >
                  <div class="key-label">Event Location</div>
                  <div
                    style="max-width: 100%; height: 150px; object-fit: none"
                    class="small"
                  >
                    <gtapp-map-leaflet
                      [customMapConfig]="{ showSimpleStaticMap: true }"
                      [latLon]="{
                        markerTooltip: item?.event_by,
                        lat: item?.event_gps?.lat,
                        lon: item?.event_gps?.lon
                      }"
                      [mapId]="'map_' + i"
                    ></gtapp-map-leaflet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template
  #statusTimeStampUpdateTemplateRef
  let-statusTimeStampUpdateTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Update {{ data.name }} Time
      <span
        class="float-end"
        (click)="statusTimeStampUpdateTemplateRef.close(false)"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div
      class="small border border-1 text-secondary p-2 mb-2 rounded-2"
      *ngIf="data?.minDateTime || data?.maxDateTime"
    >
      {{ data.name }} time must be @if(data?.minDateTime && data?.maxDateTime){
      between {{ data?.minDateTime | customDate : "d MMM y HH:mm" }} &
      {{ data?.maxDateTime | customDate : "d MMM y HH:mm" }}
      }@else if ( data?.maxDateTime) {less than

      {{ data?.maxDateTime | customDate : "d MMM y HH:mm" }}} @else { greater
      than {{ data?.minDateTime | customDate : "d MMM y HH:mm" }}
      }
    </div>
    <div class="form-floating mb-3">
      <input
        type="datetime-local"
        class="form-control"
        placeholder="Select Time"
        [(ngModel)]="data.newValue"
        (change)="changeStatusTimeStamp($event, data)"
        [min]="data?.minDateTime"
        [max]="data?.maxDateTime"
      />
      <label>Select Time</label>
    </div>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button
          type="button"
          class="btn btn-dark btn-lg w-100"
          (click)="statusTimeStampUpdateTemplateRef.close(false)"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          (click)="statusTimeStampUpdateTemplateRef.close(data)"
          [disabled]="!data?.valid"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <div class="card dialog-box">
    <div class="card-header">
      {{ data?.value ? "Edit Comment" : "Add Comment" }}
      <span class="float-end" (click)="commentRef.close(); newComment = ''">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-group input-group">
        <textarea
          class="form-control"
          rows="5"
          wrap="hard"
          maxlength="340"
          placeholder="Enter comment here"
          autofocus
          [(ngModel)]="newComment"
          id="updateCommentBox"
        ></textarea>
        <span
          class="input-group-text"
          *ngIf="newComment"
          (click)="clearAndFocusTextBox('updateCommentBox')"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close(); newComment = ''"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            [disabled]="!newComment || previousComment === newComment"
            (click)="addComment(data); commentRef.close()"
          >
            {{ data?.value ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #requestRef let-data let-requestRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Additional Information
      <span class="float-end" (click)="requestRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <!-- Request Detail Section start-->

    <div class="form-group input-group mb-3">
      <textarea
        class="form-control"
        placeholder="Additional Information"
        cols="20"
        rows="5"
        wrap="hard"
        maxlength="900"
        type="text"
        [(ngModel)]="newRequest"
        id="aiField"
      ></textarea>
      <span
        class="input-group-text"
        *ngIf="newRequest"
        (click)="newRequest = ''; clearAndFocusTextBox('aiField')"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button
          type="button"
          class="btn btn-dark btn-lg w-100"
          (click)="requestRef.close()"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          [disabled]="job_details?.additional_info === newRequest"
          class="btn btn-primary btn-lg w-100"
          (click)="updateRequestDetails(); requestRef.close()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </div>
</ng-template>
<ng-template
  #modifyExternalJobIdTemplate
  let-modifyExternalJobIdTemplate="dialogRef"
>
  <div class="dialog-box card">
    <div class="card-header">
      External Job ID<span
        class="float-end"
        (click)="modifyExternalJobIdTemplate.close()"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <!-- Job Id Section start-->
    <div class="input-group">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          placeholder="External Job ID"
          [(ngModel)]="externalNewJobId"
          autocomplete="off"
          maxlength="32"
          id="eJIdField"
        />
        <label>External Job ID</label>
      </div>
      <span
        class="input-group-text"
        *ngIf="externalNewJobId"
        (click)="externalNewJobId = ''; clearAndFocusTextBox('eJIdField')"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="modifyExternalJobIdTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          [disabled]="externalOldJobId === externalNewJobId"
          (click)="updateJobId(); modifyExternalJobIdTemplate.close()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- UPDATE MONITORING COMPANY DIALOG -->
<ng-template #modifyMCTemplate let-modifyMCTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Monitoring Company
      <span class="float-end" (click)="modifyMCTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="mb-3">
      <gtapp-auto-complete
        [data]="monitoringCompanyList"
        placeHolder="Monitoring Company"
        searchKeyword="company_name"
        [itemTemplate]="MCTemplate"
        [initialValue]="mcInputValue"
        [addInitialKey]="isAdmin || isDispatchUser"
        (inputChanged)="onMcSearch($event)"
        (selected)="addMC($event)"
        [inputId]="'mcId'"
        [enableCloseOption]="true"
        (fieldCleared)="editField('mc')"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #MCTemplate let-item>
      @if (item?.key === 0) {
      <div>Add Monitoring Company</div>
      } @else {
      <div>
        {{ item.company_name }}
      </div>
      }
    </ng-template>
    <div class="row row-cols12 mt-2" *ngIf="detailData?.monitoring_company?.id">
      <div class="col">
        <button
          (click)="
            modifyMCTemplate.close();
            updateData({ monitoring_company_id: null })
          "
          class="btn btn-outline-danger btn-lg w-100"
        >
          Remove Monitoring Company
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- edit response type  DIALOG -->
<ng-template
  #responseTypeTemplateRef
  let-responseTypeTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Update Response Type

      <span class="float-end" (click)="responseTypeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="mb-3">
        <gtapp-auto-complete
          [data]="responseTypeList"
          [maxLength]="256"
          placeHolder="Search Response Type"
          searchKeyword="name"
          [initialValue]="previousResponseTypeValue"
          [itemTemplate]="responseTypeTemplate"
          [addInitialKey]="isAdmin || isDispatchUser"
          (inputChanged)="onSearchResponseType($event)"
          (selected)="onSelectResponseType($event)"
          [inputId]="'rtId'"
          [enableCloseOption]="true"
          (fieldCleared)="editField('rt')"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #responseTypeTemplate let-item>
        @if (item?.key === 0) {
        <div>Add New Response Type</div>
        } @else {

        <div>{{ item?.name }}</div>

        }
      </ng-template>

      <div class="row row-cols12 mt-2" *ngIf="detailData?.response_type?.id">
        <div class="col">
          <button
            (click)="
              responseTypeTemplateRef.close();
              updateData({ response_type_id: null })
            "
            class="btn btn-outline-danger btn-lg w-100"
          >
            Remove Response Type
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- edit service type  DIALOG -->
<ng-template
  #serviceTypeTemplateRef
  let-serviceTypeTemplateRef="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Update Service Type

      <span class="float-end" (click)="serviceTypeTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div>
      <div class="mb-3">
        <gtapp-auto-complete
          [data]="serviceTypeListData"
          [maxLength]="256"
          placeHolder="Search Service Type"
          searchKeyword="name"
          [initialValue]="previousServiceTypeValue"
          [itemTemplate]="serviceTypeTemplate"
          [addInitialKey]="isAdmin || isDispatchUser"
          (inputChanged)="onSTSearch($event)"
          (selected)="onServiceTypeSelect($event)"
          [inputId]="'stId'"
          [enableCloseOption]="true"
          (fieldCleared)="editField('st')"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #serviceTypeTemplate let-item>
        @if (item?.key === 0) {
        <div>Add New Service Type</div>
        } @else {

        <div>{{ item?.name }}</div>

        }
      </ng-template>

      <div class="row row-cols12 mt-2" *ngIf="detailData?.job_service_type?.id">
        <div class="col">
          <button
            (click)="
              serviceTypeTemplateRef.close();
              updateData({ service_type_id: null })
            "
            class="btn btn-outline-danger btn-lg w-100"
          >
            Remove Service Type
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- send report confirmation  DIALOG -->
<ng-template
  #reportConfirmationTemplate
  let-reportConfirmationTemplate="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Recipients
      <span class="float-end" (click)="reportConfirmationTemplate.close(false)">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    @if(reportRecipientList?.length){
    <div class="row row-cols-2 mb-3">
      <div class="col">
        <button class="btn btn-sm btn-primary w-100" (click)="redirectToSite()">
          Edit Recipients (Site)
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-sm btn-outline-primary w-100"
          (click)="redirectToSite('client')"
        >
          Edit Recipients (Client)
        </button>
      </div>
    </div>
    <table class="table">
      <tbody>
        <tr *ngFor="let contact of reportRecipientList; let i = index">
          <td class="border-0">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="!contact.isSnubbed"
                (change)="selectUnselectRecipients(contact)"
                id="check-{{ contact.id }}-{{ i }}"
              />
              <label
                class="form-check-label"
                for="check-{{ contact.id }}-{{ i }}"
              >
                {{ contact?.full_name }}
                <span
                  class="small-font text-secondary"
                  *ngIf="contact?.is_site_contact"
                  >[Site Contact]</span
                >
                <div class="small-font">
                  {{ contact?.email }}
                </div>
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    } @else {
    <div class="mb-2 alert alert-danger">
      There are no recipients configured for this site
    </div>
    <div class="row mb-2">
      <div class="col">
        <button class="btn btn-lg btn-primary w-100" (click)="redirectToSite()">
          Add Recipients (Site)
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-lg btn-outline-primary w-100"
          (click)="redirectToSite('client')"
        >
          Add Recipients (Client)
        </button>
      </div>
    </div>
    }

    <div class="row row-cols-2">
      <div class="col">
        <button
          class="btn btn-lg btn-secondary w-100"
          type="button"
          (click)="reportConfirmationTemplate.close(false)"
        >
          Back
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-lg btn-success w-100"
          type="button"
          (click)="reportConfirmationTemplate.close(true)"
          [disabled]="!selectedReportRecipientList?.length"
        >
          Send Report
        </button>
      </div>
    </div>
  </div>
</ng-template>
