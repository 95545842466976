import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { fetchUniqueQrText } from '../../../../../global.variable';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ProfileService } from '../../../profile/profile.service';
import { CheckpointService } from '../checkpoint.service';

@Component({
  selector: 'gtapp-qr',
  templateUrl: './qr.component.html',
  styleUrl: './qr.component.scss',
})
export class QrComponent implements OnInit {
  @ViewChild('qrGenerateTemplateRef') qrGenerateTemplateRef: any;
  qrCodeList: any = [];
  dialogref: any;
  customerListData: any = [];
  qrCount: number = 12;
  qrCodeb64ImageUrls: any = [];
  qrImageUrl: any;

  tabNames = {
    tab1: 'Generate QR',
    tab2: 'Validate',
  };
  //qr checkpoints params
  qrCpRows: number = 10;
  qrCpPrevious: number = 0;
  qrCpPageNum: number = 1;
  qrCpTotalPages: number = 0;
  sortKey: any;
  qrCPList: any = [];
  qrCpTotalCount: number = 0;
  qrCheckpointList: any = [];
  selectedQrCheckpoints: any = [];
  existingQRCodes: boolean = false;
  qrImageSrc: string = '/assets/images/Guard_Tracker-Stacked-bw.avif';
  qrImageSize: any = { width: 350, height: 350 };
  constructor(
    private dialogService: ModelDialogueService,
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private profileService: ProfileService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.getSubscriberLogoUrl();
  }

  getSubscriberLogoUrl() {
    this.profileService
      .getUserConfig({ logo_url: 1, new_ui: 1 })
      .subscribe(async (resp: any) => {
        if (resp?.status === 'success' && resp?.logo_url) {
          this.qrImageSrc = resp?.logo_url;
          if (resp?.image_size?.width) {
            this.qrImageSize = resp?.image_size;
          }
        }
      });
  }
  addCheckpointCount() {
    let subscriberStats = JSON.parse(
      localStorage.getItem('subscriberStats') || '{}'
    );
    subscriberStats.checkpoint_count =
      (subscriberStats?.checkpoint_count || 0) + 1;
    localStorage.setItem('subscriberStats', JSON.stringify(subscriberStats));
  }
  async onChangeURL(event: any, qrData: any, elementId: any) {
    try {
      setTimeout(() => {
        var element: any = <HTMLCanvasElement>(
          document.getElementById(elementId)
        );

        let data = {
          ...qrData,
          base64Url: element.querySelector('canvas').toDataURL('image/png'),
        };
        this.qrCodeb64ImageUrls.push(data);
        if (this.qrCodeb64ImageUrls?.length === this.qrCodeList?.length) {
          this.spinnerService.hide();
        }
      }, 500);
    } catch {}
  }

  downloadQRCodes(generatePdf: number = 0) {
    this.spinnerService.show();
    this.checkpointService
      .downloadQRCodes(
        {
          data: this.qrCodeb64ImageUrls,
          existing_qr_code: this.existingQRCodes ? 1 : 0,
        },
        { generate_pdf: generatePdf }
      )
      .subscribe((response) => {
        this.spinnerService.hide();
        if (generatePdf) {
          this.selectedQrCheckpoints = [];
          this.qrCPList = [];
          this.qrCodeb64ImageUrls = [];
          if (response?.message)
            this.toasterService.setMessage({
              successMessage: response?.message,
              errorMessage: '',
            });
        } else {
          var downloadURL = window.URL.createObjectURL(<any>response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.click();
          this.spinnerService.hide();
        }
        this.qrCodeList = [];
      });
  }

  generateNQRcodes() {
    this.spinnerService.show();
    this.dialogref.close();
    this.qrCodeList = [];
    this.qrCodeb64ImageUrls = [];
    const logoImage = new Image();
    logoImage.src = '/assets/images/Guard_Tracker-Stacked-bw.avif';
    logoImage.onload = () => {
      for (let i = 0; i < this.qrCount; i++) {
        this.qrCodeList.push({ qr_code: fetchUniqueQrText() });
      }
    };
  }
  openTemplate(template: any) {
    this.qrCodeList = [];
    this.qrCodeb64ImageUrls = [];
    this.dialogref = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }

  removeSelectedCP(deleteCheckpoint: any) {
    this.selectedQrCheckpoints = this.selectedQrCheckpoints?.filter(
      (checkpoint: any) => checkpoint?.id !== deleteCheckpoint?.id
    );
  }
  addSelectedCP(addCheckpoint: any) {
    if (
      this.selectedQrCheckpoints.some(
        (checkpoint: any) => checkpoint?.id === addCheckpoint?.id
      )
    ) {
      this.toasterService.setMessage({
        errorMessage: 'Checkpoint Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedQrCheckpoints.push(addCheckpoint);
      this.qrCheckpointList = this.qrCheckpointList?.filter(
        (value1: any) =>
          !this.selectedQrCheckpoints.some(
            (value2: any) => value1?.id === value2?.id
          )
      );
    }
  }
  addRemoveCheckpoint(checkpoint: any) {
    checkpoint.selected = !checkpoint?.selected;
    if (checkpoint.selected) {
      this.addSelectedCP(checkpoint);
    } else {
      this.removeSelectedCP(checkpoint);
    }
  }
  filterQRCheckpoints(event?: any) {
    if (event.target.value?.length === 0) {
      this.getQRCheckpoints();
    } else if (event.target.value?.length > 2) {
      this.getQRCheckpoints(event);
    }
  }
  getQRCheckpoints(event?: any) {
    this.existingQRCodes = true;
    let params: any = { is_qr: 1 };
    if (this.qrCpRows) {
      params['rows'] = this.qrCpRows;
    }
    if (this.qrCpPrevious) {
      params['previous'] = this.qrCpPrevious;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (event?.target?.value) {
      params['name'] = event.target.value;
    }

    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.qrCPList = response['data'];
        this.makeSelectionChanges();
        this.qrCpTotalCount = response['total_size'];
        this.qrCpTotalPages = Math.ceil(this.qrCpTotalCount / this.qrCpRows);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  makeSelectionChanges() {
    this.qrCPList.forEach((checkpoint: any) => {
      checkpoint.selected = this.selectedQrCheckpoints?.some(
        (selectedCp: any) => selectedCp?.id === checkpoint?.id
      );
    });
  }
  onQrClickPagination(event: any) {
    this.qrCpPrevious = event.previous;
    this.qrCpPageNum = event.pageNum;
    this.qrCpRows = event.pageSize;
    this.getQRCheckpoints();
  }
  onQrChangePagination(event: any) {
    this.qrCpPrevious = event.previous;
    this.qrCpPageNum = event.pageNum;
    this.qrCpRows = event.pageSize;
    this.getQRCheckpoints();
  }
  generateExistingQRCodes() {
    this.spinnerService.show();
    this.dialogref.close();

    const logoImage = new Image();
    logoImage.src = '/assets/images/Guard_Tracker-Stacked-bw.avif';

    logoImage.onload = () => {
      this.qrCodeList = [...this.selectedQrCheckpoints];
    };
  }
}
