<ul class="nav nav-tabs">
  <li
    class="nav-item"
    (click)="changeTab(tab.value)"
    *ngFor="let tab of tabNames | keyvalue"
  >
    <a
      class="nav-link"
      data-toggle="tab"
      [ngClass]="selectedTab === tab.value ? 'active' : ''"
      >{{ tab.value }}</a
    >
  </li>
</ul>
<div
  class="me-lg-2 my-2 text-end"
  *ngIf="
    totalJobCount >= 1 &&
    (totalJobCount != totalRows || !defaultJob) &&
    selectedTab === tabNames.tab1
  "
>
  <button
    class="btn btn-secondary btn-sm"
    (click)="showAllJobsDesktop()"
    id="showResetJob"
    [ngClass]="defaultJob ? 'btn-danger' : 'btn-secondary'"
  >
    {{ defaultJob ? "Show All" : "Reset" }}
  </button>
</div>
<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div
    class="mb-2 me-sm-2 col-lg-2 d-flex"
    *ngIf="selectedTab === tabNames.tab1"
  >
    <div class="flex-grow-1">
      <button
        id="addResponseJob"
        class="btn btn-primary w-100 btn-lg"
        (click)="openAddJobForm()"
      >
        + Alarm Response
      </button>
    </div>
    <div *ngIf="totalJobCount > 5">
      <button
        id="sortJobs"
        class="btn btn-secondary btn-lg d-md-none ms-2"
        (click)="isSortPopupVisisble = !isSortPopupVisisble"
      >
        <i class="fa-solid fa-arrow-up-wide-short fa-lg"></i>
      </button>
      <div
        class="dropdown-menu shadow animated--grow-in sort-popover-position"
        [ngClass]="{
          'd-none': !isSortPopupVisisble,
          'd-block': isSortPopupVisisble
        }"
        id="popoverContent"
      >
        <div
          class="row align-items-center p-2"
          *ngFor="let item of sortItemList"
          (click)="sortField(item)"
        >
          <button class="btn btn-outline-secondary w-100 text-start">
            {{ item.header }}
            <span class="float-end">
              <span *ngIf="item?.sortOrder">
                <i class="fa fa-arrow-down"></i>
              </span>
              <span *ngIf="!item?.sortOrder">
                <i class="fa fa-arrow-up"></i>
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-2 flex-sm-grow-1" *ngIf="totalJobCount > 5" id="filterJob">
    <gtapp-auto-complete
      [data]="dropDownJobs"
      placeHolder="Search Jobs"
      searchKeyword="name"
      [initialValue]="globalFilter"
      [itemTemplate]="cpLookupTemplate"
      (inputChanged)="onFilterApply($event)"
      (selected)="openIndividualJob($event)"
      (submitted)="globalFilter = $event; onFilterApply($event)"
    >
    </gtapp-auto-complete>

    <ng-template #cpLookupTemplate let-item>
      <div>{{ item.job_key }} ({{ item.status }})</div>
      <div>{{ item?.site_name }} [{{ item?.company }}]</div>
    </ng-template>
  </div>
</div>

<div id="listJob" *ngIf="totalJobCount > 0">
  @if(largeView){
  <gtapp-gt-table
    [desktopTableBackendData]="listJobData"
    [desktopTableDetails]="jobDetailsTableView"
    [tableStyle]="tableStyle"
    [otherParams]="otherParams"
    (paginationChange)="onChangePagination($event)"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    (rowClick)="openIndividualJob($event)"
  >
  </gtapp-gt-table>
  }@else {
  <gtapp-infinite-list
    [mobileCardBackendData]="infiniteJobList"
    [mobileCardDetails]="jobCardsDetails"
    [otherParams]="otherParams"
    [totalRows]="totalRows"
    (loadMoreData)="loadMoreJobs()"
    (rowClick)="openIndividualJob($event)"
    (searchColumn)="searchColumn($event)"
    (sortColumn)="sortColumn($event)"
  ></gtapp-infinite-list>
  }
</div>
