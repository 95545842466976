import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private http: HttpClient) {}

  getHavingPlans() {
    const body = { rows: 25, previous: 0 };

    return this.http
      .post('/api/subscriptions/subscriber_linked_plans/', body)
      .pipe(map((response) => response));
  }
  getSubscriptionPlans() {
    return this.http.get('/api/subscriptions/get_all_plans');
  }

  getInvoicesBySubscription() {
    return this.http
      .get(`/api/subscription_invoices/`)
      .pipe(map((response) => response));
  }
  getSavedCards() {
    return this.http
      .get('/api/payment_card/')
      .pipe(map((response) => response));
  }

  downloadInvoiceById(id: any) {
    const downloadHttpOption = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/api/invoice_pdf/${id}`, downloadHttpOption);
  }

  createSubscription(requestData: any) {
    const body = requestData;

    return this.http
      .post('/api/subscriptions/', body)
      .pipe(map((response) => response));
  }
  addUserSlots(requestData: any) {
    const body = requestData;

    return this.http
      .post('/api/subscriptions/add_user_slots/', body)
      .pipe(map((response) => response));
  }
  updateSubscriptionCard(requestData: any) {
    const body = requestData;

    return this.http
      .post('/api/shared/update_subscription_card/', body)
      .pipe(map((response) => response));
  }
  getPaymentProviderConfigs() {
    return this.http
      .get('/api/shared/payment_config/')
      .pipe(map((response) => response));
  }
  getSubscriberSubscriptionStatus() {
    return this.http.get('api/subscriptions/is_subscriber_active/');
  }

  deleteSavedCard(id: number) {
    return this.http
      .delete(`/api/payment_card/${id}`)
      .pipe(map((response) => response));
  }
  cardStatusChange(id: number, body?: any) {
    return this.http
      .patch(`/api/payment_card/${id}/`, body)
      .pipe(map((response) => response));
  }

  changeSubscriptionRecursion(id: any, status: any) {
    return this.http.get(`api/subscriptions/change_recurrence/`, {
      params: { subscription_id: id, status: status },
    });
  }
  paymentInfo(params: any) {
    return this.http.get(`api/subscriptions/payment_info/`, {
      params: params,
    });
  }
  deleteSubscriberAccount(body: any) {
    return this.http.post(`api/delete_subscriber/`, body);
  }
  getFeedBackOptions(params?: any) {
    return this.http.get(`api/get_feedback/`, { params: params });
  }
  applyCouponCode(body: any) {
    return this.http.post(`api/subscriptions/apply_coupon/`, body);
  }
  deleteQueuedPlan(params: any) {
    return this.http
      .get(`/api/subscriptions/delete_queued/`, { params: params })
      .pipe(map((response) => response));
  }
  addNewPaymnetCard(body: any) {
    return this.http.post(`api/payment_card/`, body);
  }
}
