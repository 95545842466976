import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getFormattedDateTime,
  isLargeScreen,
} from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { AuthService } from '../../../../auth/auth.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { WebcamImageComponent } from '../../../../shared/components/web-cam-image/web-cam-image.component';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { WebSocketService } from '../../../../shared/services/web-socket.service';
import { ClientsService } from '../../clients/clients.service';
import { JobsService } from '../jobs.service';

@Component({
  selector: 'gtapp-job-review',
  templateUrl: './job-review.component.html',
  styleUrl: './job-review.component.scss',
})
export class JobReviewComponent implements OnInit, OnDestroy {
  jobReportPreviewData: any;
  detailData: any;
  dialogRef: any;
  job_details: any;
  event_data: any = [];

  eventIdsInReport: any = [];

  newComment: any;
  previousComment: any;
  statusTimeStamps: any = [];

  externalNewJobId: any;
  externalOldJobId: any;
  newRequest: any;
  jobCompleteInvoiceRelatedStatus: any = {};
  routeSubscription: any;
  largeView: Boolean = isLargeScreen;
  lastStatus: any;
  webSocketSubscription: any;
  subscriberUserId: any;
  loggedInDeviceID: any;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  showHeader: boolean = true;

  monitoringCompanyList: any = [];

  mcInputValue: any;
  previousResponseTypeValue: any;
  responseTypeList: any = [];
  initalResponseTypeList: any = [];

  serviceTypeListData: any = [];
  initalServiceTypeListData: any = [];
  previousServiceTypeValue: any;

  reportRecipientList: any = [];

  selectedReportRecipientList: any = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,
    private jobService: JobsService,
    private dialogService: ModelDialogueService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private webSocketService: WebSocketService,
    private authService: AuthService,
    private clientService: ClientsService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.router.navigate(['/jobs']);
        this.dialogRef?.close();
      }
    });
    const userData = this.appService.getUserData();

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;

    this.routeSubscription = this.route.queryParams.subscribe({
      next: (params) => {
        if (params?.['jId']) {
          // mainly used for redirecting links in emails from backend
          localStorage.setItem('urlId', params?.['jId']);

          if (this.detailData?.id && this.detailData?.id !== params?.['jId']) {
            this.getJobDetails();
          }
        }
      },
    });
    this.webSocketSubscription =
      this.webSocketService.webSocketSubject.subscribe((event: any) => {
        const isSameUserOnAnotherDevice =
          this.subscriberUserId === event?.request_user?.subs_id &&
          this.loggedInDeviceID &&
          this.loggedInDeviceID !== event?.request_user?.logged_in_device_id;
        const isAnotherUser =
          this.subscriberUserId !== event?.request_user?.subs_id;

        const allowUser = isSameUserOnAnotherDevice || isAnotherUser;

        if (
          event?.event === 'individual_job_update' &&
          event?.data?.job_details?.id === this.detailData?.id &&
          allowUser
        ) {
          // update the changes made by someone else in realtime

          setTimeout(() => {
            this.formatData(event?.data);
          }, 100);
        }
      });
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.getJobDetails();
    this.getDeviceID();
  }
  getDeviceID() {
    this.loggedInDeviceID = localStorage.getItem(btoa('loggedInDeviceID'));
    if (!this.loggedInDeviceID) {
      this.authService.checkDevice({ get_device_id: 1 }).then((resp: any) => {
        this.spinnerService.hide();
        if (resp?.device_id) {
          this.loggedInDeviceID = resp?.device_id;
          localStorage.setItem(btoa('loggedInDeviceID'), resp?.device_id);
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
  }
  getJobDetails() {
    let id = window.localStorage.getItem('urlId');

    if (!id) {
      this.router.navigate(['/']);
    }

    this.jobService
      .getJobById(id, { preview_report: 1 })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.show();

          this.formatData(response);
        } else {
          this.spinnerService.hide();
        }
      });
  }
  formatData(response: any) {
    this.dialogRef?.close();
    this.detailData = response?.job_details;
    if (this.detailData?.status?.status_identity?.value !== 9) {
      // review job
      this.router.navigate([`/job-detail/${this.detailData?.job_key}`], {
        queryParams: { jId: this.detailData?.id },
      });
      return;
    }
    this.job_details = response?.data?.job_details;

    this.event_data = response?.data?.event_data;

    this.reportRecipientList = response?.data?.recipients;

    if (this.detailData?.status?.status_identity?.value === 10) {
      this.router.navigate(['/jobs']);
      return;
    }
    this.detailData?.service_type?.statuses?.forEach((obj: any) => {
      const statusValue = obj?.status?.status_identity?.value;
      if ([9, 10, 11, 12, 13].includes(statusValue)) {
        this.formatAfterCompleteStatus(obj);
      }
    });

    let lastUpdatedStatusDict: any = {};

    this.event_data?.forEach((update: any) => {
      if (
        update?.event_action === 1 &&
        [4, 5, 8, 0, 1].includes(update?.status_identity)
      ) {
        lastUpdatedStatusDict[update?.value] = update;
      }
    });

    this.lastStatus = this.detailData?.service_type?.statuses?.find(
      // offsite status
      (s: any) => s?.status?.status_identity?.value === 5
    );

    this.statusTimeStamps = Object.values(lastUpdatedStatusDict);

    this.router.navigate([`/job-review/${this.detailData?.job_key}`], {
      queryParams: {},
      replaceUrl: true, // Replaces the current URL without adding a new history entry
    });
    this.spinnerService.hide();
  }

  formatAfterCompleteStatus(statuObj: any) {
    const statusIdentiy = statuObj?.status?.status_identity?.value;
    let statusKey = '';
    switch (statusIdentiy) {
      case 9:
        statusKey = this.detailData?.sites?.[0]?.auto_send_reports
          ? ''
          : 'review';
        break;
      case 10:
        statusKey = 'approve';
        break;
    }
    if (statusKey) {
      this.jobCompleteInvoiceRelatedStatus[statusKey] = statuObj;
    }
  }
  deleteRestoreUserUpdate(event: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title:
          event?.type === 'delete' ? 'Remove From Report?' : 'Show in Report?',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();
        let body: any = {
          id: event?.event?.id,
          job_id: this.detailData?.id,
        };
        body[event?.type === 'delete' ? 'delete_update' : 'restore_update'] = 1;

        this.jobService
          .jobUpdateWoSW(body, { preview_report: 1 })
          .then((response: any) => {
            if (response?.data) {
              this.formatData(response);
            }
            if (response?.status === 'failure') {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response?.message,
              });
            }

            this.spinnerService.hide();
          });
      }
    });
  }
  editEvent(eventData: any, requestRef?: any, timeStampTempRef?: any) {
    if (eventData?.event_action === 1) {
      this.editTimeStamp(eventData, timeStampTempRef);
    } else {
      this.editComment(eventData, requestRef);
    }
  }
  editComment(eventData: any, requestRef: any) {
    this.newComment = eventData?.value;
    this.previousComment = eventData?.value;
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: eventData,
      },
      this.viewContainerRef
    );
  }
  changeStatusTimeStamp(event: any, eventData: any) {
    const selectedTime = event?.target.value
      ? new Date(event?.target.value).getTime()
      : new Date(eventData?.currentValue).getTime();

    let valid = true;

    if (eventData?.minDateTime)
      valid = selectedTime >= new Date(eventData?.minDateTime)?.getTime();

    if (eventData?.maxDateTime)
      valid = selectedTime <= new Date(eventData?.maxDateTime)?.getTime();

    eventData.valid = valid;
  }
  editTimeStamp(event: any, timeStampTempRef: any) {
    function getEventTime(item: any) {
      return item?.user_provided_timestamp
        ? item?.user_provided_timestamp
        : item?.updated_at;
    }
    const currentValue = getFormattedDateTime(
      event?.user_provided_timestamp
        ? event?.user_provided_timestamp
        : event?.updated_at
    );

    let updatedAtMax: any;
    let updatedAtMin: any;
    let maxDateTime = new Date();
    let minDateTime: any;

    switch (event?.status_identity) {
      case 5: // offsite
        // max can be the timestamp of complete ie this should be less than complete timestamp

        updatedAtMax = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 1
          )
        );
        // min can be the timestamp of onsite ie this should be greater than onsite timestamp
        updatedAtMin = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 4
          )
        );

        maxDateTime = new Date(); // max can be the now()
        minDateTime = updatedAtMin ? new Date(updatedAtMin) : minDateTime;
        break;
      case 4: // onsite
        // max can be the timestamp of offsite ie this should be less than offsite timestamp

        updatedAtMax = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 5
          )
        );
        // min can be the timestamp of in transit ie this should be greater than in transit timestamp
        updatedAtMin = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 8
          )
        );

        maxDateTime = updatedAtMax ? new Date(updatedAtMax) : new Date();
        minDateTime = updatedAtMin ? new Date(updatedAtMin) : minDateTime;
        break;

      case 8: // in transit
        // max can be the timestamp of onsite ie this should be less than onsite timestamp

        updatedAtMax = getEventTime(
          this.statusTimeStamps?.find(
            (update: any) => update?.status_identity === 4
          )
        );
        maxDateTime = updatedAtMax ? new Date(updatedAtMax) : new Date();
        minDateTime = null;
        break;
    }

    const dialogRef = this.dialogService.open(
      timeStampTempRef,
      {
        data: {
          name: event?.value,
          currentValue: currentValue,
          newValue: currentValue,
          minDateTime: minDateTime
            ? getFormattedDateTime(minDateTime)
            : minDateTime,
          maxDateTime: maxDateTime
            ? getFormattedDateTime(maxDateTime)
            : maxDateTime,
        },
      },
      this.viewContainerRef
    );

    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        let body: any = {
          event_id: event?.id,
          user_provided_timestamp: new Date(
            value?.newValue || value?.currentValue
          ),
          change_status_time_stamp: 1,
        };
        this.updateData(body);
      }
    });
  }

  clearAndFocusTextBox(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 100);
  }
  addComment(data?: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body: any = {
        job_id: this.detailData?.id,
        comment: this.newComment,
      };
      if (Object.keys(data).length) {
        body.id = data?.id;
        body.edit_comment = 1;
      }
      this.jobService
        .jobUpdateWoSW(body, { preview_report: 1 })
        .then((response: any) => {
          if (response?.data) {
            this.formatData(response);
          }
          if (response?.status === 'failure') {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
          }

          this.spinnerService.hide();
        });
    } else {
      this.toasterService.setMessage({
        successMessage: ' ',
        errorMessage: 'Comment cannot be empty',
      });
    }
  }
  modifyRequestDetails(requestRef: any) {
    this.newRequest = this.job_details?.additional_info;
    this.dialogRef = this.dialogService.open(
      requestRef,
      {},
      this.viewContainerRef
    );
  }
  updateRequestDetails() {
    this.updateData({ request: this.newRequest });
  }
  modifyExternalJobId(template: any) {
    this.externalNewJobId = this.detailData?.ext_job_id;
    this.externalOldJobId = this.detailData?.ext_job_id;
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe(() => {});
  }
  updateJobId() {
    this.updateData({ external_job_id: this.externalNewJobId });
  }
  updateData(body: any) {
    let requestData: any = {};
    requestData['method'] = 'put';
    requestData['url'] = 'api/job/' + this.detailData.id + '/';
    requestData['id'] = this.detailData.id;
    requestData['data'] = body;
    this.spinnerService.show();
    this.jobService
      .updateJob(requestData, { preview_report: 1 })
      .then((response: any) => {
        if (response?.data) {
          this.formatData(response);
        }
        if (response?.status === 'failure') {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }

        this.spinnerService.hide();
      });
  }
  approveJob(sendReport = false, template: any) {
    this.selectedReportRecipientList = [
      ...new Set(
        this.reportRecipientList
          ?.filter((recipient: any) => !recipient?.isSnubbed)
          ?.map((recipient: any) => recipient?.id)
      ),
    ];
    const dialogRef = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        let reqBody: any = {
          job_status_id:
            this.jobCompleteInvoiceRelatedStatus?.approve?.status?.id,
        };
        if (sendReport) {
          reqBody.send_report = true;
          reqBody.recipient_ids = this.selectedReportRecipientList;
        }
        this.updateData(reqBody);
      }
    });
  }
  moveJobBack() {
    const dialogRef = this.dialogService.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Reopen Job',
          message: '',
        },
      },
      this.viewContainerRef
    );
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.updateData({
          job_status_id: this.lastStatus?.status?.id,
        });
      }
    });
  }
  openAddCommentTemplate(requestRef: any) {
    this.newComment = '';
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openCamPopup() {
    this.dialogRef = this.dialogService.open(WebcamImageComponent, {
      data: {
        showFrom: 'updateJob',
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.spinnerService.show('Uploading Details');
        let fileData: FormData = new FormData();

        fileData.append('file', value.actualFileUpload);
        if (value.newComment) {
          fileData.append('comment', value.newComment);
        }
        let url = `job/update_job_wo_sw`;
        fileData.append('job_id', this.detailData?.id);
        fileData.append('image_source', value?.uploadType);
        this.appService
          .formDataApi(url, fileData, false, { preview_report: 1 })
          .then((response: any) => {
            if (response?.data) {
              this.formatData(response);
            }
            if (response?.status === 'failure') {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response?.message,
              });
            }

            this.spinnerService.hide();
          });
      } else if (value == 'error') {
        this.toasterService.setMessage({
          errorMessage: 'Unable to access your camera',
        });
      }
    });
  }
  scrollToSection(event: Event, sectionId: string) {
    event.preventDefault();
    document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
  }
  onMcSearch(event: any) {
    this.mcInputValue = event?.target?.value;
    if (event?.target?.value.length > 2) {
      this.getMonitoringCompanyList({ search_str: event?.target?.value });
    }
  }

  getMonitoringCompanyList(params?: any) {
    this.clientService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.monitoringCompanyList = res['data'];
        }
      });
  }
  modifyMonitoringCompany(requestRef: any) {
    this.getMonitoringCompanyList();
    this.mcInputValue = this.detailData?.monitoring_company?.company_name;
    this.dialogRef = this.dialogService.open(
      requestRef,
      {
        data: {},
      },
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe(() => {});
  }
  addMC(monitoringCompany: any) {
    if (monitoringCompany.key === 0) {
      this.openAddMonitoringCompany();
    }
    this.updateData({ monitoring_company_id: monitoringCompany?.id });
    this.dialogRef.close();
  }
  openAddMonitoringCompany() {
    if (this.mcInputValue?.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createMonitoringCompany({
          company_name: String(this.mcInputValue),
        })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.addMC(response?.data);
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Monitoring Company value can't be empty",
        successMessage: '',
      });
    }
  }
  getResponseTypes(params?: any) {
    this.clientService.fetchResponseTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.responseTypeList = res['data'];

        this.initalResponseTypeList = JSON.parse(JSON.stringify(res?.data));
      }
    });
  }
  openUpdateResponseTypeTemplate(template: TemplateRef<any>) {
    this.getResponseTypes();
    this.previousResponseTypeValue = this.detailData?.response_type?.name;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  onSelectResponseType(event: any) {
    if (event?.key === 0) {
      this.openAddResponseTypeForm();
    }
    if (event?.id) {
      let body: any = {
        response_type_id: event?.id,
      };

      this.updateData(body);
    }
  }
  onSearchResponseType(event: any) {
    this.previousResponseTypeValue = event.target.value;
    if (event.target.value.length) {
      this.responseTypeList = this.initalResponseTypeList?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    } else {
      this.responseTypeList = this.initalResponseTypeList;
    }
  }

  openAddResponseTypeForm() {
    if (this.previousResponseTypeValue.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createResponseType({
          name: String(this.previousResponseTypeValue),
        })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.onSelectResponseType(response?.data);
            this.getResponseTypes();
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Response Type Name can't be empty",
        successMessage: '',
      });
    }
  }
  editField(field: string) {
    if (field == 'rt') {
      this.previousResponseTypeValue = null;
      this.responseTypeList = this.initalResponseTypeList;
      this.shiftFocus('rtId');
    }
    if (field == 'mc') {
      this.mcInputValue = null;
      this.getMonitoringCompanyList();
      this.shiftFocus('mcId');
    }
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  openUpdateServiceTypeTemplate(template: TemplateRef<any>) {
    this.getServiceTypes();
    this.previousServiceTypeValue = this.detailData?.job_service_type?.name;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  onSTSearch(event: any) {
    this.previousServiceTypeValue = event.target.value;

    if (event.target.value.length) {
      this.serviceTypeListData = this.initalServiceTypeListData?.filter(
        (type: any) =>
          type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    } else {
      this.serviceTypeListData = this.initalServiceTypeListData;
    }
  }

  getServiceTypes(params?: any) {
    this.clientService.fetchServiceTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.serviceTypeListData = res['data'];

        this.initalServiceTypeListData = JSON.parse(JSON.stringify(res?.data));
      }
    });
  }
  onServiceTypeSelect(sType: any) {
    if (sType.key === 0) {
      this.openAddServiceType();
    }
    if (sType?.id) {
      let body: any = {
        service_type_id: sType?.id,
      };

      this.updateData(body);
    }
  }
  openAddServiceType() {
    if (this.previousServiceTypeValue.length > 0) {
      this.spinnerService.show();
      this.clientService
        .createServiceType({ name: String(this.previousServiceTypeValue) })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.onServiceTypeSelect(response?.data);

            this.getServiceTypes();
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: "Service Type value can't be empty",
        successMessage: '',
      });
    }
  }
  redirectToSite(route = 'site') {
    if (route === 'site') {
      this.router.navigate(
        ['/view-site', { sKey: String(this.detailData?.sites[0]?.id) }],
        { fragment: 'jc' }
      );
    } else {
      this.router.navigate(
        ['/view-client', { cKey: String(this.detailData?.company?.id) }],
        { fragment: 'jc' }
      );
    }
  }
  selectUnselectRecipients(contact: any) {
    contact.isSnubbed = !contact?.isSnubbed;

    this.selectedReportRecipientList = [
      ...new Set(
        this.reportRecipientList
          ?.filter((recipient: any) => !recipient?.isSnubbed)
          ?.map((recipient: any) => recipient?.id)
      ),
    ];
  }
}
