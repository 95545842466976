import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  formatDateTimeStamp,
  isLargeScreen,
} from '../../../../global.variable';
import { DatetimePickerComponent } from '../../../shared/components/datetime-picker/datetime-picker.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { JobsService } from '../jobs/jobs.service';
@Component({
  selector: 'gtapp-invoice-jobs',
  templateUrl: './invoice-jobs.component.html',
  styleUrl: './invoice-jobs.component.scss',
})
export class InvoiceJobsComponent implements OnInit, OnDestroy {
  // dict keys
  // IDENTITY_COMPLETE = 1, _("COMPLETE")
  // IDENTITY_APPROVE = 10, _("APPROVE")
  // IDENTITY_INVOICED = 11, _("INVOICED")
  // IDENTITY_DO_NOT_INVOICE = 12, _("DO NOT INVOICE")
  // IDENTITY_PAID = 13, _("PAID")
  invoiceJobListData: any = { 1: [], 10: [], 11: [], 12: [], 13: [] };
  tabDataListUI: any = [];
  tabDataCount: number = 0;

  allJobsSelected: boolean = false;
  @ViewChild('donInvoiceTemplate', { static: true })
  donInvoiceTemplate: any;

  selectedJobIds: any = [];
  jobSearchStr: string = '';

  jobInvoiceStatusData: any = {};
  selectedJobStatus: any;
  dNotInvoiceReason: string = '';

  donInvoiceStatusId: string = '';

  positionStrategy: any;
  templateWidth: any = '0px';

  uiTabs = {
    //  NOTE: Refactor this.getTabData() if the order of the tab is changed
    tab1: 'Ready for Invoicing',
    tab2: 'Invoiced',
    tab3: 'Invoice Paid',
    tab4: 'DO NOT INVOICE',
  };

  selectedTab: string = this.uiTabs.tab1;
  largeView = isLargeScreen;
  tableColumns: any = {
    col1: { key: 'modified_at', sortOrder: 'desc' },
    col2: { key: 'job_type' },
    col3: { key: 'site' },
    col4: { key: 'job_id' },
  };
  dateRangeFilter: any = { dateRangeValue: 'All Data' };

  constructor(
    private jobService: JobsService,
    private spinnerService: LoadingSpinnerService,
    private router: Router,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private dialogService: ModelDialogueService
  ) {}
  ngOnInit(): void {
    const savedTab = sessionStorage.getItem('selectedTab');
    if (savedTab) {
      this.changeTab(savedTab);
    }
    this.getJobStatuses();
    this.getInvoiceJobs();
  }
  ngOnDestroy(): void {}
  getJobStatuses() {
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        response.statuses?.forEach((status: any) => {
          if ([10, 11, 12, 13].includes(status?.status_identity?.value))
            this.jobInvoiceStatusData[status?.status_identity?.value] = status;
          if (status?.status_identity?.value === 12) {
            this.donInvoiceStatusId = status?.id;
          }
        });
      }
    });
  }

  getInvoiceJobs() {
    this.spinnerService.show();
    this.jobService.getManageInvoiceJobs().then((response: any) => {
      if (response?.status === 'success') {
        if (response?.data) {
          this.formatData(response);
        }
        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
    });
  }
  changeTab(event: any) {
    this.selectedTab = event;
    // come back to the last selected tab if redirectd back/reloaded
    sessionStorage.setItem('selectedTab', this.selectedTab);
    this.getTabData();
    this.allJobsSelected = false;
    this.selectedJobIds = [];
    this.jobSearchStr = '';
    this.dateRangeFilter = { dateRangeValue: 'All Data' };
  }
  getTabData() {
    let statusNum: any;
    switch (this.selectedTab) {
      case this.uiTabs.tab1:
        statusNum = 10;
        break;
      case this.uiTabs.tab2:
        statusNum = 11;
        break;
      case this.uiTabs.tab3:
        statusNum = 13;
        break;
      case this.uiTabs.tab4:
        statusNum = 12;
        break;
    }

    if (statusNum) {
      let jobDataList: any = this.invoiceJobListData?.[statusNum] || [];
      if (statusNum == 10) {
        jobDataList = jobDataList?.concat(this.invoiceJobListData?.[1] || []);
      }

      const tabData = JSON.parse(JSON.stringify(jobDataList));
      this.tabDataListUI = tabData;
      this.tabDataCount = tabData?.length;
    }
  }

  markSelectedJobs(jobData: any) {
    jobData.selected = !jobData?.selected;

    if (jobData?.selected) {
      this.selectedJobIds.push(jobData?.id);
    } else {
      const index = this.selectedJobIds?.indexOf(jobData?.id, 0);
      if (index > -1) {
        this.selectedJobIds?.splice(index, 1);
      }
    }

    this.selectedJobIds = [...new Set(this.selectedJobIds)];

    this.allJobsSelected =
      this.selectedJobIds?.length === this.tabDataListUI?.length;
  }

  clearAndFocusTextBox(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 100);
  }
  openJobDetailPage(jobData: any) {
    this.router.navigate([`/job-detail/${jobData.job_key}`], {
      queryParams: { jId: jobData?.id },
    });
  }

  bulkUpdateJobs(body: any, params: any, addDeviceInfo = true) {
    this.spinnerService.show();
    this.jobService
      .getManageInvoiceJobs(body, params, addDeviceInfo)
      .then((response: any) => {
        if (response?.status === 'success') {
          if (response?.data) {
            this.formatData(response);
          }

          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }
      });
  }

  formatData(response: any) {
    this.invoiceJobListData = JSON.parse(JSON.stringify(response?.data));

    let selectedTab: string = this.selectedTab;
    switch (this.selectedJobStatus?.status_identity?.value) {
      case 10:
        selectedTab = this.uiTabs.tab1;
        break;
      case 11:
        selectedTab = this.uiTabs.tab2;
        break;
      case 12:
        selectedTab = this.uiTabs.tab4;
        break;
      case 13:
        selectedTab = this.uiTabs.tab3;
        break;
    }
    if (selectedTab) {
      this.changeTab(selectedTab);
    }
  }
  selectAllJobs(event: any) {
    this.tabDataListUI?.forEach((element: any) => {
      element.selected = event.target.checked;
    });
    this.selectedJobIds = [
      ...new Set(
        this.tabDataListUI
          ?.filter((job: any) => job?.selected)
          ?.map((job: any) => job?.id)
      ),
    ];
    this.allJobsSelected =
      this.selectedJobIds?.length === this.tabDataListUI?.length;
  }
  filterJobs() {
    this.getTabData();
    if (this.jobSearchStr) {
      const searchStr = this.jobSearchStr?.toLowerCase();
      this.tabDataListUI = this.tabDataListUI?.filter(
        (value: any) =>
          value?.site?.company_name?.toLowerCase()?.includes(searchStr) ||
          value?.company?.company_name?.toLowerCase()?.includes(searchStr) ||
          value?.job_type?.name?.toLowerCase()?.includes(searchStr) ||
          value?.job_key?.toLowerCase()?.includes(searchStr) ||
          value?.ext_job_id?.toLowerCase()?.includes(searchStr) ||
          value?.monitoring_company?.name?.toLowerCase()?.includes(searchStr)
      );
    }
    this.selectedJobIds = [
      ...new Set(
        this.tabDataListUI
          ?.filter((job: any) => job?.selected)
          ?.map((job: any) => job?.id)
      ),
    ];
    this.allJobsSelected =
      this.selectedJobIds?.length === this.tabDataListUI?.length;
  }
  updateJobStatus(newStatus: any, jobIdList: any) {
    this.selectedJobStatus = newStatus;
    if (newStatus?.id === this.donInvoiceStatusId) {
      this.selectedJobIds = jobIdList;
      this.dialogService.open(
        this.donInvoiceTemplate,
        {},
        this.viewContainerRef
      );
    } else {
      this.bulkUpdateJobs(
        {
          job_ids: jobIdList,
          status_id: newStatus?.id,
        },
        { update_status: 1 },
        true
      );
    }
  }
  donotInvoiceJobs() {
    this.bulkUpdateJobs(
      {
        job_ids: this.selectedJobIds,
        status_id: this.donInvoiceStatusId,
        reason: this.dNotInvoiceReason,
      },
      { update_status: 1 },
      true
    );
  }
  sortField(column: any) {
    column['sortOrder'] = column?.sortOrder === 'asc' ? 'desc' : 'asc';

    switch (column?.key) {
      case 'modified_at':
        this.tabDataListUI.sort((a: any, b: any) => {
          let dateA = new Date(a['modified_at']).getTime();
          let dateB = new Date(b['modified_at']).getTime();
          return column?.sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });
        break;
      case 'site':
        this.tabDataListUI = this.sortArrayOfObject(
          this.tabDataListUI,
          'site.company_name',
          column?.sortOrder
        );
        break;
      case 'job_type':
        this.tabDataListUI = this.sortArrayOfObject(
          this.tabDataListUI,
          'job_type.name',
          column?.sortOrder
        );
        break;
      case 'job_id':
        this.tabDataListUI = this.sortArrayOfObject(
          this.tabDataListUI,
          'job_key',
          column?.sortOrder
        );
        break;
    }
  }
  sortArrayOfObject(arr: any, key: string, sort_order = 'asc') {
    const order = sort_order.trim().toLowerCase();

    return arr.sort((a: any, b: any) => {
      const aValue = key.split('.').reduce((acc, part) => acc && acc[part], a);
      const bValue = key.split('.').reduce((acc, part) => acc && acc[part], b);

      if (aValue === null) return order === 'asc' ? 1 : -1;
      if (bValue === null) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      } else if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }
  openRangePicker() {
    const dialogRef = this.dialogService.open(DatetimePickerComponent, {
      data: { dateRange: {} },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.dateRangeFilter = value;
        this.dateRangeFilter.dateRangeValue = `${String(
          formatDateTimeStamp(value?.start, 'd MMM y HH:mm', 'en_US')
        )} - ${String(
          formatDateTimeStamp(value?.end, 'd MMM y HH:mm', 'en_US')
        )}`;
        this.getTabData();
        this.tabDataListUI = this.tabDataListUI?.filter((item: any) =>
          this.filterByDate(item)
        );
        this.selectedJobIds = [
          ...new Set(
            this.tabDataListUI
              ?.filter((job: any) => job?.selected)
              ?.map((job: any) => job?.id)
          ),
        ];
        this.allJobsSelected =
          this.selectedJobIds?.length === this.tabDataListUI?.length;
      }
    });
  }
  filterByDate(item: any) {
    let itemTime = new Date(item?.modified_at).getTime();
    return (
      itemTime >= this.dateRangeFilter?.start.getTime() &&
      itemTime <= this.dateRangeFilter?.end.getTime()
    );
  }
}
