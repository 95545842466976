<ul class="nav nav-tabs mb-3">
  <li
    class="nav-item"
    *ngFor="let tab of tabsUsers | keyvalue"
    (click)="changeTab(tab)"
  >
    <a class="nav-link" [ngClass]="selectedTab === tab.value ? 'active' : ''">{{
      tab.value
    }}</a>
  </li>
</ul>

<div class="tab-content">
  <div
    class="tab-pane"
    [ngClass]="selectedTab === tabsUsers.tab1 ? 'active' : ''"
  >
    <div
      class="alert alert-primary text-center recommendation-alert"
      *ngIf="recommendedPlan"
    >
      We recommend switching your plan to
      <span class="fw-bold">{{ recommendedPlan }}</span> for a better rate
      <div class="mt-2">
        <button class="btn btn-info" (click)="changePlan()">See Plans</button>
      </div>
    </div>

    <div class="mt-3 fw-bold mb-2" *ngIf="!isTestAccount">
      <span>Total Users</span>
      <span>
        {{ totalAddedUsers }}
        <span *ngIf="allotedUsers"> / {{ allotedUsers }}</span>
      </span>
    </div>

    <div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
      <div class="mb-2 me-sm-2 col-lg-2">
        <button
          class="btn btn-primary btn-lg w-100"
          (click)="openAddUserForm()"
          id="addUserButton"
        >
          + Add User
        </button>
      </div>
      <div
        class="mb-2 flex-sm-grow-1"
        *ngIf="totalUserCount > 5"
        id="filterSite"
      >
        <gtapp-auto-complete
          [data]="userDropDownList"
          placeHolder="Search Users"
          searchKeyword="full_name"
          [itemTemplate]="userTemplate"
          (inputChanged)="onUserLookup($event)"
          (selected)="rowClick($event)"
          (submitted)="
            globalFilter = $event; userPaginationData = {}; getUsers()
          "
        >
        </gtapp-auto-complete>

        <ng-template #userTemplate let-item>
          <div>{{ item?.full_name }}</div>
        </ng-template>
      </div>
    </div>

    @if (!largeView) {
    <div>
      <gtapp-card-list
        [mobileCardBackendData]="userPaginationData[pageNum]"
        [mobileCardDetails]="userCardDetails"
        [otherParams]="otherParams"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        (rowClick)="rowClick($event)"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list>
    </div>
    }@else {
    <!-- DESKTOP VIEW -->
    <div>
      <gtapp-gt-table
        [desktopTableBackendData]="userPaginationData[pageNum]"
        [desktopTableDetails]="userDetailsTableView"
        [tableStyle]="tableStyle"
        [otherParams]="otherParams"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        (rowClick)="rowClick($event)"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table>
    </div>
    }
  </div>
</div>

<div *ngIf="totalUserCount < 1">
  <div class="alert alert-danger" role="alert">No Users Found</div>
</div>

<div class="tab-content">
  <div
    class="tab-pane"
    [ngClass]="selectedTab === this.tabsUsers.tab2 ? 'active' : ''"
  >
    <gtapp-licences
      *ngIf="selectedTab === this.tabsUsers.tab2"
    ></gtapp-licences>
  </div>
</div>
